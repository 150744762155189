import useSize from '@react-hook/size';
import React, {useRef} from 'react'

export default function AspectRatio({ratio, offset, children}) {

    const containerRef = useRef(null)
    const [width, height] = useSize(containerRef)

    const calculateRect = (container, ratio, offset) => {

        var heightFromWidth = (container.width/ratio);

        if (heightFromWidth >= (container.height-offset)) {
            //height constrained case
            var w = ratio*(container.height-offset);
            return {
                width: w,
                height: container.height-offset,
                top: 0, 
                left: (container.width-w)/2
            }
        } else {
            //width constrained case
            return {
                width: container.width,
                height: heightFromWidth,
                top: 0, //could center vertically if desired
                left: 0
            }
        }
    }

    const containerSize = {width: width, height: height}
    var ratioParts = ratio.split(":");
    var w = ratioParts[0];
    var h = ratioParts[1];
    var ratio = w/h;

    const rect = calculateRect(containerSize, ratio, 0)

    return (
        <div 
            ref={containerRef} 
            className="aspect-container">
            <div 
                className="aspect-contained" 
                style={{
                    width: `${rect.width}px`, 
                    height: `${rect.height}px`, 
                    marginTop: `${rect.top}px`, 
                    marginLeft: `${rect.left}px`
                }}>
                {children}
            </div>
        </div>
    )
}