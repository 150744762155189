

export const LowerThirdMessageFields = [
    {
        name: "headlineColor",
        type: "text",
        value: "rgba(255, 215, 6, 1)",
    },
    {
        name: "subheadingColor",
        type: "text",
        value: "rgba(0, 0, 0, 1)",
    },
    {
        name: "messageTitle",
        type: "text",
        value: "Enter Message Title",
    },
    {
        name: "message",
        type: "text",
        value: "Enter Message",
    }
]

export default function LowerThirdMessage({headlineColor="rgba(255, 215, 6, 1)", subheadingColor="rgba(0, 0, 0, 1)", messageTitle="Headline", message="Main Text"}) {
    return (
        `<svg viewBox="0 0 1200 88" width="1200px" height="80px" xmlns="http://www.w3.org/2000/svg" style="font-family: Yu Gothic UI;">
            <rect y="38" width="1200" height="50" style="fill: ${subheadingColor}; stroke: ${subheadingColor}; paint-order: stroke; stroke-width: 0px;"/>
            <rect width="1200" height="38" style="fill: ${headlineColor}; stroke: ${headlineColor}; paint-order: stroke; stroke-width: 0px;"/>
            <text style="fill: rgb(255, 255, 255); font-size: 20px; white-space: pre;" x="10.713" y="70.989">${message}</text>
            <text class="cls-7" style="font-size: 26px; white-space: pre;" x="10" y="28.647">${messageTitle}</text>
        </svg>`
    )
}



