import * as React from "react"
import { get } from 'lodash'

const PositionMini = ({isMatched}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
    >
      <g data-name="Group 1658" transform="translate(-648 -700)">
        <g
          fill="none"
          stroke="#858585"
          strokeWidth="0.5"
          data-name="Rectangle 3411"
          transform="translate(648 700)"
        >
          <rect width="27" height="27" stroke="none" rx="6"></rect>
          <rect width="26.5" height="26.5" x="0.25" y="0.25" rx="5.75"></rect>
        </g>
        <rect
          width="9"
          height="6"
          fill={isMatched ? '#01d1fa' : "#858585"}
          data-name="Rectangle 3410"
          rx="2"
          transform="translate(662 717)"
        ></rect>
      </g>
    </svg>
  );
};



export default PositionMini
