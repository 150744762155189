import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons';

export default memo(({data, isConnectable}) => {
    
    return (
        <div className="react-flow__node-default nodeStyle">
          <Handle
            type="target"
            id="a"
            position="top"
            style={{ background: '#555'}}
            onConnect={(params) => console.log('handle onConnect', params)}
            isConnectable={isConnectable}
          />
          <div className="label" style = {{padding: "10px"}}>
            <FontAwesomeIcon icon={faArrowsRotate} size={"1x"}></FontAwesomeIcon>&nbsp;{"Loop Sequence"}
          </div>
        </div>
    );
})
