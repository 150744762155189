import React, { Component } from 'react'
import GraphBase from './components/graph-base'

import Sidebar from './components/sidebar'
import Header from './components/header'

import "./styles/root.css"
export default class GraphEditor extends Component {
    
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    
    render() {
        return (
            <div className="graph-editor-root">
                <GraphBase glEventHub={null}/>
            </div>
        )
    }
}
