import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default memo(({data, isConnectable}) => {
  return (
    <div className="react-flow__node-default sceneNode nodeStyle">
      <Handle
        type="target"
        id="a"
        position="top"
        style={{ background: '#555'}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id="b1"
        position="bottom"
        style={{ left: "20%"}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id="b2"
        position="bottom"
        style={{ left: "40%"}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id="b3"
        position="bottom"
        style={{ left: "60%"}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id="b4"
        position="bottom"
        style={{ left: "80%"}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <div className="label">
        {"Button Group 1"}
        <FontAwesomeIcon onClick={() => data.removeMe()} icon={faTimes} style={{color: "red", float: "right", marginTop: "3px", marginRight: "5px", display: "inline-block"}}/>
      </div>
    </div>
  );
});