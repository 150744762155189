import React from 'react'
import './Player.css'
import WebRTCAdaptor from './js/webrtc_adaptor'

const streamingApp = "WebRTCAppEE"

export default class WebRTCPublisher extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mediaConstraints: {
                video: true,
                audio: true
            },
            streamName: props.streamName,
            token: '',
            pc_config: {
                iceServers: [{
                    urls: 'stun:stun.l.google.com:19302'
                }]
            },
            sdpConstraints: {
                OfferToReceiveAudio: false,
                OfferToReceiveVideo: false
            },
            bandwidth: props.bandwidth,
            websocketURL: `wss://${process.env.REACT_APP_MEDIA_SERVER_HOST}/WebRTCAppEE/websocket`,
            isShow:false,
            stats: null,
        }
        this.webRTCAdaptor = null
        this.internalStream = props.stream
    }

    componentDidMount() {
        this.webRTCAdaptor = this.initiateWebrtc()
        this.setState({
            isShow:true
        })
        setTimeout(() => {this.onStartPublishing()}, 2000)
    }

    onStartPublishing = (name) => {
        this.webRTCAdaptor.publish(this.state.streamName)
        console.log("props", this.props)
    }

    initiateWebrtc = () => {
        return new WebRTCAdaptor({
            websocket_url: this.state.websocketURL,
            mediaConstraints: this.state.mediaConstraints,
            peerconnection_config: this.state.pc_config,
            sdp_constraints: this.state.sdpConstraints,
            localStream: this.internalStream,
            isPlayMode: false,
            debug: true,
            bandwidth:this.props.bandwidth,
            callback:  (info, obj) => {
                this.setState({stats: {...this.state.stats, ...obj}})
                if (info == "initialized") {
                    console.log("initialized")

                } else if (info == "publish_started") {
                    //stream is being published
                    console.log("publish started")
                    alert("publish started")
                    this.setState({
                        isShow:false
                    })

                } else if (info == "publish_finished") {
                    //stream is being finished
                    console.log("publish finished")
                    this.setState({
                        isShow:true
                    })

                } else if (info == "closed") {
                    //console.log("Connection closed")
                    if (typeof obj != "undefined") {
                        console.log("Connecton closed: "
                            + JSON.stringify(obj))
                    }
                } else if (info == "streamInformation") {


                } else if (info == "ice_connection_state_changed") {
                    console.log("iceConnectionState Changed: ", JSON.stringify(obj))
                } else if (info == "updated_stats") {
                    this.setState({stats: obj})

                    //obj is the PeerStats which has fields
                    //averageIncomingBitrate - kbits/sec
                    //currentIncomingBitrate - kbits/sec
                    //packetsLost - total number of packet lost
                    //fractionLost - fraction of packet lost
                    console.log("Average incoming kbits/sec: " + obj.averageIncomingBitrate
                        + " Current incoming kbits/sec: " + obj.currentIncomingBitrate
                        + " packetLost: " + obj.packetsLost
                        + " fractionLost: " + obj.fractionLost
                        + " audio level: " + obj.audioLevel)

                } else if (info == "data_received") {
                    console.log("Data received: " + obj.event.data + " type: " + obj.event.type + " for stream: " + obj.streamId)
                } else if (info == "bitrateMeasurement") {
                    console.log(info + " notification received")

                    console.log(obj)
                } else {
                    console.log(info + " notification received")
                }
            },
            callbackError: function (error) {
                //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError

                console.log("error callback: " + JSON.stringify(error))
                alert("R" + JSON.stringify(error))
            }
        })
    }

    render() {
        const {streamName, isShow} = this.state

        return (
            <>
                <div className="Publish">
                    {
                        this.state.stats != null ? (
                            <p>Playing {this.state.stats}</p>
                        ) : <div>Ready</div>
                    }
                </div>
            </>

        )
    }
}