export default class AudioEngine {
	
	constructor(initalMasterVolume, monitorAudio) {
		
		this.AudioContext = new AudioContext({
			sampleRate: 48000,
		})

		//create the master gain node and give it an initial volume
		this.masterGainNode = this.AudioContext.createGain()
		this.setMasterGain(initalMasterVolume)

		//create an output stream and connect it to the master gain node
		this.MixedAudioStream = this.AudioContext.createMediaStreamDestination()
		this.masterGainNode.connect(this.MixedAudioStream)

		//by default the master gain node is connected to the default destination (speakers)
		if(monitorAudio) {
			this.setMonitorAudio(true)
		}

		//internal list of audio sources and their associated audio graph nodes
		this.audioSources = []
	}

	forceStart() {
		this.AudioContext.resume()
		return this.AudioContext.state === "running"
	}

	getMixedAudioStream() {
		return this.MixedAudioStream
	}

	addDOMElementSource(id, element, gain) {
		//set conditions for getting audio from the element
		element.crossOrigin = 'anonymous'
		element.muted = false

		try {
			//create the audiocontext nodes
			const sourceNode = this.AudioContext.createMediaElementSource(element)
			const sourceGainNode = this.AudioContext.createGain()
			sourceGainNode.gain.setValueAtTime(gain, this.AudioContext.currentTime)
			sourceNode.connect(sourceGainNode)

			//connect the master node
			sourceGainNode.connect(this.masterGainNode)

			//add the node to the internal list
			let audioSource = {
				id: id,
                sourceNode: sourceNode,
				gainNode: sourceGainNode,
				element: element,
			}

			this.audioSources.push(audioSource)
		} catch (e) {
			console.log(e)
		}
	}

	addDOMElementStreamSource(id, element, gain) {
		//set conditions for getting audio from the element
		if(!element) {
			return
		}
		element.crossOrigin = 'anonymous'
		element.muted = true
		element.volume = 0

		try {
			//create the audiocontext nodes
			const sourceNode = this.AudioContext.createMediaStreamSource(element.srcObject)
			const sourceGainNode = this.AudioContext.createGain()
			sourceGainNode.gain.setValueAtTime(gain, this.AudioContext.currentTime)
			sourceNode.connect(sourceGainNode)

			//connect the master node
			sourceGainNode.connect(this.masterGainNode)

			//add the node to the internal list
			let audioSource = {
				id: id,
                sourceNode: sourceNode,
				gainNode: sourceGainNode,
				element: element,
			}

			this.audioSources.push(audioSource)
		} catch (e) {
			console.log(e)
		}
	}

	setMonitorAudio(monitorAudio) {
		
		if (this.AudioContext.state === 'suspended') {
			this.AudioContext.resume()
		}

		//try catch because the master gain node may not be connected to the audio context
		try {
			if (monitorAudio) {
				this.masterGainNode.connect(this.AudioContext.destination)
			} else {
				this.masterGainNode.disconnect(this.AudioContext.destination)
			}
		} catch (e) {
			console.log(e)
		}
	}

	setAudioSourceGain(id, gain) {

		let audioSource = this.audioSources.find((node) => node.id === id)
        
		if(!audioSource) return

		audioSource.gainNode.gain.setValueAtTime(gain, this.AudioContext.currentTime)

	}

	removeAudioSource(id) {
		
		//find the audio source in our internal list
		let audioSource = this.audioSources.find(
			(audioSource) => audioSource.id === id
		)

		//if not found we cant remove so just return
		if(!audioSource) return
		
		//disconnect the nodes from the audio graph it will automatically be cleaned up.
		audioSource.gainNode.disconnect()
		audioSource.sourceNode.disconnect()
        audioSource.gainNode = null
        audioSource.sourceNode = null

		//remove the node from the internal list
		this.audioSources = this.audioSources.filter(
			(audioSource) => audioSource.id !== id
		)

	}

	setMasterGain(gain) {
		this.masterGainNode.gain.setValueAtTime(gain, this.AudioContext.currentTime)
	}
}
