

export const CircleFields = [
    {
        name: "outlineColor",
        type: "text",
        value: "rgba(0, 0, 0, 1)",
    },
    {
        name: "fillColor",
        type: "text",
        value: "rgba(255, 255, 255, 1)",
    },
]

export default function Circle({fillColor="rgba(255, 255, 255, 1)", outlineColor="rgba(0, 0, 0, 1)"}, width="1000", height="1000") {
    return (
        `<svg viewBox="0 0 ${width} ${height}" width="${width}px" height="${height}px" xmlns="http://www.w3.org/2000/svg">
            <rect width="${width}" height="${height}" style="paint-order: fill; stroke: ${outlineColor}; fill: ${fillColor};" rx="${height/2}" ry="${height/2}"/>
        </svg>`
    )
}

