import React from "react"
import { InfoIcon } from "../../components/svgIcons"

export default function PanelHeader({ title, infoLink, children }) {
  return (
      <div className="io-subpanel">
        
        <div className="io-subpanel-left">

          <div className="io-subpanel-title">
            {title}
          </div>
          
          {infoLink &&
            <button 
              className="btn io-info"
              onClick={() =>
                window.FreshworksWidget("open", "article", { id: infoLink })
              }
            >
              <InfoIcon/>
            </button>
          }
          
        </div>

        <div className="io-subpanel-right">
            {children}
        </div>

      </div>
  )
}