import React, { useState, createContext, useEffect } from "react";
import getEventFromURL from "./util/getEventFromURL";
import { getEvent, updateEventTemplate } from "./services/api";
import { dispatchEvent } from "simple-react-event-hub";


const TemplateDataModel = {
    scoreTeam1: 0,
    scoreTeam2: 0,
    team1Name: "Sherborne St John FC",
    team2Name: "Winklebury Wizards",
    team1ShortName: "SHE",
    team2ShortName: "WIZ",
    textColor: "#ffffff",
    primaryColor: "#FC2400",
    secondaryColor: "#000000",
    date: "23rd September 2023",
    location: "Winklebury Football Complex",
    scoreVisible: true
}

export const TemplateContext = createContext(TemplateDataModel)

export default function TemplateContextWrapper({children}) {
    
    const [data, setData] = useState(null);
  
    useEffect(() => {
      getEvent(getEventFromURL()).then((data) => {
        if(data.template) {
          setData({...data.template});
          console.log("Template Data Loaded", data.template)
        } else {
          setData(TemplateDataModel);
        }
      })
    }, [])

    return (
      <TemplateContext.Provider value={{data: data, setData: (data, comitToHub=true) => {setData((currentData) => {
        const newData = {...currentData, ...data}
        if(comitToHub) updateEventTemplate(getEventFromURL(), newData)
        dispatchEvent('template-data-update', newData)
        return {...currentData, ...data}
        })}}}>
        {children}
      </TemplateContext.Provider>
    );
  }