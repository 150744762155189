export default [
    {
        id: 'default_layout',
        title: 'Default',
        layout: {
            global: {
                tabEnableRename: false,
                tabEnableClose: true,
                tabEnableFloat: true,
                tabDragSpeed: 0.2,
                legacyOverflowMenu: false,
                splitterSize: 5,
            },
            borders: [],
            layout: {
                type: "row",
                weight: 100,
                children: [
                    {
                        type: "tabset",
                        weight: 50,
                        minWidth: 1080,
                        children: [
                            {
                                type: "tab",
                                name: "⋮⋮ Scene Editor",
                                component: "preview",
                            },
                            {
                                type: "tab",
                                name: "⋮⋮ Sequence Editor",
                                component: "sequence_editor",
                            },
                            {
                                type: "tab",
                                name: "⋮⋮ Template Editor",
                                component: "template_editor",
                            }
                        ]
                    },
                    {
                        type: "row",
                        weight: 30,
                        children: [
                            {
                                type: "tabset",
                                weight: 50,
                                minWidth: 800,
                                children: [
                                    {
                                        type: "tab",
                                        name: "⋮⋮  Output Monitor",
                                        component: "output_monitor",
                                    }
                                ]
                            },
                            {
                                type: "tabset",
                                weight: 50,
                                minWidth: 800,
                                minHeight: 400,
                                enableDrag: false,
                                enableSplit: false,
                                children: [
                                    {
                                        type: "tab",
                                        name: "⋮⋮ Live Sources",
                                        component: "live_sources",
                                        enableDrag: false,
                                    },
                                    {
                                        type: "tab",
                                        name: "⋮⋮  Media Browser",
                                        component: "media_items",
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        }
    },
    {
        id: "scene_editor", 
        title: "Editor", 
        layout: {
            global: {
                borderEnableAutoHide: true,
                tabEnableRename: false,
                tabEnableClose: false,
                legacyOverflowMenu: false,
            },
            layout: {
                type: "row",
                weight: 100,
                children: [
                    {
                        type: "tabset",
                        weight: 50,
                        children: [
                            {
                                type: "tab",
                                name: "⋮⋮ Scene Editor",
                                component: "preview",
                            }
                        ]
                    },
                    {
                        type: "row",
                        weight: 20,
                        children: [
                            {
                                type: "tabset",
                                weight: 50,
                                minWidth: 600,
                                minHeight: 500,
                                enableDrag: false,
                                enableSplit: false,
                                children: [
                                   
                                    {
                                        type: "tab",
                                        name: "⋮⋮  Media Browser",
                                        component: "media_items",
                                    },
                                    {
                                        type: "tab",
                                        name: "⋮⋮ Live Sources",
                                        component: "live_sources",
                                        enableDrag: false,
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        }
    }
]