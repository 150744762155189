import React, { memo } from 'react';

import { Handle } from 'react-flow-renderer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTags, faTimes } from '@fortawesome/free-solid-svg-icons'

export default memo(({ data, isConnectable, removeMe, tags }) => {
  return (
    <div className="react-flow__node-default nodeStyle" style={{padding: "0px", width: "160px", height: "60px", borderRadius: "0px"}}>
      <FontAwesomeIcon onClick={() => data.removeMe()} icon={faTimes} style={{color: "red", float: "left", marginTop: "3px", marginLeft: "5px", display: "inline-block"}}/>
      <div style={{marginBottom: "5px", padding: "5px", width: "100%"}}>
        <FontAwesomeIcon icon={{faTags}}></FontAwesomeIcon>{data.lable}
        <p>Content Node</p>
        <div style={{fontSize: "8px"}}>
            Tags: <b style={{background: "#F90", padding: "2px", margin: "2px", borderRadius: "2px"}}>Replay</b> <b style={{background: "#F90", padding: "2px", margin: "2px", borderRadius: "2px"}}>Heighlights</b>
        </div>
      </div>
      <Handle
        type="source"
        position="right"
        id="content_source"
        style={{background: '#555' }}
        isConnectable={isConnectable}
      />
    </div>
  );
});