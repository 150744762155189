import * as React from "react"

const VolumeMute = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={19.192}
      height={14.422}
      {...props}
    >
      <g data-name="Group 1672">
        <path
          data-name="Path 278"
          d="M1.442 3.365A1.444 1.444 0 0 0 0 4.807v4.807a1.444 1.444 0 0 0 1.442 1.442H2.76l3.49 1.939V1.427L2.76 3.366ZM8.172 0a.961.961 0 0 0-.961.961v12.5a.962.962 0 0 0 1.923 0V.961A.961.961 0 0 0 8.172 0Z"
          fill="#fff"
        />
        <g
          data-name="Group 1671"
          fill="none"
          stroke="#dd1c24"
          strokeLinecap="round"
        >
          <path data-name="Line 40" d="m12.499 4.922 5.986 5.986" />
          <path data-name="Line 41" d="m18.485 4.922-5.986 5.986" />
        </g>
      </g>
    </svg>
  );
};



export default VolumeMute
