import * as React from "react"
import { get } from 'lodash'

const PositionBottomLeft = ({isMatched}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="27"
      viewBox="0 0 26 27"

    >
      <g data-name="Group 1656" transform="translate(-662 -661)">
        <g
          fill="none"
          stroke="#858585"
          strokeWidth="0.5"
          data-name="Rectangle 3404"
          transform="translate(662 661)"
        >
          <rect width="26" height="27" stroke="none" rx="6"></rect>
          <rect width="25.5" height="26.5" x="0.25" y="0.25" rx="5.75"></rect>
        </g>
        <rect
          width="10"
          height="10"
          fill={isMatched ? '#01d1fa' : "#858585"}
          data-name="Rectangle 3407"
          rx="3"
          transform="translate(665 675)"
        ></rect>
      </g>
    </svg>
  );
};



export default PositionBottomLeft
