import React, { Component } from "react"
import { Modal, Button, Badge } from "react-bootstrap"
import MediaObjectUploader from "../../components/MediaObjectUploader"
import { Image as Image2 } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as Hub from "../../services/api"
import GetToken from "../../util/getToken"
import {
  faPlay
} from "@fortawesome/free-solid-svg-icons"
import _, { map } from 'lodash'
import sourceTypes from "../../constants/sourceTypes"
import { Close } from "../../components/svgIcons"

const token = GetToken()
export default class MediaUploadModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uploadedFiles: [],
      selectedMediaObject: null
    }
  }

  transformServerMediaObject(mo) {
    if(mo && mo.url) {
      return {
        ...mo,
        title: mo.url.split("/").pop(),
        type: mo.type
          .replace("image", "image")
          .replace("video", mo.hlsUrl !== undefined ? "video" : "signedVideo"), //was signedvideo
        thumbnailURL: mo.thumbnail,
        templates: [],
        description: "",
        tags: mo.tags.length === 0 ? [{ id: "none", text: "none" }] : mo.tags
      }
    }
  }
  deleteMedia = (index) => {
    let uploadedFilesArr = _.cloneDeep(this.state.uploadedFiles)
    uploadedFilesArr = _.filter(uploadedFilesArr, (v,ind)=> {
      return ind !== index
    })
    this.setState({ uploadedFiles: uploadedFilesArr  })
  }

  // onClosebutton = () =>{
  //   const collectionId = this.props.collection
  //   let uploadedFilesArr = _.cloneDeep(this.state.uploadedFiles)
  //   const uploadKey = []
  //   map(uploadedFilesArr, v => {
  //     uploadKey.push(v.uploadKey)
  //   })
  //   Hub.deleteMedia(token, collectionId, uploadKey)
  //   this.setState({uploadedFiles: []}, () => {
  //     this.props.onClose();
  //   })
  // }

  Save = () => { 
    const collectionId = this.props.collection
    let uploadedFilesArr = _.cloneDeep(this.state.uploadedFiles)
    const uploadKey = []
    map(uploadedFilesArr, v => {
      uploadKey.push(v.uploadKey)
    })
    Hub.updateMedia(token, collectionId, uploadKey)
    this.setState({uploadedFiles: []}, () => {
      this.props.onClose();
    })
  }


  render() {
    var selectedMediaObject = 0
    if (this.state.selectedMediaObject !== null) {
      selectedMediaObject = this.state.selectedMediaObject
    }

    const getLayer = (item, index) => {
      const isSelected = index === selectedMediaObject

      const getItems = () => {
        if (item.type === sourceTypes.video) {
          return (
            <>
              <Image2
                className="media-uploaded-thumb"
                src={
                  item.thumbnailURL
                    ? item.thumbnailURL
                    : "/video-processing.gif"
                }
              />
              
              <FontAwesomeIcon
                style={{
                  display: "inline-block",
                  position: "absolute",
                  transform: "translate(-50%, -50%)",
                  top: "50%",
                  left: "50%"
                }}
                color="white"
                size={"1x"}
                icon={faPlay}
              />
            </>
          )
        } else {
          return (
            <>
              <Image2
                className="media-uploaded-thumb"
                src={
                  item.thumbnailURL
                    ? item.thumbnailURL
                    : "/video-processing.gif"
                }
              />
              <div className="uploaderMediaOverAction">
                <Button
                  variant="transparent"
                  className="btn-uploaderMediaRemove"
                  onClick={()=>this.deleteMedia(index)}
                >
                  <Close />
                </Button>
              </div>
            </>
          )
        }
      }

      return (
        <div key={item._id} className="media-upload-grid-item">
          <div
            className={
              "media-item-addPIPSource " + this.state.swapActive
                ? "media-upload-grid-item-layer"
                : ""
            }
            style={{
              outline: isSelected ? "1px solid white" : "none"
            }}
            onClick={() => this.setState({ selectedMediaObject: index })}
          >
            {getItems()}
          </div>
        </div>
      )
    }

    return (
      <Modal
        size="md"
        className="custom-modal"
        variant="dark"
        centered
        show={this.props.show}
        onHide={this.props.onClose}
      >
        <Modal.Header>
          <Modal.Title>Media Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MediaObjectUploader
            onUploadComplete={uploads => {
              this.setState({
                uploadedFiles: [...this.state.uploadedFiles, uploads]
              })
            }}
            token={this.props.token}
            collection={this.props.collection}
          />
          <div className="media-upload-grid">
            {_.get(this.state,'uploadedFiles',[]).map((item, index) => {
              return getLayer(this.transformServerMediaObject(item), index)
            })}
          </div>
          {_.get(this.state,'uploadedFiles',[]).length > 0 ? (
            <>
              {/* <div style={{ color: "#fff" }}>Detected Content</div> */}
              <div className="">
                { _.get(this.state,`uploadedFiles[${selectedMediaObject}].aiLabels`,[]).map(
                    x => {
                      return (
                        <Badge variant="dark" style={{ margin: "5px" }}>
                          {x.Name}
                        </Badge>
                      )
                    }
                  )
                }
              </div>
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant="transparent"
            className="btn-light-outline"
            onClick={() => {
              this.onClosebutton();
            }}
          >
            Close
          </Button> */}
          <Button
            variant="transparent"
            className="btn-light-outline"
            onClick={() => {
              this.Save();
            }}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
