

export const SquareFields = [
    {
        name: "outlineColor",
        type: "text",
        value: "rgba(0, 0, 0, 1)",
    },
    {
        name: "fillColor",
        type: "text",
        value: "rgba(255, 255, 255, 1)",
    },
]

export default function Square({fillColor="rgba(255, 255, 255, 1)", outlineColor="rgba(0, 0, 0, 1)"}) {
    return (
        `<svg viewBox="0 0 100 100" width="100px" height="100px" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" style="paint-order: fill; stroke: ${outlineColor}; fill: ${fillColor};"/>
        </svg>`
    )
}

