import * as React from "react"

const LayerForeground = ({isMatched}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21.142}
    height={21.141}
  >
    <path
      data-name="Path 247"
      d="M8.242 14.127a1.229 1.229 0 0 1-1.228-1.228V3.685H4.556a.614.614 0 0 0-.614.614v12.286a.614.614 0 0 0 .614.614h12.286a.614.614 0 0 0 .614-.614v-2.457Z"
      fill="#858585"
    />
    <path
      data-name="Path 256"
      d="M4.3 18.07a1.229 1.229 0 0 1-1.228-1.228V7.628H.614A.614.614 0 0 0 0 8.242v12.286a.614.614 0 0 0 .614.614H12.9a.614.614 0 0 0 .614-.614v-2.457Z"
      fill="#858585"
    />
    <path
      data-name="Path 248"
      d="M21.141.866 8.494 13.513h1.339L21.141 2.205Z"
      fill={isMatched? "#01d1fa" : "white"}
    />
    <path
      data-name="Path 249"
      d="m21.141 7.009-6.5 6.5h1.339l5.166-5.166Z"
      fill={isMatched? "#01d1fa" : "white"}
    />
    <path
      data-name="Path 250"
      d="m7.628 6.5 6.5-6.5h-1.339L7.623 5.166Z"
      fill={isMatched? "#01d1fa" : "white"}
    />
    <path
      data-name="Path 251"
      d="m21.141 3.937-9.576 9.576h1.339l8.237-8.237Z"
      fill={isMatched? "#01d1fa" : "white"}
    />
    <path
      data-name="Path 252"
      d="M7.628 9.576 17.204 0h-1.339L7.628 8.237Z"
      fill={isMatched? "#01d1fa" : "white"}
    />
    <path
      data-name="Path 253"
      d="M17.708 13.514h2.82a.614.614 0 0 0 .614-.614v-2.82Z"
      fill={isMatched? "#01d1fa" : "white"}
    />
    <path
      data-name="Path 254"
      d="M18.936 0 7.628 11.308v1.339L20.275 0Z"
      fill={isMatched? "#01d1fa" : "white"}
    />
    <path
      data-name="Path 255"
      d="M11.062 0H8.243a.614.614 0 0 0-.614.614v2.819Z"
      fill={isMatched? "#01d1fa" : "white"}
    />
  </svg>
  );
};



export default LayerForeground
