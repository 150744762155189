export default [
  {
    _id: "1",
    name: "Camera 1",
    streamType: 1,
    endpoint: "cam-1-demo",
  },
  {
    _id: "2",
    name: "Camera 2",
    streamType: 1,
    endpoint: "/cam-2-demo",
  },
  {
    _id: "3",
    name: "Camera 3",
    streamType: 1,
    endpoint: "/cam-3-demo",
  }
]

