const Reducer = (state, action) => {
    switch (action.type) {
        case 'AUTH0_LOGEDIN':
            return {
                ...state,
                auth0_user: action.payload.auth0_user
            }
        case 'USER_ABILITY_RECEIVED':
            localStorage.setItem("userAbility", JSON.stringify(action.payload.ability))
            return {
                ...state,
                ability: action.payload.ability
            }
        case 'USER_PROFILE_RECIVED':
            return {
                ...state,
                user: action.payload.user
            };
        default:
            return state
    }
}

export default Reducer