import React from "react"
import { Form, Button, Modal, Col } from "react-bootstrap"
import { Formik } from "formik"
import { get, map, includes } from "lodash"
import * as Yup from "yup"

const StreamEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "Minimum 1 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Stream Name is required"),
  streamType: Yup.number().required("Stream Type is required"),
})
export default function AddSourceModal({ sources, ...props }) {
  const handleChangeSource = (sourceName, setFieldValue, setErrors) => {
    const sourceList = map(sources, (source) => get(source, "name", ""))
    if (includes(sourceList, sourceName)) {
      setErrors({
        name: "Source name is already taken. Please enter other source name.",
      })
    }
    setFieldValue("name", sourceName)
  }
  return (
    <Modal
      variant="dark"
      className="custom-modal"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={props.onClose}
    >
      <Formik
        enableReinitialize={true}
        initialValues={props.stream}
        validationSchema={StreamEditSchema}
        onSubmit={(values) => {
          props.onSave(values)
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          handleChange,
          values,
          errors,
          setErrors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title>
                {values._id ? "Edit Live Source" : "Add Live Source"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Row>
                <Form.Group as={Col} controlId="validationFormikName">
                  <Form.Label>Source Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter source name"
                    name="name"
                    value={values.name}
                    onChange={({ target }) =>
                      handleChangeSource(target.value, setFieldValue, setErrors)
                    }
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="validationFormikStreamType">
                  <Form.Label>Source Type</Form.Label>
                  <Form.Control
                    as="select"
                    name="streamType"
                    value={values.streamType}
                    onChange={handleChange}
                    isInvalid={!!errors.streamType}
                  >
                    <option selected value="1">
                      Web Stream
                    </option>
                    <option value="2">RTMP</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.streamType}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => props.onClose()}
                className="btn-light-outline"
              >
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
