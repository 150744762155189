const API_SERVER = process.env.REACT_APP_API_SERVER
const API_URL = API_SERVER + process.env.REACT_APP_API_ROOT

const DefaultHeader = (token) => {
  return { 
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('auth0_token')}`,
   }
}

export async function getUserProfile() {
  return await 
    fetch(
      API_URL + 'user',
        {
          headers : DefaultHeader(),
          method: "GET"
        }
      )
    .then((response) => {
      return response.json()
    }).catch(err => {return null})
} 

export function getEvents(token) {
  return fetch(
      API_URL + 'events',
        {
          headers : DefaultHeader(token),
          method: "GET"
        }
      )
    .then((response) => {
      return response.json()
    })
} 

export async function createEvent(token, event) {
  return fetch(
      API_URL + 'events',
        {
          headers : DefaultHeader(token),
          method: "POST",
          body: JSON.stringify(event)
        }
      )
    .then((response) => {
      return response.json()
    })
} 

export async function getEvent(eventId) {
  return fetch(
      API_URL + 'events/' + eventId,
        {
          headers : DefaultHeader(),
          method: "GET",
        }
      )
    .then((response) => {
      return response.json()
    })
} 

export function getCollections(token) {
  return fetch(
      API_URL + 'collections',
        {
          headers : DefaultHeader(token),
          method: "GET"
        }
      )
    .then((response) => {
      return response.json()
    })
} 

export async function createCollection(token, collection) {
  return fetch(
      API_URL + 'collections/collection',
        {
          headers : DefaultHeader(token),
          method: "POST",
          body: JSON.stringify(collection)
        }
      )
    .then((response) => {
      return response.json()
    })
} 

export async function getCollection(token, collectionId) {
  return fetch(
      API_URL + 'collections/collection/' + collectionId,
        {
          headers : DefaultHeader(token),
          method: "GET",
        }
      )
    .then((response) => {
      return response.json()
    })
} 


export async function updateCollection(token, collectionId, updateBody) {
  return fetch(
      API_URL + 'collections/collection/' + collectionId,
        {
          headers : DefaultHeader(token),
          method: "PUT",
          body: JSON.stringify(updateBody)
        }
      )
    .then((response) => {
      return response.json()
    })
} 

export async function getSignedMediaObjectURL(mediaObjectURL) {
  return fetch(
      API_SERVER + '/s3/' + mediaObjectURL,
        {
          method: "GET",
        }
      ).then((response) => {
        return response
      })
} 

export function getMediaOjectSigningURL(mediaObjectURL) {
  return API_SERVER + '/s3' + mediaObjectURL
} 

export async function addMediaObjectToCollection(token, collectionId, mediaObject) {
  return fetch(
      API_URL + 'collections/collection/' + collectionId + "/mediaobject",
        {
          headers: DefaultHeader(token),
          method: "POST",
          body: JSON.stringify(mediaObject)
        }
      ).then((response) => {
        return response.json()
      })
} 

export async function removeMediaObjectFromCollection(token, collectionId, moId) {
  return fetch(
      API_URL + 'collections/collection/' + collectionId + "/mediaobject/" + moId ,
        {
          headers: DefaultHeader(token),
          method: "DELETE"
        }
      ).then((response) => {
        return response.json()
      })
} 

//collections in events

export async function linkCollectionToEvent(token, eventId, collectionId) {
  return fetch(
      API_URL + `events/${eventId}/collections`,
        {
          headers: DefaultHeader(token),
          method: "POST",
          body: JSON.stringify({id: collectionId})
        }
      ).then((response) => {
        return response.json()
      })
} 

export async function getEventCollections(token, eventId) {
  return fetch(
      API_URL + `events/${eventId}/collections`,
        {
          headers: DefaultHeader(token),
          method: "GET",
        }
      ).then((response) => {
        return response.json()
      })
} 

export async function deleteMedia(token, id, data) {
  return fetch(
      API_URL + `collections/collection/${id}/media`,
        {
          headers: DefaultHeader(token),
          method: "POST",
          body: JSON.stringify(data)

        }
      ).then((response) => {
        return response.json()
      })
} 

export async function updateMedia(token, id, data) {
  return fetch(
      API_URL + `collections/collection/${id}/updatemedia`,
        {
          headers: DefaultHeader(token),
          method: "POST",
          body: JSON.stringify(data)

        }
      ).then((response) => {
        return response.json()
      })
} 

export async function getEventOutputStreams(token, eventId) {
  return fetch(
    API_URL + `events/${eventId}/outputstreams`,
      {
        headers: DefaultHeader(token),
        method: "GET",
      }
    ).then((response) => {
      console.log("getEventOutputStreams", response)
      return response.json()
    })
} 

export async function getEventInputStreams(token, eventId) {
  return fetch(
      API_URL + `events/${eventId}/inputstreams`,
        {
          headers: DefaultHeader(token),
          method: "GET",
        }
      ).then((response) => {
        return response.json()
      })
} 


export async function getEventOutputSettings(eventId) {
  return fetch(
      API_URL + `events/${eventId}/outputSettings`,
        {
          headers: DefaultHeader(""),
          method: "GET",
        }
      ).then((response) => {
        return response.json()
      })
} 

export async function setEventOutputSettings(eventId, settings) {
  return fetch(
      API_URL + `events/${eventId}/outputSettings`,
        {
          headers: DefaultHeader(""),
          method: "POST",
          body: JSON.stringify(settings),
        }
      ).then((response) => {
        return response.json()
      })
} 

export async function deleteEventInputStreams(token, eventId) {
  return fetch(
      API_URL + `events/${eventId}/streams`,
        {
          headers: DefaultHeader(token),
          method: "DELETE",
        }
      ).then((response) => {
        return response.json()
      })
} 

export async function getEventScenes(token, eventId) {
  return fetch(
      API_URL + `events/${eventId}/scenes`,
        {
          headers: DefaultHeader(token),
          method: "GET",
        }
      ).then((response) => {
        return response.json()
      })
} 

export async function setEventScenes(token, eventId, newScenes) {
  return fetch(
      API_URL + `events/${eventId}/scenes`,
        {
          headers: DefaultHeader(token),
          method: "POST",
          body: JSON.stringify(newScenes, (key, value) => {
            if (typeof value === 'function') {
              return value.toString();
            } else {
              return value;
            }
          }
          )

        }
      ).then((response) => {
        return response.json()
      })
} 

export async function createBroadcast(eventId) {
  return fetch(
    API_URL + `events/${eventId}/createbroadcast`,
      {
        headers: DefaultHeader(),
        method: "POST",
        body: JSON.stringify({name: eventId})
      }
    ).then((response) => {
      return response.json()
    })
} 

export async function addEventOutputStream(eventId, newStream) {
  return fetch(
      API_URL + `events/${eventId}/outputstreams`,
        {
          headers: DefaultHeader(),
          method: "POST",
          body: JSON.stringify(newStream)

        }
      ).then((response) => {
        return response.json()
      })
} 

export async function updateEventOutputStream(eventId, streamId, updatedOutput) {
  return fetch(
      API_URL + `events/${eventId}/outputstreams/${streamId}`,
        {
          headers: DefaultHeader(),
          method: "PUT",
          body: JSON.stringify(updatedOutput)

        }
      ).then((response) => {
        return response.json()
      })
} 

export async function deleteEventOutputStream(eventId, streamId) {
  return fetch(
      API_URL + `events/${eventId}/outputstreams/${streamId}`,
        {
          headers: DefaultHeader(),
          method: "DELETE",

        }
      ).then((response) => {
        return response.json()
      })
} 

export async function addEventInputStream(token, eventId, newStream) {
  return fetch(
      API_URL + `events/${eventId}/inputstreams`,
        {
          headers: DefaultHeader(token),
          method: "POST",
          body: JSON.stringify(newStream)

        }
      ).then((response) => {
        return response.json()
      })
} 

export async function updateEventTemplate(eventId, template) {
  console.log("updateEventTemplate", template)
  return fetch(
      API_URL + `events/${eventId}/template`,
        {
          headers: DefaultHeader(),
          method: "POST",
          body: JSON.stringify(template)

        }
      ).then((response) => {
        return response.json()
      })
} 

export async function updateEventInputStream (token, eventId, streamId, updatedStream) {
  return fetch(
      API_URL + `events/${eventId}/inputstreams/${streamId}`,
        {
          headers: DefaultHeader(token),
          method: "PUT",
          body: JSON.stringify(updatedStream)

        }
      ).then((response) => {
        return response.json()
      })
} 

export async function deleteEventInputStream (token, eventId, streamId) {
  return fetch(
      API_URL + `events/${eventId}/inputstreams/${streamId}`,
        {
          headers: DefaultHeader(token),
          method: "DELETE",

        }
      ).then((response) => {
        return response.json()
      })
} 


export async function createMediaObject(token, mediaObject) {
  return fetch(
      API_URL + 'mediaobjects/',
        {
          headers: DefaultHeader(token),
          method: "POST",
          body: JSON.stringify(mediaObject)
        }
      ).then((response) => {
        return response.json()
      })
} 

export async function addTagToSelectedMediaObjects(token, mediaObjects, tag, collectionId) {
  return fetch(
      API_URL + 'mediaobjects/tag',
        {
          headers: DefaultHeader(token),
          method: "POST",
          body: JSON.stringify({mediaObjects: mediaObjects, tag: tag, collectionId: collectionId})
        }
      ).then((response) => {
        return response.json()
      })
} 

export async function removeTagFromSelectedMediaObjects(token, mediaObjects, tag, collectionId) {
  return fetch(
      API_URL + 'mediaobjects/untag',
        {
          headers: DefaultHeader(token),
          method: "POST",
          body: JSON.stringify({mediaObjects: mediaObjects, tag: tag, collectionId: collectionId})
        }
      ).then((response) => {
        return response.json()
      })
} 

export async function getRole(token, orgId, teamId) {
  let url = API_URL + 'roles'

  if(orgId && teamId){
    url += `/${orgId}/${teamId}`
  }else if(orgId){
    url += `/org/${orgId}`
  } else if(teamId){
    url += `/team/${teamId}`
  } 

  return await fetch(
      url,
        {
          headers : DefaultHeader(token),
          method: "GET",
        }
      )
    .then((response) => {
      return response.json()
    })
} 