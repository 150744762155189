import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import Store from './store/appStore';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Store>
      <Auth0ProviderWithHistory>
        <DndProvider backend={HTML5Backend}>
          <App/>
        </DndProvider>
      </Auth0ProviderWithHistory>
    </Store>
  </React.StrictMode>,
);