import * as React from "react"

const VolumeUp = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12.5}
      height={14.422}
      {...props}
    >
      <path
        data-name="Path 279"
        d="M12.501 7.211a4.773 4.773 0 0 1-1.408 3.4.48.48 0 0 1-.679-.679 3.846 3.846 0 0 0 0-5.439.481.481 0 0 1 .679-.68 4.775 4.775 0 0 1 1.408 3.4ZM1.444 3.365A1.444 1.444 0 0 0 .002 4.807v4.807a1.444 1.444 0 0 0 1.442 1.442h1.318l3.49 1.939V1.427l-3.49 1.939ZM8.174 0a.961.961 0 0 0-.961.961v12.5a.962.962 0 0 0 1.923 0V.961A.961.961 0 0 0 8.172 0Z"
        fill="#fff"
      />
    </svg>
  );
};



export default VolumeUp
