
export const TitleCardFields = [
    {name: "team1Name", type: "string", defaultValue: "Team 1", value: "USE TEMPLATE"},
    {name: "team2Name", type: "string", defaultValue: "Team 2", value: "USE TEMPLATE"},
    {name: "primaryColor", type: "string", defaultValue: "#FF0000", value: "USE TEMPLATE"},
    {name: "secondaryColor", type: "string", defaultValue: "#000000", value: "USE TEMPLATE"},
    {name: "kickOffTime", type: "number", defaultValue: 0, value: "USE TEMPLATE"},
    {name: "message", type: "string", defaultValue: "Enter your message", value: "USE TEMPLATE"},
]
export default function TitleCard({
	team1Name,
	team2Name,
	primaryColor,
	kickOffTime,
	message="",
}) {
	return `
    <svg viewBox="0 0 1920 1080" width="1920" height="1080" xmlns="http://www.w3.org/2000/svg" style="font-family: &quot;Yu Gothic UI&quot;;">
    <defs>
        <linearGradient gradientUnits="userSpaceOnUse" x1="960" y1="0" x2="960" y2="1080" id="gradient-0" gradientTransform="matrix(0.667464, -0.744642, 1.109117, 0.994163, -279.68825, 719.584797)">
            <stop offset="0" style="stop-color: rgba(0, 0, 0, 1)"/>
            <stop offset="0.504" style="stop-color: rgb(60, 60, 60);"/>
            <stop offset="1" style=""/>
        </linearGradient>
    </defs>
    <rect width="1920" height="1080" style="fill: url(#gradient-0); paint-order: fill;"/>
    <path d="M 295.771 802.202 L 545.771 1052.202 L 45.771 1052.202 L 295.771 802.202 Z" style="paint-order: stroke; fill: ${primaryColor}; stroke: rgba(0, 0, 0, 0);" transform="matrix(0.707107, -0.707107, 0.707107, 0.707107, -777.084839, -360.907471)" shape="triangle 45.771 802.202 500 250 0.5 0 1@86f366e6" origin="0.5 -1"/>
    <path d="M 1213.359 610.427 L 1463.359 860.427 L 963.359 860.427 L 1213.359 610.427 Z" style="paint-order: stroke; fill: ${primaryColor}; stroke: rgba(0, 0, 0, 0);" transform="matrix(-0.707107, 0.707107, -0.707107, -0.707107, 3210.300781, 654.694946)" shape="triangle 963.359 610.427 500 250 0.5 0 1@a5a8c1ca" origin="0.5 -1"/>
    <text style="fill: rgb(255, 255, 255); font-size: 32px; white-space: pre;" x="28.635" y="939.278">Stream Provider</text>
    <text style="fill: rgb(255, 255, 255); font-size: 64px; font-weight: 700; text-anchor: middle; white-space: pre;" x="960" y="592" origin="0.513153 0.500176">VS</text>
    <text style="fill: rgb(255, 255, 255); font-size: 100px; font-weight: 700; text-anchor: middle; white-space: pre;" x="960" y="495">${team1Name}</text>
    <text style="fill: rgb(255, 255, 255); font-size: 100px; font-weight: 700; text-anchor: middle; white-space: pre;" x="960" y="700">${team2Name}</text>
    <text style="fill: rgb(255, 255, 255); font-size: 64px; text-anchor: middle; white-space: pre;" x="960" y="870">${message}</text>
    <text style="fill: rgb(255, 255, 255); font-size: 42px; white-space: pre; text-anchor: end;" x="1872" y="64">Match Livestream</text>
    <text style="fill: rgb(255, 255, 255); font-size: 32px; white-space: pre; text-anchor: end;" x="1872" y="117.431">Kick off ${kickOffTime}</text>
    <g id="Layer_1-2" transform="matrix(1.314872, 0, 0, 1.314872, 32.27018, 955.023926)" style="">
        <g>
        <path id="Path_69" class="cls-1" d="m5.5,17.05c1.35-.03,2.66.53,3.61,1.55.95,1.02,1.48,2.4,1.46,3.84v46.07c.03,1.44-.5,2.83-1.46,3.84-.95,1.04-2.25,1.61-3.61,1.6-1.35.03-2.65-.55-3.57-1.6-.98-1.01-1.52-2.4-1.5-3.84V22.45c-.03-1.45.51-2.84,1.5-3.84.93-1.03,2.23-1.59,3.57-1.55Z" style="fill: rgb(255, 255, 255);"/>
        <path class="cls-1" d="m118.87,68.58c.83,0,1.54.26,2.14.78.58.52.86,1.15.86,1.9s-.29,1.38-.86,1.9c-.6.54-1.31.8-2.14.8h-21.54c-.83,0-1.55-.27-2.14-.8-.58-.52-.86-1.15-.86-1.9v-38.14c0-.75.29-1.38.86-1.9.6-.54,1.31-.8,2.14-.8s1.51.27,2.11.8c.58.52.86,1.15.86,1.9v35.47h18.57Z" style="fill: rgb(255, 255, 255);"/>
        <path class="cls-1" d="m128.17,30.41c.81,0,1.51.27,2.11.8.58.52.86,1.15.86,1.9v38.14c0,.75-.29,1.38-.86,1.9-.6.54-1.3.8-2.11.8s-1.54-.27-2.14-.8c-.58-.52-.86-1.15-.86-1.9v-38.14c0-.75.29-1.38.86-1.9.6-.54,1.31-.8,2.14-.8Z" style="fill: rgb(255, 255, 255);"/>
        <path class="cls-1" d="m170.72,31.97c.19.37.29.74.29,1.12,0,.31-.06.63-.19.95l-15.28,38.15c-.47,1.17-1.41,1.76-2.81,1.76s-2.32-.59-2.81-1.76l-15.28-38.15c-.13-.33-.19-.64-.19-.95,0-.38.1-.76.29-1.12.36-.67.93-1.13,1.69-1.38.36-.12.71-.17,1.05-.17.43,0,.84.09,1.25.26.75.33,1.26.84,1.53,1.53l12.46,31.14,12.46-31.14c.3-.69.82-1.2,1.57-1.53.4-.17.82-.26,1.25-.26.34,0,.68.06,1.02.17.79.25,1.35.71,1.69,1.38Z" style="fill: rgb(255, 255, 255);"/>
        <path class="cls-1" d="m180.25,35.81v13.62h16.84c.83,0,1.53.26,2.11.78.6.54.89,1.18.89,1.93s-.3,1.36-.89,1.9c-.58.52-1.28.78-2.11.78h-16.84v13.77h20.07c.81,0,1.51.26,2.11.78.58.52.86,1.15.86,1.9s-.29,1.38-.86,1.9c-.6.54-1.3.8-2.11.8h-23.04c-.83,0-1.55-.27-2.14-.8-.58-.52-.86-1.15-.86-1.9v-38.14c0-.75.29-1.38.86-1.9.6-.54,1.31-.8,2.14-.8h23.04c.81,0,1.51.27,2.11.8.58.52.86,1.15.86,1.9s-.29,1.38-.86,1.9c-.6.54-1.3.8-2.11.8h-20.07Z" style="fill: rgb(255, 255, 255);"/>
        <path id="Path_68" class="cls-2" d="m0,5.6c-.04-1.47.5-2.9,1.5-3.98C2.42.6,3.74,0,5.12,0h.77c1.39-.03,2.71.57,3.61,1.62,1,1.08,1.54,2.51,1.5,3.98.04,1.46-.5,2.88-1.5,3.94-.91,1.05-2.22,1.66-3.61,1.66h-.77c-1.39,0-2.72-.6-3.61-1.66C.5,8.47-.03,7.06,0,5.6Z" style="fill: rgb(229, 29, 63);"/>
        <g>
            <path class="cls-1" d="m59.09,62.21c-3.08,1.23-6.32,1.78-9.71,1.65-1.7-.06-3.45-.3-5.24-.71-6.71-1.67-11.83-5.41-15.37-11.25-2.47-4.19-3.63-8.49-3.46-12.92.06-1.56.29-3.16.69-4.8,1.32-5.41,4.29-9.9,8.91-13.48,4.81-3.52,10.18-5.17,16.12-4.95,6.91.26,12.63,2.86,17.18,7.81,4.64,4.95,6.88,10.69,6.74,17.21-.03.64-.09,1.26-.17,1.87l8.69,1.14c.1-.81.18-1.63.23-2.47l.02-.51c.21-9.45-2.92-17.65-9.38-24.62-6.37-6.77-14.01-10.32-22.92-10.65-6.52-.24-12.67,1.4-18.46,4.94-5.88,3.53-10.25,8.39-13.12,14.56-2.01,4.21-3.1,8.61-3.27,13.18-.09,2.29.07,4.63.47,7.03,1.1,7.01,3.92,12.99,8.46,17.93,6.32,6.77,14.01,10.34,23.06,10.73h.29c6.03.24,11.51-.96,16.44-3.57,0,0,3.25-2.36.63-6.51-2.34-3.72-6.84-1.62-6.84-1.62Z" style="fill: rgb(255, 255, 255);"/>
            <circle id="Ellipse_104" class="cls-2" cx="78.92" cy="46.2" r="9.89" style="fill: rgb(229, 29, 63);"/>
        </g>
        </g>
    </g>
    </svg>`
}
