import React, { useState } from 'react'
import { List, arrayMove } from "baseui/dnd-list"
import MoreIcon from "../../../components/svgIcons/MoreIcon"
import { Dropdown, ListGroup } from "react-bootstrap"


export default function LayoutPreviewList({layoutList, activeScene, currentLiveScene, removeLayoutCB, jumpToLayoutCB, updateLayoutOrderCB, copyLayoutCallback}) {
  
    console.log("render")

    const [clickTimeout, setClickTimeout] = useState({ time: 0, index: -1 })
    
    const onMouseDownClickDetector = index => {
        const time = Date.now()
        setClickTimeout({ time: time, index: index })
    }

    const onMouseUpClickDetector = (layout, index) => {
        const time = Date.now()
        if (
            time - clickTimeout.time < 2000 &&
            index === clickTimeout.index
        ) {
            setTimeout(jumpToLayoutCB(index), 10)
        }
    }

    var layoutListJSX = []
    layoutList.forEach((layout, index) => {
        var name = index + 1
        var isActive = false
        if (index === activeScene) {
            isActive = true
        }

        var isLive = index === currentLiveScene

        let style = {border: isActive ? "2px solid #01d1fa" : "none"}
        
        if (isLive) {
            //style = {border: "2px solid red"}
        }

        layoutListJSX.push(
            <ListGroup.Item
                key={index}
                as="div"
                frame-scene={name}
                className={`source-list-item`}
            >
                <div
                    className="scene-layout-preview"
                    onMouseDown={() => onMouseDownClickDetector(index)}
                    onMouseUp={() => onMouseUpClickDetector(layout, index)}
                    style={style}
                >
                    {layout.preview ? (
                        <img
                            src={layout.preview}
                            style={{ filter: isActive ? "brightness(1.1)" : "brightness(0.8)" }}
                            alt=""
                        />
                    ) : (
                        ""
                    )}
                </div>
                <div className="scene-list-more-action">
                    <Dropdown alignRight className="action-more-dropdown">
                        <Dropdown.Toggle
                            align="end"
                            variant="transparent"
                            id="action-more"
                            className="action-more-dropdown-components"
                        >
                            <MoreIcon />
                        </Dropdown.Toggle>

                        <Dropdown.Menu align="end" variant="light">
                        <Dropdown.Item
                                eventKey="1"
                                onMouseUp={() => copyLayoutCallback(index)}
                            >
                                Make Copy
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey="2"
                                onMouseUp={() => removeLayoutCB(index)}
                            >
                                Delete
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </ListGroup.Item>
        )
    })
    return (
        <List
            id="dnd-list-inner"
            className="dnd-list-inner"
            items={layoutListJSX}
            removable={false}
            onClick={e => {
                e.preventDefault()
            }}
            onChange={({ oldIndex, newIndex }) => {
                var updatedLayoutList = layoutList
                //handle change
                updatedLayoutList = arrayMove(layoutList, oldIndex, newIndex)
                
                updateLayoutOrderCB({
                    updatedLayoutList: updatedLayoutList,
                    activeScene: newIndex
                })
            }}
        />
  )
}


