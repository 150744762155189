import { cloneDeep } from "lodash";
import sourceTypes from "../constants/sourceTypes";
import { Canvg } from 'canvg';

/**
 * Converts relative position [0-1] to canvas units (px)
 * @param {int} canvasWidth the width of the canvas in px
 * @param {*} canvasHeight the height of the canvas in px
 * @param {*} relativePosition the position of the element 
 * @returns 
 */
const MapPositionToCanvas = (canvasWidth, canvasHeight, relativePosition) => ({
    height: Math.round(relativePosition.height*canvasHeight),
    width: Math.round(relativePosition.width*canvasWidth),
    x: Math.round(relativePosition.x*canvasWidth),
    y: Math.round(relativePosition.y*canvasHeight)
})

const drawTriangle = (context, x, y, triangleWidth, triangleHeight, fillStyle) =>{
    context.beginPath();
    context.moveTo(x+triangleWidth/2, y);
    context.lineTo(x - triangleWidth / 2, y + triangleHeight);
    context.lineTo(x - triangleWidth / 2, y - triangleHeight);
    context.closePath();
    context.fillStyle = fillStyle;
    context.fill();
}

/**
 * Renders a single source to the canvas
 * @param {Object} source the source to be rendered
 */
const renderSourceToCanvas = (canvas, source, resolution) => {
    try {
        const {x, y, width, height} = MapPositionToCanvas(resolution.w, resolution.h, source.position)
        const ref = source.ref1.current
        const ref2 = source.ref2.current
        switch (source.type) {
            case sourceTypes.image:
                ref.crossOrigin = "anonymous"
                canvas.drawImage(ref, x, y , width , height)
            break;
            case sourceTypes.SVG:
                ref.crossOrigin = "anonymous"
                canvas.drawImage(ref, x, y , width , height)
            break;
            case sourceTypes.video:
                //use the thumbnail for the video instead
                ref.crossOrigin = "anonymous"
                canvas.drawImage(ref,  x, y , width , height);   
                drawTriangle(canvas, x + width/2, y+height/2, width/3, height/3, "white")
            break;
            case sourceTypes.webRTC:
                //use a green box with camera name for live videos -
                canvas.fillStyle = 'green';
                canvas.fillRect(x, y, width, height);
                canvas.fillStyle = "#ffffff";
                canvas.font = "8px Arial";
                canvas.textAlign = "center";
                canvas.textBaseline = "middle"
                canvas.fillText(source.name, x + width/2 , y + (height/2), (width-10));
            break;
            case sourceTypes.mediaQuery:
                ref.crossOrigin = "anonymous"
                canvas.fillStyle = source.backgroundColor;
                canvas.fillRect(x, y, width, height);
                canvas.drawImage(ref, x, y , width , height)
                if(source.mediaQuery.items.length > 1) {
                    canvas.drawImage(ref2, x, y , width , height)
                }
            break;
            default:
                console.debug("Invalid source type")
            break;
        }
    } catch(e) {
        console.log(e)
    }
}



/**
 * Renders all sources in Z index order
 * @param {Object} sources the sources to render
 * @param {*} renderFunction the rendering function for each source
 */
const DrawInZOrder = (canvas, sources, renderFunction, resolution) => {
    sources.sort((a,b) => a.position.z - b.position.z)
    
    sources.forEach(source => {
        renderFunction(canvas, source, resolution)
    })
}

/**
 * Renders all sources onto the canvas 
 * @param {*} canvas the canvas context to render to
 * @param {*} sources the sources to render
 */
const Render = (canvas, sources, resolution) => {
    const sourcesClone = cloneDeep(sources)
    DrawInZOrder(canvas, sourcesClone, renderSourceToCanvas, resolution)
}

const Init = (canvas, resolution) => {
    const {w, h} = resolution
    canvas.canvas.width  = w;
    canvas.canvas.height = h;
    canvas.fillRect(0,0, w, h);
}

export {Render, Init}