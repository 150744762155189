import React from 'react'

export default function KickOff({team1Name="Team 1", team2Name="Team 2", date="Date", location="Location", primaryColor, secondaryColor}) {
  
	const style = `.cls-6{fill: #e5e4e4;}.cls-1{}.cls-2{}.cls-3{}.cls-4{fill:${primaryColor};}.cls-5{fill:#e5e4e4;}.cls-6,.cls-7{fill:${secondaryColor};}.cls-7,.cls-8{font-size:29.34px;font-weight:700;}.cls-8{fill:#fff;}.cls-9{}.cls-10{}.cls-11{}.largeText{font-size:26.34px;}`

	return `
		<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" width="1920" height="300" viewBox="0 0 1222.77 186.4">
		<defs>
			${style}
		</defs>
		<g id="Layer_1-2" style="font-family: &quot;Yu Gothic UI&quot;;">
			<polygon class="cls-6" points="1178.44 114.27 44.52 114.27 0 40.86 1222.77 40.86 1178.44 114.27" style="fill: ${secondaryColor};" />
			<polygon class="cls-5" points="1173.92 120.58 48.28 120.58 44.56 114.27 1178.44 114.27 1173.92 120.58" style="fill: #e5e4e4;"/>
			<polygon class="cls-6" points="1121.74 186.12 101.2 186.12 61.14 120.05 1161.64 120.05 1121.74 186.12" style="fill: ${secondaryColor};"/>
			<polygon class="cls-5" points="940.28 53.47 283.34 53.47 252.28 0 970.58 0 940.28 53.47" style="fill: #e5e4e4;" />
			<polygon class="cls-4" points="653.34 114.27 569.91 114.27 534.19 53.47 688.58 53.47 653.34 114.27" style="fill: ${primaryColor};"/>
			<polygon class="cls-4" points="1163.04 114.27 1041.83 114.27 1085.73 40.86 1207.91 40.86 1163.04 114.27" style="fill: ${primaryColor};" />
			<polygon class="cls-4" points="58.76 114.27 179.97 114.27 136.08 40.86 13.89 40.86 58.76 114.27" style="fill: ${primaryColor};" />
			<polygon class="cls-4" points="611.43 186.4 649.74 120.05 573.12 120.05 611.43 186.4" style="fill: ${primaryColor};" />

			<text class="cls-8" transform="translate(360.00 93.58)" text-anchor="middle" style="fill: white;">
				<tspan class="cls-8" x="0" y="0" style="font-size: 30px;">
					${team1Name}
				</tspan>
			</text>
			<text class="cls-8" transform="translate(610.15 34.36)" text-anchor="middle" style="fill: black; font-size: 30px; ">
				<tspan x="0" y="0">
					KICKOFF
				</tspan>
			</text>
			<text class="cls-6" transform="translate(863.49 93.58)" text-anchor="middle" style="fill: white; font-size: 30px;">
				<tspan class="cls-8" x="0" y="0">
					${team2Name}
				</tspan>
			</text>
			<text class="cls-6" transform="translate(340.15 163.58)" text-anchor="middle" style="fill: white; font-size: 30px;">
				<tspan class="cls-8" x="0" y="0">
					${date}
				</tspan>
			</text>
			<text class="cls-6" transform="translate(883.49 163.58)" text-anchor="middle" style="fill: white; font-size: 30px;">
				<tspan class="cls-8" x="0" y="0">
					${location}
				</tspan>
			</text>
		</g>
		</svg>
		`
}



