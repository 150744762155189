import React from 'react'
import { Image as Image2, OverlayTrigger, Tooltip } from 'react-bootstrap'
import * as Hub from '../../services/api'
import GetEventFromURL from '../../util/getEventFromURL'
import { Button, ButtonGroup } from 'react-bootstrap'
import sourceTypes from '../../constants/sourceTypes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faTimes } from '@fortawesome/free-solid-svg-icons'
import { walk, getNodeAtPath } from 'react-sortable-tree'
import InputDialog from '../../components/modals/InputDialog'
import MediaUploadModal from '../media-upload-modal/media-upload-modal'
import PanelHeader from '../components/PanelHeader'
import { toast } from 'react-toastify'
import { get, map, orderBy } from 'lodash'
import { dispatchEvent } from 'simple-react-event-hub'
import UseEventCallback from '../preview/useEventCallback'
import { faGrid, faGrid2 } from '@fortawesome/pro-solid-svg-icons'
import FontAwesomeButton from '../../util/FontAwesomeButton'
import MediaPreviewGridSubpanel from './MediaPreviewGridSubpanel'

const token = localStorage.getItem('auth0_token')

const Modes = {
	all: 1,
	any: 2,
}

const EnableTagModeToggles = false

export default class LayerProps extends React.Component {
	constructor(props) {
		super(props)


		let targetZoomSize = localStorage.getItem('targetZoomSize')
		if (!targetZoomSize) {
			targetZoomSize = 100
		} else {
			targetZoomSize = parseInt(targetZoomSize)
		}

		this.state = {
			items: [],
			tags: [],
			suggestions: [],
			savedSelections: [],
			containerWidth: null,
			mode: Modes.all,
			swapActive: false,
			firstColection: null,
			uploadModalActive: false,
			addMode: false,
			zoomLevel: 5,
			zoomTargetSize: targetZoomSize,
			width: null,
		}
		this.ref = React.createRef()
		this.lastResult = null
		this.collection = null
		this.width = null
		this.mediaGridRef = React.createRef()
	}

	handleDelete = (tagsToRemove) => {
		const { tags } = this.state

		this.setState({
			tags: tags.filter(
				(tag) => tagsToRemove.findIndex((x) => x.id === tag.id) === -1
			),
		})
	}

	componentDidMount() {
		this.getClipsFromServer()
		//setInterval(this.getClipsFromServer, 5000)
	}

	componentWillUnmount() {}

	setSwapState = (mode) => {
		this.setState({ swapActive: mode })
	}

	setAddState = (mode) => {
		this.clearSelectedTags()
		this.setState({ addMode: mode })
	}

	transformServerMediaObject(mo) {
		if (mo && mo.url) {
			return {
				...mo,
				title: mo.url.split('/').pop(),
				type: mo.type
					.replace('image', 'image')
					.replace('video', mo.hlsUrl !== undefined ? 'video' : 'signedVideo'), //was signedvideo
				thumbnailURL: mo.thumbnail,
				templates: [],
				description: '',
				tags: mo.tags.length === 0 ? [{ id: 'none', text: 'none' }] : mo.tags,
			}
		}
	}

	isNotExistingMo = (id) => {
		return this.state.items.findIndex((y) => y._id === id) === -1
	}

	getClipsFromServer = () => {
		const event = GetEventFromURL()

		Hub.getEventCollections(token, event)
			.then((result) => {
				this.setState({ firstColection: result[0] })
				fetchCollections(result)
			})
			.catch((err) => {
				console.log('Load Collection ERR', err)
			})

		const fetchCollections = async (collections = []) => {
			collections.forEach(async (collectionId) => {
				var collection = await Hub.getCollection(token, collectionId).then(
					(collection) => {
						return collection
					}
				)

				var transformedMediaObjects = get(collection, 'mediaObjects', []).map(
					(mediaObject) => this.transformServerMediaObject(mediaObject)
				)
				transformedMediaObjects = orderBy(
					transformedMediaObjects,
					['createdAt'],
					['desc']
				)
				const tags = []
				const callback = (node) => {
					if (node.parentNode) {
						tags.push({
							id: node.node.id,
							isParent: false,
							parent: node.parentNode.id,
							selected: false,
							text: `${node.node.title}`,
						})
					} else {
						tags.push({
							id: node.node.id,
							isParent: true,
							hasChildNodes: node.node.children !== undefined,
							mode: Modes.any,
							selected: false,
							text: `${node.node.title}`,
						})
					}
				}

				walk({
					treeData: get(collection, 'treeData', []),
					getNodeKey: getNodeAtPath,
					callback: callback,
					ignoreCollapsed: false,
				})

				if (this.collection != JSON.stringify(collection)) {
					tags.push({
						id: 'none',
						isParent: true,
						hasChildNodes: false,
						selected: false,
						text: `Untagged Media`,
					})
					this.setState({ items: transformedMediaObjects, suggestions: tags })
					this.collection = JSON.stringify(collection)
				}
			})
		}
	}

	handleAddition = (tag) => {

		const avalibleTags = this.state.suggestions.filter(
			(x) => this.state.tags.findIndex((y) => y.id === x.id) === -1
		)

		if (tag) {
			if (!this.state.tags.find((x) => x.id === tag.id)) {
				let tags = this.state.tags
				tags.push({ selected: true, ...tag })
				this.setState((state) => ({
					tags: tags,
				}))
			}
			return
		}
	}

	handleDrag = (tag, currPos, newPos) => {
		const tags = [...this.state.tags]
		const newTags = tags.slice()

		newTags.splice(currPos, 1)
		newTags.splice(newPos, 0, tag)

		// re-render
		this.setState({ tags: newTags })
	}

	drag = (event, item) => {
		event.dataTransfer.setData('item', JSON.stringify(item))
		console.log('dragStartedMediaItem', event)
	}

  getPosition(str, m, i) { return str.split(m, i).join(m).length; }

  getFileNameFromUploadKey = (uploadKey) => {
    const getPosition = (str, m, i) => { return str.split(m, i).join(m).length}
    const index = getPosition(uploadKey, '-', 5) + 1
    return uploadKey.substring(index)
  }

	getLayer(item) {
		const getItems = () => {
			if (item.type === sourceTypes.video) {
				return (
          <OverlayTrigger
		  container={this.mediaGridRef}
          placement={'bottom'}
          delay={{
            show: 250,
            hide: 250,
          }}
          overlay={
            <Tooltip
              id='button-tooltip'
              style={{ pointerEvents: 'none', marginTop: "5px"}}
            >
              {this.getFileNameFromUploadKey(item.uploadKey)}
            </Tooltip>
          }
        >
					<div className='io-media-wrap'>

						<img
							className='io-media-thumb content'
							src={
								item.thumbnailURL ? item.thumbnailURL : '/video-processing.gif'
							}
						></img>
						<FontAwesomeIcon
							style={{
								display: 'inline-block',
								position: 'absolute',
								transform: 'translate(-50%, -50%)',
								top: '50%',
								left: '50%',
							}}
							color='white'
							size={'1x'}
							icon={faPlay}
						/>
					</div>
          </OverlayTrigger>
				)
			} else {
				return (
					<div
						className={
							item.thumbnailURL
								? 'io-media-wrap checked-background'
								: 'io-media-wrap'
						}
						draggable={false}
						onDragStart={(event) => {
							this.drag(event, item)
						}}
					>
						<OverlayTrigger
							container={this.mediaGridRef}
							placement={'bottom'}
							delay={{
								show: 250,
								hide: 250,
							}}
							overlay={
								<Tooltip
									id='button-tooltip'
									style={{ pointerEvents: 'none', marginTop: "5px" }}
								>
									{this.getFileNameFromUploadKey(item.uploadKey)}
								</Tooltip>
							}
						>
							<Image2
								className='io-media-thumb content'
								draggable={true}
								onDragStart={(event) => {
									this.drag(event, item)
								}}
								src={
									item.thumbnailURL
										? item.thumbnailURL
										: '/video-processing.gif'
								}
							/>
						</OverlayTrigger>
					</div>
				)
			}
		}
		return (
			<div
				key={item._id}
				className='media-item-layer'
			>
				<div
					className={
						'media-item-addPIPSource ' +
						(this.state.swapActive || this.state.addMode)
							? 'media-item-swap-hover'
							: ''
					}
					style={{
						outline:
							this.state.swapActive || this.state.addMode
								? '1px solid yellow'
								: 'none',
					}}
					onClick={() => {this.addPIPSource(item)}}
				>
					{getItems()}
				</div>
			</div>
		)
	}

	getMeta(url, callback) {
		const img = new Image()
		img.addEventListener('load', function () {
			callback({ w: this.naturalWidth, h: this.naturalHeight })
		})
		img.src = url
	}

	addPIPSource = async (item) => {
		const canDrop = !item.tags.includes('frame')

		if (item.type === 'signedImage') {
			const unsignedURL = '/img/' + item.url.split('.com/').pop()
			const signingURL = await Hub.getMediaOjectSigningURL(unsignedURL)
			this.getMeta(signingURL, (ar) => {
				let aspect = ar.h / ar.w
				let width = 500
				let height = width * aspect
				width = width / 1920
				height = height / 1080


				const newSource = {
					type: 'image',
					url: signingURL,
					x: 0.0,
					y: 0.0,
					w: width,
					h: height,
					z: 3,
					thumbURL: signingURL,
					canDrop: canDrop,
				}

				dispatchEvent('add-source', newSource)
			})
			return
		}
		if (item.type === 'signedVideo') {
			const unsignedURL =
				'/uploads/' +
				item.url
					.split('.com/')
					.filter((e) => e)
					.pop()
			const signingURL = Hub.getMediaOjectSigningURL(unsignedURL)

			const newSource = {
				type: 'video',
				url: signingURL,
				x: 0,
				y: 0,
				w: 0.3,
				h: 0.3,
				z: 3,
				thumbURL: item.thumbnailURL,
				canDrop: canDrop,
			}

			dispatchEvent('add-source', newSource)

			return
		}

		if (item.type === 'image') {
			if (this.state.addMode) {
				dispatchEvent('append-media-to-source-query', item)
				return
			}

			this.getMeta(item.url, (ar) => {
				let aspect = ar.h / ar.w
				let width = 500
				let height = width * aspect
				width = width / 1920
				height = height / 1080
				
				const newSource = {
					type: sourceTypes.mediaQuery,
					url: `https://video-processing-latest-source71e471f1-1o7pywergus2t.s3.eu-west-1.amazonaws.com/${item.uploadKey}`,
					mediaQuery: { type: 'MediaObjectList', items: [item] },
					x: 0.0,
					y: 0.0,
					w: width,
					h: height,
					z: 3,
					canDrop: canDrop,
				}

				dispatchEvent('add-source', newSource)

				return
			})
		}

		if (item.type === sourceTypes.video) {
			const newSource = {
				type: item.type,
				url: item.url,
				x: 0,
				y: 0,
				w: 0.3,
				h: 0.3,
				z: 3,
				thumbURL: item.thumbnailURL,
				canDrop: canDrop,
			}
			dispatchEvent('add-source', newSource)
		}
	}

	removeItem = (index) => {
		var items = this.state.items
		items.splice(index, 1)
		this.setState({ items: items })
	}

	getItemsRendered(items) {
		var filtered = []
		var rendered = []

		var filter = this.state.tags

		items.forEach((item, index) => {
			//start with high rank
			var rank = 10000

			//find lowest rank filter
			item.tags.forEach((tag) => {
				const newRank = filter.findIndex((x) => x.id === tag)

				if (newRank !== -1) {
					rank = Math.min(rank, newRank)
				}
			})

			filtered.push({
				rank: rank,
				item: item,
			})
		})

		filtered.sort(function (a, b) {
			return a.rank - b.rank
		})

		rendered = filtered.map((x) => {
			return (
				<li
					key={x.item._id}
					className='media-items-grid-item'
					draggable={false}
					onDragStart={(event) => this.drag(event, x.item)}
				>
					{this.getLayer(x.item)}
				</li>
			)
		})

		return rendered
	}

	clearSelectedTags = () => {
		this.setState({ tags: [] })
	}
	selectAllTags = () => {
		const excludeTagsById = (first, second) => {
			return first.filter((x) => second.findIndex((y) => y.id === x.id) === -1)
		}
		const selectedTags = this.state.tags
		const avalibleTags = excludeTagsById(this.state.suggestions, selectedTags)
		this.setState({
			tags: [...selectedTags, ...avalibleTags],
			mode: Modes.any,
		})
	}

	handleActiveTagClick = (tagi, t) => {
		const tag = this.state.tags[tagi]
		const items = this.state.items
			.filter((x) => x.tags.includes(tag.id))
			.filter((x) => x.type === 'video')
			.map((x) => {
				return x.url
			})
		const newSource = {
			type: 'tag',
			url: items,
			x: 0,
			y: 0,
			w: 0.3,
			h: 0.3,
			z: 10,
			thumbURL: '/img/tag.png',
			canDrop: true,
		}
		dispatchEvent('add-source', newSource)
	}

	toggleTag = (tag) => {
		const selectedTags = this.state.tags
		const isSelectedTag = (tag) =>
			selectedTags.findIndex((selectedTag) => selectedTag.id === tag.id) !== -1
		if (!isSelectedTag(tag)) {
			this.handleAddition(tag)
		} else {
			this.handleDelete([tag])
		}
	}

	toggleParentTag = (tags, allTagsSelected) => {
		const allSelected = Object.freeze(allTagsSelected)
		const selectAllTags = (tags) =>
			tags.forEach((tag) => {
				this.handleAddition(tag)
			})
		const deselectAllTags = (tags) => this.handleDelete([...tags])

		if (!allSelected) {
			selectAllTags(tags)
		}
		if (allSelected) {
			deselectAllTags(tags)
		}
	}

	promptForSaveName = () => {
		this.setState({ showDialog: true })
	}

	saveSelection = (name) => {
		if (name === null) return
		let savedSelections = this.state.savedSelections
		let newSelection = {
			name: name,
			tags: this.state.tags,
			filterMode: this.state.mode,
		}
		savedSelections.push(newSelection)
		this.setState({ savedSelections: savedSelections, showDialog: false })
	}

	loadSelection = (selection) => {
		this.setState({ tags: selection.tags, mode: selection.filterMode })
	}

	filterMediaObjectsByTagSelection = (mediaObjects) => {
		const selection = this.state.suggestions
		const parentTags = selection.filter((x) => x.isParent)
		const childTags = selection.filter((x) => !x.isParent)
		const selectedTags = this.state.tags

		//filter methods
		let includesAll = (arr, target) =>
			target.every((v) => arr.findIndex((x) => x.id === v.id) !== -1)
		const findMediaObjectsWithAnyTag = (mediaObjects, tags) =>
			mediaObjects.filter((mediaObject) =>
				mediaObject.tags.some((tag) => tags.find((val) => val.id === tag.id))
			)
		const findMediaObjectsWithAllTags = (mediaObjects, tags) =>
			mediaObjects.filter((mediaObject) => includesAll(mediaObject.tags, tags))
		const isSelectedTag = (tag) =>
			selectedTags.findIndex((selectedTag) => selectedTag.id === tag.id) !== -1

		let filteredResult = mediaObjects

		if (this.state.mode === Modes.all) {
			parentTags.forEach((parentTag) => {
				//handle top level tags with no childern
				if (parentTag.hasChildNodes === false) {
					if (isSelectedTag(parentTag)) {
						filteredResult = findMediaObjectsWithAllTags(filteredResult, [
							parentTag,
						])
					}
					return
				}

				//get selected child tags
				const childTags = selection.filter((tag) => tag.parent === parentTag.id)
				const selectedChildTags = childTags.filter((tag) => isSelectedTag(tag))

				if (parentTag.mode === Modes.any) {
					if (selectedChildTags.length === 0) return
					filteredResult = findMediaObjectsWithAnyTag(
						filteredResult,
						selectedChildTags
					)
				}
				if (parentTag.mode === Modes.all) {
					if (selectedChildTags.length === 0) return
					filteredResult = findMediaObjectsWithAllTags(
						filteredResult,
						selectedChildTags
					)
				}
			})
		}


		//start with parent tags
		return filteredResult
	}

	openMediaUpload = () => {
		const { firstColection } = this.state
		if (!firstColection) {
			toast.dismiss()
			toast.error('Please create at least 1 collection before media upload')
		} else {
			this.setState({ uploadModalActive: true })
		}
	}

	render() {

		const ModeToggle = ({ parentTag }) => {
			const updateParentTagMode = (tag, mode) => {
				let tags = this.state.suggestions
				const tagIndex = tags.findIndex((x) => x.id === parentTag.id)
				tags[tagIndex].mode = mode
				this.setState({ suggestions: tags })
			}

			return (
				<div
					style={{
						display: EnableTagModeToggles ? 'inline-block' : 'none',
						position: 'relative',
						float: 'right',
					}}
				>
					<div
						style={{
							color: 'white',
							display: 'inline-block',
							fontSize: 'smaller',
							verticalAlign: 'middle',
						}}
					>
						Match:
					</div>

					<ButtonGroup
						style={{
							display: 'inline-block',
							float: 'right',
							marginRight: '5px',
						}}
					>
						<Button
							variant={parentTag.mode === Modes.any ? 'warning' : 'dark'}
							style={{ padding: '0px', fontSize: 'smaller' }}
							onClick={(e) => {
								e.stopPropagation()
								updateParentTagMode(parentTag, Modes.any)
							}}
						>
							&nbsp; Any Tag &nbsp;
						</Button>
					</ButtonGroup>
				</div>
			)
		}
		const TagSection = ({ tags, parent }) => {
			const isSelectedTag = (tag) =>
				selectedTags.findIndex((selectedTag) => selectedTag.id === tag.id) !==
				-1
			const allTagsSelected = tags.every((tag) => isSelectedTag(tag))

			if (tags.length === 0) {
				return (
					<li
						className='treeview-branch'
						style={{
							background: isSelectedTag(parent) ? '#353A68' : '',
							color: isSelectedTag(parent) ? '#fff' : '',
						}}
						onClick={() => {
							if (this.state.addMode) {
								setTimeout(() => {
									const items = this.filterMediaObjectsByTagSelection(
										this.state.items
									)
									dispatchEvent('append-media-to-source-query-multiple', {
										items: items,
										tag: parent,
									})
								}, 200)
							}
							this.toggleTag(parent)
						}}
					>
						<div className='treeview-branch-title'>{parent.text}</div>
						{/* <div className="treeview-branch-badge">
              <Button variant="transparent" className="io-btn-danger btn-treeview-remove">
                Remove
              </Button>
            </div> */}
					</li>
				)
			}

			return (
				<li
					className='treeview-branch'
					onClick={(e) => {
						e.stopPropagation()
						this.toggleParentTag(tags, allTagsSelected)
						if (this.state.addMode) {
							setTimeout(() => {
								const items = this.filterMediaObjectsByTagSelection(
									this.state.items
								)
								dispatchEvent('append-media-to-source-query-multiple', {
									items: items,
									tag: parent,
								})
							}, 200)
						}
					}}
					style={{
						background: allTagsSelected ? '#353A68' : '',
						color: allTagsSelected ? '#fff' : '',
					}}
				>
					{/* <div className="treeview-branch-title">
            <Button variant="dark" className="btn-treeview btn-branch">
              {parent.text}
            </Button>
          </div> */}
					<div className='treeview-branch-parent-title'>{parent.text}</div>
					{/* <div className="treeview-branch-badge">
            <Button variant="transparent" className="io-btn-danger btn-treeview-remove">
              Remove
            </Button>
          </div> */}

					<ModeToggle
						style={{
							display: 'inline-block',
							position: 'absolute',
							top: '5px',
							right: '5px',
							float: 'right',
						}}
						parentTag={parent}
					/>
					<ul className='treeview-branch-sub'>
						{map(tags, (tag, ind) => {
							return (
								<li>
									<div
										key={ind}
										onClick={(e) => {
											e.stopPropagation()
											if (this.state.addMode) {
												setTimeout(() => {
													const items = this.filterMediaObjectsByTagSelection(
														this.state.items
													)
													dispatchEvent(
														'append-media-to-source-query-multiple',
														{ items: items, tag: tag }
													)
												}, 200)
											}
											this.toggleTag(tag)
										}}
										style={{
											background:
												selectedTags.findIndex(
													(selectedTag) => selectedTag.id === tag.id
												) === -1
													? ''
													: '#8286A9',
											color:
												selectedTags.findIndex(
													(selectedTag) => selectedTag.id === tag.id
												) === -1
													? ''
													: '#fff',
										}}
										className='treeview-branch-badge'
									>
										<div className='treeview-branch-title'>{tag.text}</div>
										
									</div>
									
								</li>
							)
						})}
					</ul>
				</li>
			)
		}

		//filter tags by avalibility
		const selectedTags = this.state.tags
		const avalibleTags = this.state.suggestions

		const parentTags = avalibleTags.filter((x) => x.isParent)
		const childTags = avalibleTags.filter((x) => !x.isParent)

		//sub components
		const MediaPreviewGridB = (props) => {
			var filter = this.state.tags
			let includesAll = (arr, target) =>
				target.every((v) => arr.findIndex((x) => x.id === v.id) !== -1)
			let filteredItems = this.filterMediaObjectsByTagSelection(props.items)

			const Content = ({ filteredItems }) => {
				let content = (
					<div className='blank-panel-window'>
						<div className='blank-panel-window-text'>
							No media selected - try some different tags
						</div>
					</div>
				)

				if (filteredItems.length > 0) {
					content = (
						<div ref={this.mediaGridRef} className='media-library-wrapper'>
								<ul className='media-items-grid' style={{gridTemplateColumns: `repeat(auto-fill, minmax(${this.state.zoomTargetSize}px, 1fr))`}}>
									{this.getItemsRendered(filteredItems)}
								</ul>
						</div>
					)
				}
				return content
			}

			const UpdateZoomLevel = (zoomDirection) => {
				const clamp = (num, min, max) => Math.min(Math.max(num, min), max)
				const containerWidth = this.mediaGridRef.current.offsetWidth
				const currentCols = Math.floor(containerWidth / this.state.zoomTargetSize)
				const nextCols = clamp(currentCols + zoomDirection, 2, 12)
				const nextZoomTargetSize = Math.floor(containerWidth / nextCols)
				localStorage.setItem('targetZoomSize', nextZoomTargetSize)
				this.setState({ zoomTargetSize: nextZoomTargetSize})
			}

			return (
				<div className='media-library-panel'>
					<PanelHeader
						title='Media Objects'
						infoLink={process.env.REACT_APP_FRESHDESK_MEDIA_RESULTS}
					>
						<div className='io-subpanel-actions'>
							<Button
								variant='transparent'
								className='io-btn-light'
								onClick={() => this.setState({ uploadModalActive: true })}
							>
								Upload
							</Button>
							<FontAwesomeButton
								icon={faGrid2}
								size='1x'
								style={{ marginLeft: '15px', marginRight: '5px' }}
								variant='transparent'
								className='io-btn-light'
								tooltip={'Zoom In'}
								onClick={() => UpdateZoomLevel(-1)}
							/>
							<FontAwesomeButton
								icon={faGrid}
								size='1x'
								style={{ margin: '5px', marginRight: '0px' }}
								variant='transparent'
								className='io-btn-light'
								tooltip={'Zoom Out'}
								onClick={() => UpdateZoomLevel(1)}
							/>
						</div>
					</PanelHeader>
					<Content filteredItems={filteredItems} />
				</div>
			)
		}

		const MediaPreviewGrid = React.memo(MediaPreviewGridB)

		const TagSubPanel = () => {
			const hasSavedQueryies = this.state.savedSelections.length > 0

			return (
				<div className='tag-selector-panel'>
					<PanelHeader
						title='Tag Selector'
						infoLink={process.env.REACT_APP_FRESHDESK_MEDIA_TAG_SELECTOR}
					>
						<div className='io-subpanel-actions'>
							<Button
								variant='transparent'
								className='io-btn-light'
								onClick={this.promptForSaveName}
							>
								Save
							</Button>
							<Button
								variant='transparent'
								className='io-btn-light'
								onClick={() => {
									window.location =
										process.env.REACT_APP_PRE_PRODUCTION_URL +
										'/collection/' +
										this.state.firstColection
								}}
							>
								Edit
							</Button>
						</div>
					</PanelHeader>

					<div className='tag-selector'>
						<div
							className=''
							style={{
								width: '100%',
								display: hasSavedQueryies ? 'block' : 'none',
							}}
						>
							<div className='panel-subheader'>
								<div className='panel-subheader-content'>Saved Selections</div>
								<div></div>
							</div>
							<ul className='tag-saved-selections'>
								{map(
									get(this.state, 'savedSelections', []),
									(savedSelection, ind) => {
										return (
											<li>
												<Button
													key={ind}
													variant='transparent'
													className='io-btn-light'
													onClick={() => {
														this.loadSelection(savedSelection)
													}}
												>
													{savedSelection.name} &nbsp;{' '}
													<FontAwesomeIcon
														icon={faTimes}
														onClick={() =>
															this.setState({
																savedSelections:
																	this.state.savedSelections.filter(
																		(x) => x.name !== savedSelection.name
																	),
															})
														}
													></FontAwesomeIcon>
												</Button>
											</li>
										)
									}
								)}
							</ul>
						</div>

						<div className='tag-selector'>
							<div className='panel-subheader'>
								<div className='panel-subheader-content'>Selection</div>
								<Button
									disabled={this.state.tags <= 0}
									variant='transparent'
									className='io-btn-light'
									onClick={this.clearSelectedTags}
								>
									CLEAR
								</Button>
							</div>

							<div
								style={{
									display: EnableTagModeToggles ? 'inline-block' : 'none',
									position: 'relative',
									float: 'right',
								}}
							>
								<div
									style={{
										color: 'white',
										display: 'inline-block',
										fontSize: 'smaller',
										verticalAlign: 'middle',
									}}
								>
									Match: &nbsp;{' '}
								</div>

								<ButtonGroup style={{ display: 'inline-block' }}>
									<Button
										variant={this.state.mode === Modes.all ? 'warning' : 'dark'}
										style={{ padding: '0px', fontSize: 'smaller' }}
										onClick={() => this.setState({ mode: Modes.all })}
									>
										&nbsp; All Groups &nbsp;
									</Button>
								</ButtonGroup>
							</div>

							<ul className='treeview'>
								{map(parentTags, (parentTag, ind) => {
									return (
										<TagSection
											key={ind}
											tags={childTags.filter((x) => x.parent === parentTag.id)}
											parent={parentTag}
										/>
									)
								})}
							</ul>
						</div>
					</div>
				</div>
			)
		}

		return (
			<main
				className='main-content'
				ref={this.ref}
			>
				<UseEventCallback
					event='swap-active'
					callback={this.setSwapState}
				/>
				<UseEventCallback
					event='add-media-to-source-query'
					callback={this.setAddState}
				/>

				<MediaUploadModal
					show={this.state.uploadModalActive}
					token={token}
					collection={this.state.firstColection}
					onClose={() => {
						this.setState({ uploadModalActive: false })
						this.getClipsFromServer()
					}}
				></MediaUploadModal>
				<MediaPreviewGrid items={this.state.items} />
				<TagSubPanel />
				<InputDialog
					show={this.state.showDialog}
					header='Name Your Selection'
					onConfirm={(name) => {
						this.saveSelection(name)
					}}
					onCancel={() => {
						this.setState({ showDialog: false })
					}}
				/>
			</main>
		)
	}
}
