import React, { useEffect, useRef } from 'react'
import useSize from '@react-hook/size'

/**
 * 
 * @param {Object} onSizeChangeCB /the callback function that will be called when the component is resized. 
 * @returns 
 */
export default function SizedCanvas({onSizeChangeCB, children, style}) {
    
    const wrapperRef = useRef(null)
    const [width, height] = useSize(wrapperRef)
    
    useEffect(() => {
        onSizeChangeCB(width, height)
    }, [width, height])

    return (
        <div ref={wrapperRef} style={style}>
            {children}
        </div>
    )
}
