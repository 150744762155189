import React, { useReducer, useEffect, useState, useRef } from "react"

//styling
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/css/default.css"
import "./assets/css/react-tags.css"
import "./assets/css/layout-system.css"
import "./assets/css/resolution-scale.css"
import "../node_modules/flexlayout-react/style/dark.css"
// Navbar
import Navbar from "./components/navbar/navbar"

//panels
import InputMonitorPanel from "./panels/input-monitor/input_monitor"
import PreviewPanel from "./panels/preview/PreviewPanel"
import MediaItemPanel from "./panels/media-items/media_items"
import ProgramOutPanel from "./panels/program-out/program_out"
import TemplatePanel from "./panels/template/template"

// auth0
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import Reducer from "./reducers/loginReducer"
import { getUserProfile, getRole } from "./services/api"
import { ToastContainer } from "react-toastify"

import GetEventFromURL from "./util/getEventFromURL"
import * as Hub from "./services/api"
import SequencePanel from "./panels/sequence/sequencePanel"
import * as FlexLayout from "flexlayout-react"
import preset_layouts from "./constants/preset_layouts"
import useSize from "@react-hook/size"
import 'react-toastify/dist/ReactToastify.css'
import TemplateContextWrapper from "./templateContext"
import InputMappingContextWrapper from "./panels/input-monitor/inputMappingContext"


const Modes = {
  EditorMode: 1,
  LiveMode: 2
}

const defaultLayout = preset_layouts[0]
const defaultLayoutModel = FlexLayout.Model.fromJson(defaultLayout.layout)

const App = () => {
  const AppCotainerRef = useRef()
  const { isLoading } = useAuth0()
  const [state, dispatch] = useReducer(Reducer)
  const [event, setEvent] = useState(null)
  const [loaderUser, setLoaderUser] = useState(false)
  const [loadedToken, setLoadedToken] = useState(false)
  const [studioMode, setStudioMode] = useState(Modes.LiveMode)
  const [_, forceUpdate] = useReducer(x => x + 1, 0)
  const [currentLayoutModel, setCurrentLayoutModel] = useState(defaultLayoutModel)
  const [currentLayout, setCurrentLayout] = useState(defaultLayout)
  const [width, height] = useSize(AppCotainerRef)


  const getCurrentRole = token => {
    getRole(token).then(result => {
      dispatch({
        type: "USER_ABILITY_RECEIVED",
        payload: { ability: result }
      })
    })
  }

  const getEventFromHub = () => {
    const event = GetEventFromURL()
    if(!event || event === "") return
    Hub.getEvent(event).then((result) => {
      setEvent(result)
    })
  }

  useEffect(() => {
    const freshAuthToken = JSON.parse(
      localStorage.getItem(
        `@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::default::openid profile email`
      ) || "{}"
    ).body.id_token
    if (freshAuthToken) {
      localStorage.setItem("auth0_token", freshAuthToken)
      setLoadedToken(!loadedToken)
    }
    getEventFromHub()
    getLayoutFromLocalStorage()
    getCurrentRole(freshAuthToken)
  }, [])

  useEffect(() => {
    const authToken = localStorage.getItem("auth0_token")
    const loggedInUser = localStorage.getItem("userProfile")
    if (loggedInUser) {
      dispatch({
        type: "USER_PROFILE_RECIVED",
        payload: { user: loggedInUser }
      })
      setLoaderUser(true)
    } else if (authToken) {
      getUserProfile(authToken).then(result => {
        if (result.user) {
          dispatch({
            type: "USER_PROFILE_RECIVED",
            payload: { user: result.user }
          })
          localStorage.setItem("userProfile", JSON.stringify(result.user))
        } else if (result.user === null) {
          return (window.location = "/")
        }
      })
      setLoaderUser(true)
    }
    getCurrentRole(authToken)
  }, [loadedToken])

  const getLayoutFromLocalStorage = () => {
    const data = localStorage.getItem('saved_layout')
    if (!data) return
    const JSONdata = JSON.parse(data)
    const savedLayoutModel = FlexLayout.Model.fromJson(JSONdata)
    setCurrentLayoutModel(savedLayoutModel)
  }

  const saveLayoutToLocalStorage = (layout) => {
    localStorage.setItem('saved_layout', JSON.stringify(layout))
  }

  const updateLayout = (layout) => {
    const newLayoutModel = FlexLayout.Model.fromJson(layout)
    setCurrentLayoutModel(newLayoutModel)
    setCurrentLayout(layout)
  }

  const layoutComponentFactory = (node) => {
    var component = node.getComponent();
    if (component === "button") {
        return <button>{node.getName()}</button>;
    }
    if (component === "preview") {
      return (<PreviewPanel></PreviewPanel>)
    }
    if (component === "live_sources") {
      return (<InputMonitorPanel></InputMonitorPanel>)
    }
    if (component === "media_items") {
      return (<MediaItemPanel></MediaItemPanel>)
    }
    if(component === "output_monitor") {
      return (<ProgramOutPanel></ProgramOutPanel>)      
    }
    if(component === "sequence_editor") {
      return (<SequencePanel></SequencePanel>)      
    }
    if(component === "template_editor") {
      return (<TemplatePanel></TemplatePanel>)      
    }

  }

  const layoutChanged = (layout) => {
    const data = layout.toJson()
    saveLayoutToLocalStorage(data)
  }


  if (isLoading || !state || !state.user || !event) {
    return (
      <>
       
      </>
    )
  }

  return (
    <div ref={AppCotainerRef} style={{display: "flex", width: "100vw", height: "100vh", flexDirection: "column"}}>
      <Navbar
        setMode={mode => setMode(mode)}
        mode={studioMode}
        layoutCB={updateLayout}
        event={event}
      />

      <div className="panel_container" style={{margin: `5px`, marginTop: "0px", flexGrow: "1"}}>
        <TemplateContextWrapper>
          <InputMappingContextWrapper>
            <FlexLayout.Layout model={currentLayoutModel} splitterSize={"1px"} factory={layoutComponentFactory} onModelChange={layoutChanged}/>
          </InputMappingContextWrapper>
        </TemplateContextWrapper> 
      </div>
      
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  )

}

export default withAuthenticationRequired(App)
