import * as React from "react"

const MoreIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="4"
      fill="none"
      viewBox="0 0 18 4"
      {...props}
    >
      <path
        fill="#fff"
        d="M2 4a2 2 0 100-4 2 2 0 000 4zM8.667 4a2 2 0 100-4 2 2 0 000 4zM15.333 4a2 2 0 100-4 2 2 0 000 4z"
      ></path>
    </svg>
  )
}
export default MoreIcon

