import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default memo(({data, isConnectable}) => {
  return (
    <div className="react-flow__node-default sceneNode nodeStyle">
      <Handle
        type="target"
        id="a"
        position="top"
        style={{ background: '#555'}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        id="b"
        position="left"
        style={{ background: '#555', width: "10px",height: "10px", top: "50%"}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <img className="preview" style={{height: "auto", marginBottom: "5px"}} src={data.preview}></img>
      <Handle
        type="source"
        position="bottom"
        id="a"
        style={{background: '#555' }}
        isConnectable={isConnectable}
      />
    </div>
  );
});