import React, { useEffect, useState } from 'react'
import { Modal, Button, Dropdown } from 'react-bootstrap'

const resolutionOptions = [
	{ id: "1", name: '1080p', value: { w: 1920, h: 1080 } },
	{ id: "2", name: '720p', value: { w: 1280, h: 720 } },
]

const bitrateOptions = [
  {id: "1", name: '1Mbps', value: 1000},
  {id: "2", name: '2Mbps', value: 2000},
  {id: "3", name: '3Mbps', value: 3000},
  {id: "4", name: '4Mbps', value: 4000},
  {id: "5", name: '5Mbpss', value: 5000},
  {id: "6", name: '6Mbps', value: 6000},
  {id: "7", name: '7Mbps', value: 7000},
  {id: "8", name: '8Mbps', value: 8000},
  {id: "9", name: '9Mbps', value: 9000},
  {id: "10", name: '10Mbps', value: 10000},
]

export default function OutputSettingsModal({ show, onClose, onSave, currentResolution}) {

  const resolutionFromCurrent = resolutionOptions.find(res => res.value.w == currentResolution.w && res.value.h == currentResolution.h)
  
  const [resolution, setResolution] = useState(resolutionFromCurrent)

  useEffect(() => {
	const resolutionFromCurrent = resolutionOptions.find(res => res.value.w == currentResolution.w && res.value.h == currentResolution.h)
	setResolution(resolutionFromCurrent)
  }, [currentResolution])

  const saveOptions = () => { 
    onSave({resolution: resolution.value})
  }
  
	return (
		<Modal
			show={show}
			onHide={onClose}
			className='custom-modal'
			variant='dark'
			size='md'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Output Settings</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h5 style={{color: "white"}}>Resolution</h5>
        <p>Recomended to drop to 720p when on 4G</p>
				<Dropdown
					className='text-muted'
					style={{ maxWidth: '100%', width: '100%', fontSize: '1vw' }}
				>
					<Dropdown.Toggle
						variant='dark'
						id='dropdown-basic'
						className='fullWidth'
						style={{ width: '100%' }}
					>
            {resolution?.name || "Select a resolution"}
          </Dropdown.Toggle>
					<Dropdown.Menu
						variant='dark'
						className='fullWidth'
					style={{ maxWidth: '100%', width: '100%'}}
					>
						{resolutionOptions.map((resolutionOption) => {
							return (
								<Dropdown.Item
									key={resolutionOption.name}
									onClick={() => setResolution(resolutionOption)}
									className='text-center fullWidth text-limited'
								>
									{resolutionOption.name}
								</Dropdown.Item>
							)
						})}
					</Dropdown.Menu>
				</Dropdown>
        {/*<br></br>
        <h5 style={{color: "white"}}>Bitrate</h5>
        <p>Higher is better but lower setting may be more stable on poor connections</p>
        <p><b>Recomended Minimums:</b></p>
        <p>1080p - 6Mbps+</p>
        <p>720p - 3Mbps+</p>

        <Dropdown
					className='text-muted'
					style={{ maxWidth: '100%', width: '100%', fontSize: '1vw' }}
				>
					<Dropdown.Toggle
						variant='dark'
						id='dropdown-basic'
						className='fullWidth'
						style={{ width: '100%' }}
					>
            {bitrate?.name || "Select a bitrate"}
          </Dropdown.Toggle>
					<Dropdown.Menu
						variant='dark'
						className='fullWidth'
					style={{ maxWidth: '100%', width: '100%'}}
					>
						{bitrateOptions.map((bitrateOption) => {
							return (
								<Dropdown.Item
									key={bitrateOption.name}
									onClick={() => setBitrate(bitrateOption)}
									className='text-center fullWidth text-limited'
								>
									{bitrateOption.name}
								</Dropdown.Item>
							)
						})}
					</Dropdown.Menu>
				</Dropdown>
          */}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant='danger'
					onClick={onClose}
				>
					Cancel
				</Button>
				<Button
					variant='success'
					onClick={saveOptions}
				>
					Save
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
