import React, { Component } from "react"
import DropzoneS3Uploader from "react-dropzone-s3-uploader"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { createMediaObject } from "../services/api"
import { UploadIcon } from "./svgIcons"

const Megabyte = 1048576

const uploadOptions = {
  server: process.env.REACT_APP_API_SERVER,
  signingUrlQueryParams: { uploadType: "mediaObject" },
}

export default class MediaObjectUploader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toasts: [],
      isError: false
    }
  }

  onUploadError = (err) => {
    toast.error("Upload Error", {
      position: "bottom-right",
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: 0,
    })
  }

  onUploadProgress = (percent, message, file) => {

    console.log("FILE", file)

    var { toasts } = this.state
    let toastId = null
    try {
      toastId = toasts.find((x) => x.file === file.preview).toast
    } catch (e) {
      console.log(e)
    }
    switch (message) {
      case "Waiting":
        const uploadToast = toast.info("Uploading " + file.name, {
          position: "bottom-right",
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: 0,
          autoClose: false,
        })
        toasts.push({ file: file.preview, toast: uploadToast })
        break
      case "Uploading":
       
        toast.update(toastId, {
          hideProgressBar: false,
          progress: percent / 100,
        })
        break
      case "Finalizing":
        toast.update(toastId, {
          type: "success",
        })
        break
      case "Upload completed":
        toast.dismiss(toastId)
        break
      default:
        console.log("Unhandled Message", percent, message, file)
    }
    this.setState({ toasts: toasts })

  }
  onUploadFinish = (uploadInfo) => {  

    const { filename, fileKey, fileUrl, file } = uploadInfo

    const mimeBaseType = file.type.split("/")[0] //do we want to keep full mime type?

    const mediaObject = {
      url: fileUrl,
      uploadKey: fileKey,
      type: mimeBaseType,
      status: 0
    }
    
    const uploadEvent = {
      objectKey: fileKey,
      objectBucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
      collection: this.props.collection,
      mediaObject: mediaObject,
    }

    console.log("File upload complete, creating media object", uploadEvent)

    createMediaObject(this.props.token, uploadEvent)
      .then((res) => {
        this.props.onUploadComplete(res)
      })
      .catch((error) => {
        console.log("Error creating media object", error)
      })

  }

  render() {
    const UploadPlaceholder = () => {
      return (
        <div className="uploaderInside">
          <div className="uploaderInsideIcon">
            <UploadIcon />
          </div>
          <div className="uploaderInsideText">
            Upload icon by dragging here or browse files <u>browse files</u>
          </div>
        </div>
      )
    }

    return (
      <>
        <DropzoneS3Uploader
          className="io-dropzone"
          s3Url={process.env.REACT_APP_AWS_S3_USER_UPLOAD_URL}
          accept={process.env.REACT_APP_UPLOAD_ALLOWED_TYPES}
          maxSize={
            Megabyte * 1024 * process.env.REACT_APP_UPLOAD_ITEM_MAX_SIZE_GB
          }
          upload={uploadOptions}
          onProgress={this.onUploadProgress}
          onFinish={this.onUploadFinish}
          onError={this.onUploadError}
        >
          <UploadPlaceholder />
        </DropzoneS3Uploader>
      </>
    )
  }
}
