import React from 'react';

//components
import {ListGroup} from 'react-bootstrap';
import { Handle } from 'react-flow-renderer';

//utils
import {cloneDeep} from 'lodash'

//config
const MaxSideBarWidth = 300


const onDragStart = (event, nodeData) => {
  event.dataTransfer.setData('application/reactflow', JSON.stringify(cloneDeep(nodeData)));
  event.dataTransfer.effectAllowed = 'move';
  if(nodeData.type === 'input') {
    event.dataTransfer.setDragImage(new Image(), 0, 0)
  }
};


//node types
const SequenceStartNode = ({onDragFunction}) => {
  return (
    <div className="react-flow__node-input node"  onDragStart={(event) => onDragFunction(event, {type: "input"})} draggable>
      Sequence Start
      <Handle
        type="source"
        id="a"
        position="bottom"
        style={{ background: '#555'}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={true}
      />
    </div>
  )
}

const SequenceEndNode = ({onDragFunction}) => {
  return (
    <div className="react-flow__node-output node"  onDragStart={(event) => onDragFunction(event, {type: "output"})} draggable>
      <b>End Sequence</b>
    </div>
  )
}

const SequenceLoopNode = ({onDragFunction}) => {
  return (
    <div className="react-flow__node-output node"  onDragStart={(event) => onDragFunction(event, {type: "loopNode"})} draggable>
      <b>Loop Sequence</b>
    </div>
  )
}

const ButtonGrpupNode = ({onDragFunction}) => {
  return (
    <div className="react-flow__node-output node"  onDragStart={(event) => onDragFunction(event, {type: "buttonNode"})} draggable>
      <b>Button Group</b>
      <br></br>
      <button>1</button>
      <button>2</button>
      <button>3</button>
      <button>4</button>
    </div>
  )
}

const ContentNode = ({onDragFunction, tags}) => {
  return (
    <div className="react-flow__node-default node" onDragStart={(event) => onDragFunction(event, {type: "content", tags: tags})} draggable >
      <div style={{marginBottom: "5px", padding: "5px"}}>
          <p>Content Node</p>
          <div style={{fontSize: "8px"}}>
              Tags: <b style={{background: "#F90", padding: "2px", margin: "2px", borderRadius: "2px"}}>{tags[0]}</b> <b style={{background: "#F90", padding: "2px", margin: "2px", borderRadius: "2px"}}>{tags[1]}</b>
          </div>
      </div>
      <Handle
        type="source"
        position="right"
        id="content_source"
        style={{background: '#555' }}
        isConnectable={true}
      />
    </div>
  )
}

const SceneNode = ({scene, onDragFunction}) => {
  return (
    <ListGroup.Item className="react-flow__node-default node" style={{width: "calc(100% - 10px)", padding: "0px"}} onDragStart={(event) => onDragFunction(event, {type: "sceneNode", preview: scene.preview, lable: scene.label, layout: scene.layout, runtime: scene.maxLength})} draggable>
      <img style={{width: "100%", height: "auto"}} src={scene.preview}></img>
    </ListGroup.Item>
  )
}

const SceneList = ({sceneData}) => {
  
  const Scenes = sceneData.map(scene =>{
    return (
      <SceneNode scene={scene} onDragFunction={onDragStart}/>
    )
  })

  return (
    <ListGroup style={{overflowY: "scroll", maxHeight: "890px", height: "100%"}}>
      {Scenes}
    </ListGroup>
  )

}

const Sidebar = (props) => {
  return (
    <div style={{color: "white", padding:"var(--panelPadding)"}}>
      
      <b>Control Nodes</b>
      <SequenceStartNode onDragFunction={onDragStart}/>
      <SequenceEndNode onDragFunction={onDragStart}/>
      <ContentNode tags={["replay" ,"heighlights"]} onDragFunction={onDragStart}/>
      <SequenceLoopNode onDragFunction={onDragStart}/>
      <ButtonGrpupNode onDragFunction={onDragStart}></ButtonGrpupNode>
      <b>Scenes</b>
      <SceneList sceneData ={props.scenes} style={{flexGrow: "1"}}/>

    </div>
  );
};

export default Sidebar;