import * as React from "react"

const InfoIcon = ({ ...props }) => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width={12.249}
    //   height={12.249}
    //   {...props}
    // >
    //   <g data-name="Group 1378" fill="#fff">
    //     <path
    //       data-name="Path 103"
    //       d="M6.729 3.619a.6.6 0 1 1-.6-.6.6.6 0 0 1 .6.6"
    //     />
    //     <path
    //       data-name="Path 104"
    //       d="M6.125.612A5.512 5.512 0 1 1 .613 6.124 5.518 5.518 0 0 1 6.125.612m0-.612a6.125 6.125 0 1 0 6.125 6.125A6.125 6.125 0 0 0 6.125 0Z"
    //     />
    //     <path data-name="Path 105" d="M5.696 5.048h.857v4.187h-.857Z" />
    //   </g>
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="none"
      viewBox="0 0 15 15"
      {...props}
    >
      <path
        fill="currentColor"
        d="M8.24 4.431a.735.735 0 11-1.47 0 .735.735 0 011.47 0"
      ></path>
      <path
        fill="currentColor"
        d="M7.5.75A6.75 6.75 0 11.75 7.498 6.757 6.757 0 017.5.749zm0-.75a7.5 7.5 0 100 15 7.5 7.5 0 000-15z"
      ></path>
      <path fill="currentColor" d="M6.975 6.181h1.05v5.127h-1.05V6.181z"></path>
    </svg>
  )
}
export default InfoIcon

