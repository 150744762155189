import React, { useState, useEffect } from "react";
import RenderTargetToolbar from "./RenderTargetToolbar";
import { Rnd } from "react-rnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGalleryThumbnails, faVideo,
  faPlay,
  faImage,
  faIcons
} from "@fortawesome/pro-light-svg-icons";
import sourceTypes from "../../../constants/sourceTypes";
import useKeyPress from "../../../util/useKeyPress";
import AlignmentManager from "./AlignmentManager";


export default function RenderTarget({
  sources,
  source,
  index,
  content,
  isSelected,
  canvasWidth,
  canvasHeight,
  onPositionChanged,
  onPositionChanging,
  onTargetExchanged,
  onTargetSelected,
  onTargetRemoved,
  handleDragOver,
  handleDrop,
  toggleSourceLock,
}) {
  
  const [showOutline, setShowOutline] = useState(false);

  const [snapGuideLineX, setSnapGuideLineX] = useState(null);
  const [snapGuideLineY, setSnapGuideLineY] = useState(null);

  const ShiftPressed = useKeyPress("Shift");
  const DeletePressed = useKeyPress("Delete");
  const ControlPressed = useKeyPress("Control");

  useEffect(() => {
    if(DeletePressed && isSelected) {
      onTargetRemoved(source.id)
    }
  }, [DeletePressed])

  //conditionals
  let outline = {};
  let toolbarVisible = {}

  if (isSelected) {
    outline = { outline: "none", outlineOffset: "-1px" };
    toolbarVisible = {visibility: "visible"}
  }

  const MapIcons = (type) => {
    switch (type) {
      case sourceTypes.webRTC:
        return faVideo;
      case sourceTypes.mediaQuery:
        return faGalleryThumbnails;
      case sourceTypes.video:
        return faPlay;
      case sourceTypes.SVG:
        return faIcons
      default:
        return faImage;
    }
  };


  //inline subcomponents
  const RenderTargetTitle = () => {
    return (
      <div
        className={"render-target-title-inner"}
        style={{ height: "auto", zIndex: 1001, ...toolbarVisible}}
      >
        <FontAwesomeIcon
          icon={MapIcons(source.type)}
          size="1x"
          color="white"
          style={{ marginLeft: "10px", display: "inline-block" }}
        />
        <p className="live-source-preview-text">{source.name}</p>
      </div>
    );
  };

  /**
   * The render target is the window in the space that will be filled with content
   * This component wraps the underlying content with all the controls
   */
  return (
    <>
    <Rnd
      key={source.id}
      
      //styling
      className="sourceContainer"

      style={{ 
        zIndex: source.position.z, 
        ...outline
      }}
      
      //lockable functions
      disableDragging={source.lockedAR}
      enableResizing={!source.lockedAR}
      lockAspectRatio={!ShiftPressed}

      //positioning
      size={{
        width: source.position.width * canvasWidth,
        height: source.position.height * canvasHeight,
      }}
      
      position={{
        x: source.position.x * canvasWidth,
        y: source.position.y * canvasHeight,
      }}

      bounds='parent'
      
      minWidth={5}
      minHeight={5}

      //action handlers
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      
      onDrag={(e, dropPosition) => {
        e.preventDefault()
        
        if(source.lockedAR) return;

        const nextPosition = AlignmentManager.snapToOtherSourcesOnDrag(source, sources, dropPosition, canvasWidth, canvasHeight, ControlPressed)
        
        setSnapGuideLineX(nextPosition.guideLineX)
        setSnapGuideLineY(nextPosition.guideLineY)
        onPositionChanging(nextPosition.position, index);
      }}
      onDragStart={(e) => {
        e.preventDefault();
        onTargetSelected(index);
        setShowOutline(true)
      }}
      onDragStop={(e, dropPosition) => {
        setShowOutline(true)
        setSnapGuideLineX(null)
        setSnapGuideLineY(null)
      }}
      onResizeStart={(e) => {
        //e.preventDefault();
      }}  
      onResize={(e, direction, ref, delta, position) => {
      
        e.preventDefault()

        if(source.lockedAR) return;

        const nextPosition = AlignmentManager.snapToOtherSourcesOnResize(source, sources, ref, direction, position, canvasWidth, canvasHeight, ControlPressed, ShiftPressed)
        
        setSnapGuideLineX(nextPosition.guideLineX)
        setSnapGuideLineY(nextPosition.guideLineY)
        onPositionChanged(nextPosition.position, index);
      }}
      onResizeStop={() => {
        setSnapGuideLineX(null)
        setSnapGuideLineY(null)
      }}
      onClick={(e) => {
        onTargetSelected(index);
        e.stopPropagation();
      }}
    >
      <div style={{width: `100%`, height: `100%`, zIndex: 2, background: source.backgroundColor}}>
        {content}
      </div>
      
    </Rnd>

      {isSelected &&
        <div style={{position: "absolute", left: source.position.x*canvasWidth, top: source.position.y*canvasHeight, zIndex: "1001", display: isSelected ? "inline-block" : "none", pointerEvents: "none", outline: showOutline ? "1px solid #1cabe4" : "1px solid RGBA(200, 200, 255, 0.5)", outlineOffset: "-1px", width: `${source.position.width*canvasWidth}px`, height: `${source.position.height*canvasHeight}px`}}>
            <RenderTargetToolbar
              toolbarVisible={{ ...toolbarVisible}}
              onTargetExchanged={() => onTargetExchanged(index)}
              onTargetRemoved={() => onTargetRemoved(source.id)}
              locked={source.lockedAR}
              toggleSourceLock={() => toggleSourceLock(index)}
            />
            <RenderTargetTitle/>
        </div>
      }
      <AlignmentManager.GuideLineVertical 
        x={snapGuideLineX} 
        height={canvasHeight} 
        thickness={2} 
        color={"#1cabe4"}
      />
      <AlignmentManager.GuideLineHorizontal 
        y={snapGuideLineY} 
        width={canvasWidth} 
        thickness={2} 
        color={"#1cabe4"}
      />
    </>
  );
}
