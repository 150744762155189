import React, { useState, useRef, useEffect } from "react"
import { Button, Modal, Form } from "react-bootstrap"

const Keys = {
  Enter: 13,
}
export default function InputDialog({ show, header, onCancel, onConfirm }) {
  const [value, setValue] = useState("")
  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [show])

  return (
    <>
      <Modal show={show} varient="dark" className="custom-modal" onHide={onCancel} centered>
        <Modal.Header>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            ref={inputRef}
            type="text"
            placeholder="name"
            onChange={(e) => setValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                onConfirm(value)
              }
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="transparent" className="btn-light-outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => onConfirm(value)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
