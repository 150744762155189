import React from "react"
import { Button, Modal } from "react-bootstrap"

export function LinkShareDialog({
  show,
  onClose,
  onDelete,
  header,
  content,
}) {
  return (
    <>
      <Modal show={show} onHide={onClose} width="500px">
        <Modal.Header closeButton>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
