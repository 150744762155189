import React, { Component } from "react";
import { Fade } from "react-slideshow-image";
import { Image } from "react-bootstrap";
import "react-slideshow-image/dist/styles.css";

export default class MediaQuerySourcePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaObjects: [],
      type: "slideshow",
    };
  }

  componentDidMount() {
    if(this.props.source.mediaQuery) {
      this.processMediaQuery(this.props.source.mediaQuery);
    }
  }

  componentWillReceiveProps() {
    this.processMediaQuery(this.props.source.mediaQuery);
  }

  processMediaQuery = (query) => {
    switch (query.type) {
      case "MediaObjectList":
        //need to implement backend call for retriving objects.
        const mediaObjects = query.items;
        this.setState({ mediaObjects: mediaObjects});
        break;
      default:
        const mediaObjects2 = query.items;
        this.setState({ mediaObjects: mediaObjects2});
        break;
    }
  };

  getMediaObjectsHTML = (mediaObject, index) => {
    switch (mediaObject.type) {
      case "image":
        return (
          <Image
            key={mediaObject.id + this.props.source.id}
            ref={index === 0 ? this.props.canvasPreviewRef : this.props.canvasPreviewRef}
            src={mediaObject.url}
            style={{ width: "100%", height: "100%", objectFit: this.props.source.fillMode }}
          />
        );
    }
  };

  handleDrop = (event) => {
    console.log("Drop", event);
  };

  render() {
    const mediaObjects = this.state.mediaObjects;

    if (mediaObjects.length === 0) {
      return (
        <div
          className="media-query-preview-container"
          style={{background: this.props.source.backgroundColor}}
          onDrop={this.handleDrop}
        ></div>
      );
    }
    return (
      <div className="media-query-preview-container" style={{background: this.props.source.backgroundColor}} onDrop={this.handleDrop}>
        {mediaObjects.length > 0 ? (
          <Fade
            style={{ height: "100%", width: "100%", display: "flex"}}
            nextArrow={<></>}
            prevArrow={<></>}
            autoplay={true}
            pauseOnHover={false}
            canSwipe={false}
            transitionDuration={500}
            duration={this.props.source.duration}
            cssClass="fader"
          >
            {this.state.mediaObjects.map((mediaObject, index) =>
              this.getMediaObjectsHTML(mediaObject, index)
            )}
          </Fade>
        ) : (
          <div></div>
        )}
        <div style={{display: "none"}}>
          <img ref={this.props.canvasOverlayRef} src = "/Stackicon.png"></img>
        </div>
      </div>
    );
  }
}
