

export const ScoreBoardFields = [
	{name: "team1ShortNames", type: "string", defaultValue: "TM1", value: "USE TEMPLATE"}, 
	{name: "team2ShortName", type: "string", defaultValue: "TM2", value: "USE TEMPLATE"},
	{name: "primaryColor", type: "string", defaultValue: "#FF0000", value: "USE TEMPLATE"},
	{name: "secondaryColor", type: "string", defaultValue: "#FFFFFF", value: "USE TEMPLATE"},
	{name: "scoreTeam1", type: "number", defaultValue: 0, value: "USE TEMPLATE"},
	{name: "scoreTeam2", type: "number", defaultValue: 0, value: "USE TEMPLATE"}
]

export default function ScoreBoard({team1ShortName="TM1", team2ShortName="TM2", primaryColor="#FF0000", secondaryColor="#000000", scoreTeam1="0", scoreTeam2="0", textColor}) {
  
	const style = `.cls-1{letter-spacing:-.04em;}.cls-2{letter-spacing:-.01em;}.cls-3{letter-spacing:-.09em;}.cls-4{fill:${primaryColor};}.cls-5{fill:#e5e4e4;}.cls-6,.cls-7{fill:${secondaryColor};}.cls-7,.cls-8{font-family:Roboto-Bold, Roboto;font-size:26.34px;font-weight:700;}.cls-8{fill:#fff;}.cls-9{letter-spacing:-.03em;}.cls-10{letter-spacing:-.06em;}.cls-11{letter-spacing:-.06em;}.largeText{font-size:26.34px;}`

	return (
	`<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" width="345" height="47" viewBox="0 0 344.94 46.71">
	<defs>
		<style>
			${style}
		</style>
	</defs>
	<g id="Layer_1-2" style="font-family: &quot;Yu Gothic UI&quot;;">
		<polygon class="cls-7" points="320.25 44.25 24.09 44.25 0 2.46 344.94 2.46 320.25 44.25" />
		<polygon class="cls-4" points="218.95 46.71 125.84 46.71 100.13 0 244.76 0 218.95 46.71" />
		<text class="largeText" transform="translate(40.08 33.48)">
			<tspan class="cls-5" x="0" y="0" style='fill: white;'>
				${team1ShortName}
			</tspan>
		</text>
		<text class="largeText" transform="translate(170 32)">
			<tspan class="cls-5" x="0" y="0" style='fill: ${textColor};'>
				-
			</tspan>
		</text>
		<text class="largeText" transform="translate(150 32)">
			<tspan class="cls-5" x="0" y="0" style='fill: ${textColor}; text-anchor: middle;'  >
				${scoreTeam1}
			</tspan>
		</text>
		<text class="largeText" transform="translate(200 32)">
			<tspan class="cls-5" x="0" y="0" style='fill: ${textColor}; text-anchor: middle;' >
				${scoreTeam2}
			</tspan>
		</text>
		<text class="largeText" transform="translate(251.97 33.48)">
			<tspan class="cls-5" x="0" y="0" style='fill: white;'>
				${team2ShortName}
			</tspan>
		</text>
	</g>
</svg>`
  )
}

