export const HalfTimeFields = [
    {
        name: "gameMode",
        type: "text",
        value: "Half Time",
    },
]

export default function HalfTimeScore({team1Name, team2Name, scoreTeam1, scoreTeam2, gameMode="Half Time", primaryColor, secondaryColor, textColor, halfTimeVisible=true}) {

	const style = `.cls-1{}.cls-2{}.cls-3{}.cls-4{fill:${primaryColor};}.cls-5{fill:#e5e4e4;}.cls-6,.cls-7{fill:${secondaryColor};}.cls-7,.cls-8{}.cls-8{fill:${textColor};}.cls-9{}.cls-10{}.cls-11{}.largeText{font-size:26.34px;}`


	if (!halfTimeVisible) {
		return `<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" width="345" height="47" viewBox="0 0 344.94 46.71"></svg>`;
	}

  return (
`<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" width="3000" height="300" viewBox="0 0 1222.77 120.58">
	<defs>
		<style>
			${style}
		</style>
	</defs>
	<g id="Layer_1-2" style="font-family: Yu Gothic UI;" >
		<polygon class="cls-7" points="1178.44 114.27 44.52 114.27 0 40.86 1222.77 40.86 1178.44 114.27" /> {/* Body */}
		
		<polygon class="cls-5" points="1173.92 120.58 48.28 120.58 44.56 114.27 1178.44 114.27 1173.92 120.58" /> {/* Bottom line */}
		
		<polygon class="cls-5" points="848.57 53.47 374.98 53.47 352.59 0 870.42 0 848.57 53.47" />
		<polygon class="cls-4" points="708.46 114.27 515.02 114.27 479.31 53.47 743.7 53.47 708.46 114.27" />
		
		<polygon class="cls-4" points="58.76 114.27 102.25 114.27 58.36 40.86 13.89 40.86 58.76 114.27" />
		<polygon class="cls-4" points="1160.95 114.27 1117.45 114.27 1161.35 40.86 1205.82 40.86 1160.95 114.27" />
		<text transform="translate(270.27 93.58)" text-anchor="middle">
			<tspan x="0" y="0" style="font-size: 30px; fill: RGB(255, 255, 255);">
				${team1Name}
			</tspan>
		</text>
		<text transform="translate(610.53 34.36)" text-anchor="middle">
			<tspan x="0" y="0" style="font-size: 30px;">
				${gameMode}
			</tspan>
		</text>
		<text transform="translate(945.58 93.58)" text-anchor="middle" style="font-size: 30px; fill: RGB(255, 255, 255);">
			<tspan x="0" y="0">
				${team2Name}
			</tspan>
		</text>
		<text class="cls-5" transform="translate(610 102)" text-anchor="middle">
			<tspan x="0" y="0" style='font-size: 50px; fill: ${textColor};'>
				-
			</tspan>
		</text>
		<text class="cls-5" transform="translate(560 102)" text-anchor="middle">
			<tspan x="0" y="0" style='font-size: 50px; fill: ${textColor};'>
				${scoreTeam1}
			</tspan>
		</text>
		<text class="cls-5" transform="translate(655 102)" text-anchor="middle">
			<tspan x="0" y="0" style='font-size: 50px; fill: ${textColor};'>
				${scoreTeam2}
			</tspan>
		</text>
	</g>
</svg>`
  )
}


