import React, { useState, useRef, useEffect, useCallback} from 'react';
import useStateRef from 'react-usestateref'
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  Controls,
  MiniMap,
  updateEdge,
  useZoomPanHelper,
  ControlButton,
  isEdge,
  Background,
  removeElements
} from 'react-flow-renderer';

import { v4 as uuidv4 } from 'uuid';
import {Button} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Player from './player'

import TestScenes from '../../test-data/scenes.json'
import SceneNode from "./scene-node"
import ContentNode from "./tag-node"

import GraphPlayback, {checkForStartNode} from '../../graph-engine'

import Sidebar from './sidebar';
import PropertyBar from './property-bar'

import '../styles/sidepanel.css';
import { faBoxOpen, faFastForward, faPlay, faSave, faStop, faUpload } from '@fortawesome/free-solid-svg-icons';

import "../styles/nodes.css"
import getEventFromURL from '../../../../util/getEventFromURL';
import * as Hub from "../../../../services/api"
import PanelHeader from '../../../components/PanelHeader';
import { cloneDeep } from 'lodash';
import LoopNode from './loopNode';
import ButtonNode from './buttonNode';

const flowKey = 'example-flow';



const connectionLineStyle = { stroke: '#fff', strokeWidth: "2"};
const snapGrid = [20, 20];
const defaultNodeWidth = 160

const onDragOver = (event) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'move';
};

const StartNodeData = {label: 'Show Start [1]', role: "show_start", cueKey: 1}

const initialElements = [{ 
    id: '1', 
    type: 'input', 
    data: StartNodeData,
    sourcePosition: 'bottom', 
    targetPosition: 'top', 
    position: { x: 260, y: 20 }, 
    style: {width: `${defaultNodeWidth}px`} 
}];

const fmtMSS = (s) => {return(s-(s%=60))/60+(9<s?':':':0')+s}


const nodeTypes = {
    sceneNode: SceneNode,
    content: ContentNode,
    loopNode: LoopNode,
    buttonNode: ButtonNode
  };

const getId = () => `dndnode_${uuidv4()}`;

let sequence = 1;
const getSequence = () => `Sequence ${sequence++}`

var target = null;

const DnDFlow = (props) => {
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState();
  const [elements, setElements, elementsRef] = useStateRef(initialElements);
  const [selectedElement, setSelectedElement] = useState(null)
  const [graphPlayer, setGraphPlayer, graphPlayerRef] = useStateRef(null);
  const [scenes, setScenes] = useState([]);
  const ref = useRef(null)


  useEffect(() => {
    const scenes = loadScenesFromHub(getEventFromURL(), "token")
    
    const keyDownFunc = (key) => {
      console.log("KEYDOWN", key)
    }
    window.addEventListener("keydown", (key) => keyDownFunc, true);
  
    //props.glEventHub.on("ButtonPress", buttonPress)
    //props.glEventHub.on("nextScene", onNext)


  }, [])

  useEffect(() => {
    console.log("SCENES", scenes)
  }, [scenes])

  const buttonPress = (btn) => {
    console.log("REC PRESS")
    graphPlayerRef.current.jumpViaHandle(btn)
  }

  const loadScenesFromHub = (event, token) => {
    Hub.getEventScenes(token, event)
      .then((result) => {
        setScenes(result)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onConnect = (params) => {
    
    const defaultNewEdge = (edgeData) => {
      return {
        style: {strokeWidth: "2"},
        arrowHeadType: "arrow", 
        label: edgeData.mode, 
        data: edgeData
      }
    }

    var edgeData = {type: "sequence_flow", mode: "auto"}

    //set edge type based on source handle type
    if(params && params.sourceHandle) {
      if(params.sourceHandle.toString() === "content_source") edgeData = {type: "content_flow", mode: "first"}
    }

    //add the edge to the graph
    setElements((elements) => addEdge({...params, ...defaultNewEdge(edgeData)}, elements))

    console.log(`Conected Nodes: ${params.source} - ${params.target} with edge type: ${edgeData.type}`)
  }

  const onElementsRemove = (elementsToRemove) => {
    const filteredElementsToRemove = elementsToRemove.filter(element => checkForStartNode(element))
    setElements((els) => removeElements(filteredElementsToRemove, els))
    setSelectedElement(null)
  }

  const onLoad = (_reactFlowInstance) => setReactFlowInstance(_reactFlowInstance);

  const onElementClick = (event, element) => {
      console.log('click', element)
      setSelectedElement(element);
      if(graphPlayer) {
        graphPlayer.jump(element.id)
      }
  };

  const updateElement = (updateElement) => {
    if(updateElement.type === "sceneNode") {
        const index = elementsRef.current.findIndex(x => x.id === updateElement.id)
        var element = elementsRef.current
        element[index] = updateElement
        setElements((elements) => {return element})
        setElements((es) => es.concat({}));
    }

    if(updateElement.type === "input") {
      const index = elementsRef.current.findIndex(x => x.id === updateElement.id)
      var element = elementsRef.current
      element[index] = updateElement
      setElements((elements) => {return element})
      setElements((es) => es.concat({}));
  }

    if(updateElement.source !== undefined) {
        ///this is an edge
        console.log("edge update top")
        const index = elementsRef.current.findIndex(x => x.id === updateElement.id)
        var element = elementsRef.current
        element[index] = updateElement
        if(updateElement.data.mode === "content_finish") {
          const sourceNode = elementsRef.current.find(x => x.id === updateElement.source)
          element[index].label = "Finish: " + fmtMSS(sourceNode.data.runtime)
          element[index].data.time = sourceNode.data.runtime
        }
        setElements((elements) => {return element})
        setElements((es) => es.concat({}));
    }
    
  }

  //const { transform } = useZoomPanHelper;


  const updateOneElement = (els, updateElement) => {
    if(!els) return {}
    return els.map((el) => {
      if (el.id === updateElement.id) {
        return {...el, updateElement}
      } 
        return {...el}      
    })
  }

  const onSave = useCallback(() => {
    if (reactFlowInstance) {
      const flow = JSON.stringify(reactFlowInstance.toObject());
      localStorage.setItem(flowKey, flow);
    }
  }, [reactFlowInstance]);

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const f = await localStorage.getItem(flowKey);
      const flow = JSON.parse(f)
      if (flow) {
        const [x = 0, y = 0] = flow.position;
        const restoredElements = flow.elements.map((element) => {return {...element, data: {...element.data, removeMe: () => {onElementsRemove([{id: element.id}])}}}})
        setElements(restoredElements || []);
        //transform({ x, y, zoom: flow.zoom || 0 });
      }
    };

    restoreFlow();
  }, [setElements]);


  useEffect(() => {
    console.log("ELEMENTS", elements)
  }, [elements]);

  const advanceEdgeClock = (index, timeRemaining) => {
    console.log("EDGECLOCK", timeRemaining, index)
      const els = elements
      console.log(els)
      var updateElement = els[Math.round(index, 0)]
      if(timeRemaining === 0) {
        updateElement.label = "Time: " + fmtMSS(els[index].data.time)
      } else {
        const nextTimeRemaining = timeRemaining - 1;
        updateElement.label = "Time: " + fmtMSS(nextTimeRemaining)
        updateElement.data.time = nextTimeRemaining
        setTimeout(() => {advanceEdgeClock(index, nextTimeRemaining)}, 1000)
      }
      setElements((elements) => {return els.concat({})})
  }

  const advanceEdgeClock2 = (index, timeRemaining) => {
    
    setTimeout(() => {
      const els = elements
      console.log("Time", els)
      if(timeRemaining === 0) {
        els[index].label = "Finish: " + fmtMSS(els[index].data.time)
        return
      }
      const nextTimeRemaining = timeRemaining - 1;
      els[index].label = "Finish: " + fmtMSS(nextTimeRemaining)
      setElements(els)
      setTimeout(advanceEdgeClock2(index, nextTimeRemaining))
    }, 1000)
  }

  const getElementFromGraphEvent = (event, elementList) => {
    const els = elementList.map(el => {return {...el, className: ""}})
    const index = els.findIndex(x => x.id === event.element.id)
    return els[index]
  }

  const onGraphEvent = (event) => {
      
    console.log("Graph_Event", event)

      if(event.type === "activeNode") {
        
        

        
        const els = elementsRef.current.map(el => {return {...el, className: ""}})
        const index = els.findIndex(x => x.id === event.element.id)
 
        els[index].className="activeNode"

        if(els[index].type === "input") {
          target = event.element.id
          return
        }
        
        if(els[index].type === "sceneNode") {
          console.log("LA", els[index])
          //props.glEventHub.emit(
          //  "transition-layout-pgm",
          //  cloneDeep(els[index].data.layout)
          //);
        }
        if(els[index].type === "buttonNode") {
          console.log("LA", els[index])
          //props.glEventHub.emit(
          //  "buttonNode",
          //  ["1","2","3","4"]
          //);
        }

        if(els[index].type === "loopNode") {
          graphPlayerRef.current.doLoop(target)
        }
        setElements((elements) => {return els})
        setElements((es) => es.concat({}));
      }

      if(event.type === "activeEdge") {
        const els = elementsRef.current.map(el => {return {...el, animated: false, labelBgStyle: {}, style: {}}})
        const index = els.findIndex(x => x.id === event.edge.id)
        if(index !== -1) {
          const el = els[index]
          if(el.data.mode === "time") {
            if(el.data.time > 0) {
              advanceEdgeClock(index, el.data.time)
            }
          }
          //if(el.data.mode === "content_finish") {
          //  advanceEdgeClock2(index, el.data.time)
          //}
          els[index].animated = true
          els[index].style={stroke: '#7CFC00', strokeWidth: "2"}
          els[index].labelBgStyle = { fill: '#7CFC00'}
        }
        setElements((elements) => {return els})
        setElements((es) => es.concat({}));
      }
  }

  const onStart = ()=> {
    console.log("Graph playback started")
    const graphPlayer = new GraphPlayback(elementsRef.current, onGraphEvent)
    graphPlayer.start()
    setGraphPlayer(graphPlayer)
  }

  const onNext = ()=> {
    graphPlayerRef.current.next()
  }

  const onStop = ()=> {
    const els = elementsRef.current.map(el => {return {...el, animated: false, labelBgStyle: {}, style: {}}})
    setElements((elements) => {return elements.map(el => {
        if(isEdge(el)) {
          return {...el, animated: false, labelBgStyle: {}, style: {}}
        }
        return {...el, className: ""}
    })})
    graphPlayerRef.current.stop()
  }

  const onDrop = (event) => {
    event.preventDefault();

    if (!reactFlowInstance) throw new Error("React flow not initialized")
        
    let nodeData = null
    try {
       nodeData = JSON.parse(event.dataTransfer.getData('application/reactflow'));
       console.log("ND", nodeData)
    }
    catch (e) {
     return
    }

    const id = getId()
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    var newNode = {
        id: id,
        position,
        type: nodeData.type,
        style: {width: "160px"}
    }

    switch (nodeData.type) {
        case "sceneNode":
          newNode.data = {...nodeData, onChange: () => {}, removeMe: () => {onElementsRemove([{id: id}])}, id: id }
          break;
        case "input":
          newNode.data = {label: getSequence(), removeMe: () => {onElementsRemove([{id: id}])}}
          break;
        case "output":
          newNode.data = {label: "End Sequence", removeMe: () => {onElementsRemove([{id: id}])}}
          break;
        case "content":
          newNode.data = {label: "Content", tags: nodeData.tags, removeMe: () => {onElementsRemove([{id: id}])}}
          break;
        case "default":
          throw new Error("Node type not supported yet")
          break;
    }

    setElements((es) => [...es, newNode]);
    
  };



  return (
   
    <div className="main-content">

    <div className="dndflow" style={{height: "100%", width: "100%"}}>
        
    <ReactFlowProvider>

        <div style={{position: "absolute", top: "6px", right: "5px", zIndex: "99"}}>
            <Button style={{display: "inline-block", marginRight: "5px"}} onClick={onSave}><FontAwesomeIcon icon={faSave}/></Button>
            <Button style={{display: "inline-block"}} onClick={onRestore}><FontAwesomeIcon icon={faUpload}/></Button>
        </div>

        <div className="dnd-list">
            <PanelHeader
              title={"Sequence Nodes"}
              infoLink={process.env.REACT_APP_FRESHDESK_SCENES}
            >
            </PanelHeader>

            <Sidebar 
              scenes={scenes}
            />
        </div>


        

      
        <div className="scene-preview" style={{height: "calc(100% - 45px)"}}>
            <PanelHeader
              title={"Sequence Graph"}
              infoLink={process.env.REACT_APP_FRESHDESK_SCENES}
            >
            </PanelHeader>
        <div className="reactflow-wrapper" ref={reactFlowWrapper}>
          <ReactFlow
              elements={elements}
              nodeTypes={nodeTypes}
              onConnect={onConnect}
              onElementsRemove={onElementsRemove}
              onLoad={onLoad}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onEdgeUpdate={(oldEdge, newConnection) => console.log("edge updated", oldEdge, newConnection)}
              connectionLineStyle={connectionLineStyle}
              snapToGrid={true}
              snapGrid={snapGrid}
              defaultZoom={1.5}
              onElementClick={true ? onElementClick : undefined}
              deleteKeyCode={'Delete'}
          >
                <Background
                  variant="dots"
                  gap={20}
                  size={0.5}
                />
            <Controls>
              <ControlButton onClick={onStart}>
                <FontAwesomeIcon icon={faPlay} color={"green"}/>
              </ControlButton>
              <ControlButton onClick={onStop}>
                <FontAwesomeIcon icon={faStop} color={"red"}/>
              </ControlButton>
              <ControlButton onClick={onNext}>
                <FontAwesomeIcon icon={faFastForward} color={"black"}/>
              </ControlButton>
            </Controls>
          </ReactFlow>
        </div>
        </div>

        <PropertyBar 
          selectedElement={selectedElement} 
          onChangeProperty={updateElement}
        />

  </ReactFlowProvider>

    </div>
    
    </div>
  );
};

export default DnDFlow;