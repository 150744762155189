import { forEach } from "lodash";

export function checkForStartNode(node) {
    if(node.data === undefined) return true;
    if(node.data.role === undefined) return true;
    if(node.data.role === "show_start") return false;
}

export function isEdge(element) {
    if(!element) return false;
    if(!element.source) return false;
    return true
}

export function isEdgeFromNode(node, element) {
    if (!isEdge(element)) return false;
    if (element.source === node.id) return true;
}

export function isEdgeToNode(node, element) {
    if (!isEdge(element)) return false;
    if (element.target === node.id) return true;
    return false
}

export function getOutgoingEdges(node, graph) {
    const edgesOut = graph.filter(element => isEdgeFromNode(node, element))
    return edgesOut 
}

export function getIncomingEdges(node, graph) {
    const edgesOut = graph.filter(element => isEdgeToNode(node, element))
    return edgesOut 
}


export default class GraphPlayback {

    constructor(graph, eventCallback) {
        this.graph = graph
        this.eventCallback = eventCallback
        this.activeNode = null;
        this.nextTarget = null;
        this.timeOuts = []
        this.playing = false;
        this.lastSequence = null
    }

    processEdges(edges, graph) {
        if(!edges) return;
        edges.forEach(edge => {
            if(edge.data === undefined) return;
            
            this.setNextTarget(edge.target)
            this.eventCallback({type: "activeEdge", edge: edge})
            switch(edge.data.mode) {
                case "auto":
                    this.next()
                    break;
                case "click":
                    break;
                case "time":
                    this.timeOuts.push(setTimeout(() => {this.next()}, edge.data.time*1000))
                    break;
                case "content_finish":
                    this.timeOuts.push(setTimeout(() => {this.next()}, edge.data.time*1000))
                    break;
                default:
                    throw new Error("Unknown transistion")
            }
        })
    }

    start = () => {
        this.playing = true
        const startElement = this.graph.find(x => !checkForStartNode(x))
        if(!startElement) throw new Error("Start node not found")
        console.log("activeNode", startElement)
        this.setActiveNode(startElement.id)
    }

    clearIncomingEdges = (nodeId) => {
        return
        const node = this.graph.find(x => x.id === nodeId)
        const inEdges = getIncomingEdges(this.activeNode, this.graph)
        console.log("incomingEdges", inEdges)
        inEdges.forEach(edge => {
            console.log("EDGECN", edge)
           edge.className="react-flow__edge react-flow__edge-default"
        })
    }

    setActiveNode(nodeId) {
        this.activeNode = this.graph.find(x => x.id === nodeId)
        this.eventCallback({type: 'activeNode', element: this.activeNode})
        const edges = getOutgoingEdges(this.activeNode, this.graph)
        console.log("outgoingEdges", edges)
        ///this.clearIncomingEdges(this.activeNode.id)
       
        if(this.activeNode.type !== "buttonNode") {
            this.processEdges(edges)
        }
    }

    setNextTarget = (nodeId) => {
        this.timeOuts.forEach(time => {
            clearTimeout(time)
        })
        this.nextTarget = nodeId
    }

    next = () => {
        this.setActiveNode(this.nextTarget)
    }

    jump = (target) => {
        console.log("jump", target)
        this.setActiveNode(target)
    }

    jumpViaHandle = (handle) => {
        console.log("Try jump via", handle)
        const edges = getOutgoingEdges(this.activeNode, this.graph)
        console.log("outgoingEdges", edges)
        const selectedEdge = edges.find(x => x.sourceHandle === "b"+handle)
        console.log("selectedEdge", selectedEdge)
        this.jump(selectedEdge.target)
    }

    stop = () => {
        this.playing = false
        this.activeNode = null;
        this.nextTarget = null;
        this.timeOuts.forEach(time => {
            clearTimeout(time)
        })
        this.timeOuts = [];
    }

    doLoop = (lastSequence) => {
        console.log("Loop", lastSequence)
        this.jump(lastSequence)
    }
    

}