
export default class AlignmentManager {
    
    constructor(options) {
        this.options = options
    }

    static GuideLineHorizontal = ({y, width, thickness, color}) => {
      return (
          <div 
          style={{
              position: "absolute", 
              zIndex: "10000", 
              display: y !== null ? "inline-block" : "none", 
              height: `${thickness}px`, 
              background: color, 
              top: y-thickness, 
              left: 0, 
              width: `${width}px`
          }}>
          </div>
      )
  }

  static GuideLineVertical = ({x, height, thickness, color}) => {
    return (
        <div 
            style={{
                position: "absolute", 
                zIndex: "10000", 
                display: x !== null ? "inline-block" : "none", 
                width: `${thickness}px`, 
                background: color, 
                top: "0", 
                left: x-thickness, 
                height: `${height}px`
            }}>
        </div>
    )
  }
  

    static snapToOtherSourcesOnDrag = (source, sources, dropPosition, canvasWidth, canvasHeight, overideSnapping) => {
        
        let currentClosestX = 10000
        let nearestSourceX = null
        let leftXmatch = false;
        let rightXmatch = false;
        let righToRightmatch = false;
        let rightToLeftmatch = false;

        let currentClosestY = 10000
        let nearestSourceY = null
        let topYmatch = false;
        let bottomYmatch = false;
        let bottomYtoBottomY = false;
        let bottomYtoTopY = false;

        
        sources.forEach((sourceN) => {
        if(sourceN.id === source.id) return; //don't compare with self

        let leftX = sourceN.position.x * canvasWidth
        let rightX = leftX + sourceN.position.width * canvasWidth

        let topY = sourceN.position.y * canvasHeight
        let bottomY = topY + sourceN.position.height * canvasHeight

        const myBottomY = dropPosition.y + source.position.height*canvasHeight
        const myRightX = dropPosition.x + source.position.width*canvasWidth

        const distanceLeftX = Math.abs(leftX - dropPosition.x)
        const distanceRightX = Math.abs(rightX - dropPosition.x)
        const distanceTopY = Math.abs(topY - dropPosition.y)
        const distanceBottomY = Math.abs(bottomY - dropPosition.y)

        const distanceBottomToBottom = Math.abs(myBottomY - bottomY)
        const distanceBottomToTop = Math.abs(myBottomY - topY)

        const distanceRightToRight = Math.abs(myRightX - rightX)
        const distanceRightToLeft = Math.abs(myRightX - leftX)

        if (distanceRightToLeft < currentClosestX) {
            currentClosestX = distanceRightToLeft
            nearestSourceX = sourceN
            leftXmatch = false
            rightXmatch = false
            righToRightmatch = false
            rightToLeftmatch = true
        }
        
        if (distanceRightToRight < currentClosestX) {
            currentClosestX = distanceRightToRight
            nearestSourceX = sourceN
            leftXmatch = false
            rightXmatch = false
            righToRightmatch = true
            rightToLeftmatch = false
        }

        if (distanceRightX < currentClosestX) {
            currentClosestX = distanceRightX
            nearestSourceX = sourceN
            leftXmatch = false
            rightXmatch = true
            righToRightmatch = false
            rightToLeftmatch = false
        }

        if (distanceLeftX <= currentClosestX) {
            currentClosestX = distanceLeftX
            nearestSourceX = sourceN
            leftXmatch = true
            rightXmatch = false
            righToRightmatch = false
            rightToLeftmatch = false
        }
        
        
        

        if (distanceBottomY < currentClosestY) {
            currentClosestY = distanceBottomY
            nearestSourceY = sourceN
            topYmatch = false
            bottomYmatch = true
            bottomYtoBottomY = false
            bottomYtoTopY = false
        }

        if (distanceTopY <= currentClosestY) {
            currentClosestY = distanceTopY
            nearestSourceY = sourceN
            topYmatch = true
            bottomYmatch = false
            bottomYtoBottomY = false
            bottomYtoTopY = false
        }

        if (distanceBottomToBottom < currentClosestY) {
            currentClosestY = distanceBottomToBottom
            nearestSourceY = sourceN
            topYmatch = false
            bottomYmatch = false
            bottomYtoBottomY = true
            bottomYtoTopY = false
        }

        if (distanceBottomToTop <= currentClosestY) {
            currentClosestY = distanceBottomToTop
            nearestSourceY = sourceN
            topYmatch = false
            bottomYmatch = false
            bottomYtoBottomY = false
            bottomYtoTopY = true
        }

        })

        let newX = dropPosition.x
        let newY = dropPosition.y
        let guideLineX = null
        let guideLineY = null


        if (currentClosestX < 5 && !overideSnapping) {
            if(leftXmatch) {
            newX=(nearestSourceX.position.x*canvasWidth)
            guideLineX = newX
            }

            if(rightXmatch) {
            newX=((nearestSourceX.position.x*canvasWidth) + (nearestSourceX.position.width*canvasWidth))
            guideLineX = newX
            }

            if(rightToLeftmatch) {
            newX=(nearestSourceX.position.x*canvasWidth) - source.position.width*canvasWidth
            guideLineX = nearestSourceX.position.x*canvasWidth
            }

            if(righToRightmatch) {
            newX=((nearestSourceX.position.x*canvasWidth) + (nearestSourceX.position.width*canvasWidth) - source.position.width*canvasWidth)
            guideLineX = nearestSourceX.position.x*canvasWidth + nearestSourceX.position.width*canvasWidth
            }
            
        } 

        if (currentClosestY < 5 && !overideSnapping) {
            if(topYmatch) {
            newY=(nearestSourceY.position.y*canvasHeight)
            guideLineY = newY
            }

            if(bottomYmatch) {
            newY=(nearestSourceY.position.y*canvasHeight)+(nearestSourceY.position.height*canvasHeight)
            guideLineY = newY
            }

            if(bottomYtoBottomY) {
            newY=(nearestSourceY.position.y*canvasHeight)+(nearestSourceY.position.height*canvasHeight) - source.position.height*canvasHeight
            guideLineY = (nearestSourceY.position.y*canvasHeight)+(nearestSourceY.position.height*canvasHeight)
            }

            if(bottomYtoTopY) {
            newY=(nearestSourceY.position.y*canvasHeight) - source.position.height*canvasHeight
            guideLineY = (nearestSourceY.position.y*canvasHeight) 
            }
        }
        
        let newPosition = {
            x: newX,
            y: newY,
            width: source.position.width*canvasWidth,
            height: source.position.height*canvasHeight,
        };

        return {position: newPosition, guideLineX: guideLineX, guideLineY: guideLineY}
    }

    static snapToOtherSourcesOnResize = (source, sources, ref, direction, position, canvasWidth, canvasHeight, ControlPressed, ShiftPressed) => {
        
        let resizeHandlePosition = {
            x: 0,
            y: 0,
          }
  
          if(direction === "bottomRight") {
             resizeHandlePosition = {
              x: position.x + ref.offsetWidth,
              y: position.y + ref.offsetHeight
            }
          }
  
          if(direction === "bottomLeft" || direction === "bottom") {
             resizeHandlePosition = {
              x: position.x,
              y: position.y + ref.offsetHeight
            }
          }
  
          if(direction === "topLeft" || direction === "left" || direction === "top") {
             resizeHandlePosition = {
              x: position.x,
              y: position.y
            }
          }
  
          if(direction === "topRight" || direction === "right") {
             resizeHandlePosition = {
              x: position.x + ref.offsetWidth,
              y: position.y
            }
          }
  
          let currentClosestX = 10000
          let nearestSourceX = null
          let leftXmatch = false;
          let rightXmatch = false;
          let righToRightmatch = false;
          let rightToLeftmatch = false;
  
          let currentClosestY = 10000
          let nearestSourceY = null
          let topYmatch = false;
          let bottomYmatch = false;
          let bottomYtoBottomY = false;
          let bottomYtoTopY = false;
  
          
          sources.forEach((sourceN) => {
            if(sourceN.id === source.id) return; //don't compare with self
  
            let leftX = sourceN.position.x * canvasWidth
            let rightX = leftX + sourceN.position.width * canvasWidth
  
            let topY = sourceN.position.y * canvasHeight
            let bottomY = topY + sourceN.position.height * canvasHeight
  
  
            const myBottomY = position.y + ref.offsetHeight
            const myRightX = position.x + ref.offsetWidth
  
            const distanceLeftX = Math.abs(leftX - resizeHandlePosition.x)
            const distanceRightX = Math.abs(rightX - resizeHandlePosition.x)
            const distanceTopY = Math.abs(topY - resizeHandlePosition.y)
            const distanceBottomY = Math.abs(bottomY - resizeHandlePosition.y)
  
            const distanceBottomToBottom = Math.abs(myBottomY - bottomY)
            const distanceBottomToTop = Math.abs(myBottomY - topY)
  
            const distanceRightToRight = Math.abs(myRightX - rightX)
            const distanceRightToLeft = Math.abs(myRightX - leftX)
  
            if(direction === "bottomRight" || direction === "topRight" || direction === "right") {
              //resizing from right so only compare right edges
              if (distanceRightToLeft < currentClosestX) {
                currentClosestX = distanceRightToLeft
                nearestSourceX = sourceN
                leftXmatch = false
                rightXmatch = false
                righToRightmatch = false
                rightToLeftmatch = true
              }
              
              if (distanceRightToRight < currentClosestX) {
                currentClosestX = distanceRightToRight
                nearestSourceX = sourceN
                leftXmatch = false
                rightXmatch = false
                righToRightmatch = true
                rightToLeftmatch = false
              }
            } else {
              if (distanceRightX < currentClosestX) {
                currentClosestX = distanceRightX
                nearestSourceX = sourceN
                leftXmatch = false
                rightXmatch = true
                righToRightmatch = false
                rightToLeftmatch = false
              }
    
              if (distanceLeftX <= currentClosestX) {
                currentClosestX = distanceLeftX
                nearestSourceX = sourceN
                leftXmatch = true
                rightXmatch = false
                righToRightmatch = false
                rightToLeftmatch = false
              }
            }
  
            if(direction === "topLeft" || direction === "topRight" || direction === "top") {
              if (distanceBottomY < currentClosestY) {
                currentClosestY = distanceBottomY
                nearestSourceY = sourceN
                topYmatch = false
                bottomYmatch = true
                bottomYtoBottomY = false
                bottomYtoTopY = false
              }
  
              if (distanceTopY <= currentClosestY) {
                currentClosestY = distanceTopY
                nearestSourceY = sourceN
                topYmatch = true
                bottomYmatch = false
                bottomYtoBottomY = false
                bottomYtoTopY = false
              }
            } else {
              if (distanceBottomToBottom < currentClosestY) {
                currentClosestY = distanceBottomToBottom
                nearestSourceY = sourceN
                topYmatch = false
                bottomYmatch = false
                bottomYtoBottomY = true
                bottomYtoTopY = false
              }
    
              if (distanceBottomToTop <= currentClosestY) {
                currentClosestY = distanceBottomToTop
                nearestSourceY = sourceN
                topYmatch = false
                bottomYmatch = false
                bottomYtoBottomY = false
                bottomYtoTopY = true
              }
            }
          })
  
          let newPosition = {
            x: position.x / canvasWidth,
            y: position.y / canvasHeight,
            width: ref.offsetWidth / canvasWidth,
            height: ref.offsetHeight / canvasHeight,
          };
  
          let aspectRatio = newPosition.width / newPosition.height;
  
  
          let guideLineX = null
          let guideLineY = null
  
          if(currentClosestX < 5 && !ControlPressed) {
            
            if(rightToLeftmatch) {
              newPosition.width = Math.abs((nearestSourceX.position.x)-(position.x/canvasWidth))
              guideLineX = (nearestSourceX.position.x*canvasWidth)
            }
  
            if(righToRightmatch) {
              newPosition.width = Math.abs((position.x/canvasWidth)-(nearestSourceX.position.x + nearestSourceX.position.width))
              guideLineX = (nearestSourceX.position.x*canvasWidth + nearestSourceX.position.width*canvasWidth)
            }
  
            if(leftXmatch) {
              newPosition.width = Math.abs((nearestSourceX.position.x)-(position.x/canvasWidth + ref.offsetWidth/canvasWidth))
              newPosition.x = nearestSourceX.position.x
              guideLineX = (nearestSourceX.position.x*canvasWidth)
            }
  
            if(rightXmatch) {
              newPosition.width = Math.abs((position.x/canvasWidth + ref.offsetWidth/canvasWidth)-(nearestSourceX.position.x + nearestSourceX.position.width))
              newPosition.x = nearestSourceX.position.x + nearestSourceX.position.width
              guideLineX = (nearestSourceX.position.x*canvasWidth + nearestSourceX.position.width*canvasWidth)
            }
  
            if(!ShiftPressed) {
              newPosition.height = newPosition.width / aspectRatio
            }
          }
  
          if(currentClosestY < 5 && !ControlPressed) {
            
            if(topYmatch) {
              newPosition.height = Math.abs((nearestSourceY.position.y)-(position.y/canvasHeight+ref.offsetHeight/canvasHeight))
              newPosition.y = nearestSourceY.position.y
              guideLineY = (nearestSourceY.position.y*canvasHeight)
            }
  
            if(bottomYmatch) {
              newPosition.height = Math.abs((position.y/canvasHeight+ref.offsetHeight/canvasHeight)-(nearestSourceY.position.y + nearestSourceY.position.height))
              newPosition.y = nearestSourceY.position.y + nearestSourceY.position.height
              guideLineY = (nearestSourceY.position.y*canvasHeight + nearestSourceY.position.height*canvasHeight)
            }
  
            if(bottomYtoBottomY) {
             newPosition.height = Math.abs((nearestSourceY.position.y+nearestSourceY.position.height)-(position.y/canvasHeight))
             guideLineY = (nearestSourceY.position.y*canvasHeight + nearestSourceY.position.height*canvasHeight)
            }
  
            if(bottomYtoTopY) {
              newPosition.height = Math.abs((position.y/canvasHeight)-(nearestSourceY.position.y))
              guideLineY = (nearestSourceY.position.y*canvasHeight)
            }
  
            if(!ShiftPressed) {
              newPosition.width = newPosition.height * aspectRatio
            }
          }

          return {position: newPosition, guideLineY: guideLineY, guideLineX: guideLineX}
    }
    
}

