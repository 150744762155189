import * as React from "react"

const Close = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="currentColor"
      viewBox="0 0 13 13"
      {...props}
    >
      <path
        fill="currentColor"
        d="M13 1.31L11.69 0 6.5 5.19 1.31 0 0 1.31 5.19 6.5 0 11.69 1.31 13 6.5 7.81 11.69 13 13 11.69 7.81 6.5 13 1.31z"
      ></path>
    </svg>
  )
}

export default Close
