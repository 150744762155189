

export const MatchMessageFields = [
    {
        name: "messageTitle",
        type: "text",
        value: "Enter Message Title",
    },
    {
        name: "message",
        type: "text",
        value: "Enter Message",
    }
]

export default function MatchMessage({primaryColor, messageTitle, message}) {
    return (
        `<svg viewBox="0 0 1222.77 120.58" width="1222.77px" height="120.58px" xmlns="http://www.w3.org/2000/svg">
        <g id="Layer_1-2" style="font-family: Yu Gothic UI;">
            <polygon class="cls-7" points="1178.695 115.9 49.227 115.9 4.882 42.49 1222.851 42.49 1178.695 115.9" style=""/>
            <polygon class="cls-5" points="1177.174 120.009 51.534 120.009 47.814 113.699 1181.694 113.699 1177.174 120.009" style="fill: ${primaryColor};"/>
            <polygon class="cls-5" points="851.824 53.899 378.234 53.899 355.844 0.429 873.674 0.429 851.824 53.899" style="fill: RGB(255, 255, 255);"/>
            <text style="fill: rgb(255, 255, 255); font-size: 22px; text-anchor: middle; white-space: pre;" x="612.267" y="88.404">${message}</text>
            <text class="cls-7" style="font-size: 32px; text-anchor: middle; white-space: pre;" x="614.759" y="39.319">${messageTitle}</text>
        </g>
        </svg>`
    )
}