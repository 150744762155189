export const TextFields = [
    {
        name: "text",
        type: "text",
        value: "Your Text Here",
    },
    {
        name: "textColor",
        type: "color",
        value: "rgba(255, 255, 255, 1)",
    },
    {
        name: "font",
        type: "text",
        value: "font-family: Arial; font-size: 64px; font-weight: 400",
    }
]

export default function Text({width="500", height="64", text="Your Text Here",textColor="#FFFFFFFF", backgroundColor="#00000000", font="font-family: Arial; font-size: 64px; font-weight: 400"}) {
    return (
        `<svg viewBox="0 0 ${width} ${height}" width="${width}px" height="${height}px" xmlns="http://www.w3.org/2000/svg">
            <text style="fill: ${textColor}; ${font};" x="0px" y="0px" dominant-baseline="hanging">${text}</text>
        </svg>`
    )
}

