
export default class FootballTimer {

    constructor(props) {
        this.props = props;
        this.startTime = null
        this.elapsedTime = 
        this.listeners = []
        this.updateInterval = null
    }

    start() {
        console.log('start')
        this.startTime = Date.now();
        this.updateInterval = setInterval(() => {this.tick()}, 500)
    }

    addTickListener(callback) {
        this.listeners.push(callback);
    }

    stop() {
        this.updateInterval.clear()
    }

    tick() {
        const now = Date.now();
        const elapsed = now - this.startTime;
        if(this.listeners) {
            this.listeners.forEach(listener => listener(elapsed));
        }
    }
    
}