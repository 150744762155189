import React from 'react'
import { Modal, Button, ListGroup, ListGroupItem, Tab, Tabs } from 'react-bootstrap'
import ScoreBoard, {ScoreBoardFields} from '../../template/assets/football-graphics/scoreboard'
import { useContext } from 'react'
import { TemplateContext } from '../../../templateContext'
import halfTime, { HalfTimeFields } from '../../template/assets/football-graphics/halfTimeScore'
import prematch from '../../template/assets/football-graphics/kickOff'
import halfTimeSponsor from '../../template/assets/football-graphics/halfTimeSponsor'
import halfTimeInterview, { HalfTimeInterviewFields } from '../../template/assets/football-graphics/halfTimeInterview'
import Clock from '../../template/assets/football-graphics/clock'
import TitleCard, {TitleCardFields} from '../../template/assets/football-graphics/titleCard'
import convertSVGtoIMG from '../../../util/SVGtoIMG'
import MatchMessage, { MatchMessageFields } from '../../template/assets/football-graphics/matchMessage'
import LowerThirdMessage, { LowerThirdMessageFields } from '../../template/assets/lower-thirds/lowerThird'
import Square, { SquareFields } from '../../template/assets/basic-shapes/square'
import Circle, { CircleFields } from '../../template/assets/basic-shapes/circle'
import Text, { TextFields } from '../../template/assets/basic-shapes/text'
import TitleCardClean from '../../template/assets/football-graphics/titleCard-clean'

const FootballGraphicsList = [
	{ name: 'Small Score', type: 'SVG', graphic: ScoreBoard, fields: ScoreBoardFields},
	{ name: 'Half Time', type: 'SVG', graphic: halfTime, fields: HalfTimeFields },
	{ name: 'Pre Match', type: 'SVG', graphic: prematch, fields: [] },
	{ name: 'Sponsor Presentation', type: 'SVG', graphic: halfTimeSponsor, fields: [] },
	{ name: 'Interview', type: 'SVG', graphic: halfTimeInterview, fields: HalfTimeInterviewFields },
	{ name: 'Clock', type: 'SVG', graphic: Clock, fields: [] },
	{ name: 'Title Card', type: 'SVG', graphic: TitleCard, fields: TitleCardFields },
	{ name: 'Match Message', type: 'SVG', graphic: MatchMessage, fields: MatchMessageFields },
	{ name: 'Title Card Clean', type: 'SVG', graphic: TitleCardClean, fields: [] },
]

const LowerThirdsList = [
	{ name: 'Basic Lower Third', type: 'SVG', graphic: LowerThirdMessage, fields: LowerThirdMessageFields },
]

const BasicShapesList = [
	{ name: 'Text', type: 'SVG', graphic: Text, fields: TextFields},
	{ name: 'Square', type: 'SVG', graphic: Square, fields: SquareFields},
	{ name: 'Circle', type: 'SVG', graphic: Circle, fields: CircleFields},
]

export default function CreateObjectModal({ show, onConfirm, onClose }) {

    const TemplateData = useContext(TemplateContext)
	return (
		<Modal
			show={show}
			varient='dark'
			className='custom-modal'
			onHide={onClose}
			centered
		>
			<Modal.Header>
				<Modal.Title>Create a scene object</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<Tabs
						defaultActiveKey="basic"
						id="uncontrolled-tab-example"
						className="mb-3"
					>
						<Tab eventKey="basic" title="Basic Shapes">
						<ListGroup variant='dark' style={{maxHeight: "50%", overflowY: "auto"}}>
						{BasicShapesList.map((item, index) => {
                            
                            let svg = item.graphic({...TemplateData.data})
                            let image64 = convertSVGtoIMG(svg);
          
                            return (
                            
							<ListGroupItem variant='dark' key={index} 
                            onClick={() => onConfirm(item)}
                            
                            >
								<div style={{ width: '50%', display: 'inline-block' }}>
									{item.name}
								</div>
								<img
									style={{
										display: 'inline-block',
										width: '40%',
										height: '100px',
										objectFit: 'contain',
									}}
									src={image64}
									alt={item.name}
								/>
							</ListGroupItem>
						)}
                        )}
					</ListGroup>
						</Tab>
						<Tab eventKey="lower" title="Lower Thirds">
						<ListGroup variant='dark' style={{maxHeight: "50%", overflowY: "auto"}}>
						{LowerThirdsList.map((item, index) => {
                            
                            let svg = item.graphic({...TemplateData.data})
                            let image64 = convertSVGtoIMG(svg);
          
                            return (
                            
							<ListGroupItem variant='dark' key={index} 
                            onClick={() => onConfirm(item)}
                            
                            >
								<div style={{ width: '50%', display: 'inline-block' }}>
									{item.name}
								</div>
								<img
									style={{
										display: 'inline-block',
										width: '40%',
										height: '100px',
										objectFit: 'contain',
									}}
									src={image64}
									alt={item.name}
								/>
							</ListGroupItem>
						)}
                        )}
					</ListGroup>
						</Tab>
					<Tab eventKey="football" title="Football Graphics">
					<ListGroup variant='dark' style={{maxHeight: "50%", overflowY: "auto"}}>
						{FootballGraphicsList.map((item, index) => {
                            
                            let svg = item.graphic({...TemplateData.data})
                            let image64 = convertSVGtoIMG(svg);
          
                            return (
                            
							<ListGroupItem variant='dark' key={index} 
                            onClick={() => onConfirm(item)}
                            
                            >
								<div style={{ width: '50%', display: 'inline-block' }}>
									{item.name}
								</div>
								<img
									style={{
										display: 'inline-block',
										width: '40%',
										height: '100px',
										objectFit: 'contain',
									}}
									src={image64}
									alt={item.name}
								/>
							</ListGroupItem>
						)}
                        )}
					</ListGroup>
					</Tab>
					</Tabs>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant='transparent'
					className='btn-light-outline'
					onClick={onClose}
				>
					Cancel
				</Button>
				<Button
					variant='primary'
					onClick={() => {onConfirm(value)}}
				>
					Save
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
