import React, { Component } from "react"
import { Form, Button, Modal, Col, Row } from "react-bootstrap"
import { Formik } from "formik"
import { get } from "lodash"
import * as Yup from "yup"

export default class AddOutputModal extends Component {
  constructor(props) {
    super(props)
    this.state ={
      isclosed : true
    }
  }

  render() {
    const outputSchema = Yup.object().shape({
      streamType: Yup.number().required("Type is required"),
      streamUrl: Yup.string().required("Url is required"),
      streamKey: Yup.string().required("Stream Key is required"),
    })
    return (
      <Modal
        variant="dark"
        className="custom-modal"
        size="md"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        show={this.props.show}
        onHide={this.props.onClose}
      >
        <Formik
          enableReinitialize={true}
          initialValues={this.props.output}
          validationSchema={outputSchema}
          onSubmit={(values) => {
            console.log(values)
            this.props.onSave(values)
           
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header>
                <Modal.Title>
                  {get(values, "_id", null)
                    ? "Edit Output"
                    : "Add an Output Stream"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Form.Group as={Col} controlId="validationFormikStreamType">
                    <Form.Label className="eventText">
                      Choose your output platform
                    </Form.Label>

                    <Form.Control
                      as="select"
                      name="streamType"
                      value={get(values, "streamType", "1")}
                      onChange={(e) => {
                        this.props.handleTypeChange(e)
                      }}
                      onBlur={handleChange}
                      isInvalid={!!errors.streamType}
                    >
                      <option value="1">Facebook</option>
                      <option value="2">YouTube</option>
                      <option value="3">Custom</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.streamType}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId="validationFormikEndpoint">
                    <Form.Label className="eventText">Enter URL</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter URL"
                      name="streamUrl"
                      disabled={
                        ["1", "2", 1, 2].indexOf(
                          get(values, "streamType", "1")
                        ) !== -1
                          ? true
                          : false
                      }
                      value={get(values, "streamUrl", "")}
                      isInvalid={!!errors.streamUrl}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.streamUrl}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId="validationFormikEndpoint">
                    <Form.Label className="form-label">
                      Enter Streamkey
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Stream Key"
                      name="streamKey"
                      value={get(values, "streamKey", "")}
                      isInvalid={!!errors.streamKey}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.streamKey}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                {get(values, "_id", null) && (
                  <Button
                    variant="danger"
                    onClick={() => this.props.onDelete(values._id)}
                    disabled={this.props.isdeleted}
                  >
                    Delete
                  </Button>
                )}
                
                
                
                <Button
                  variant="transparent"
                  className="btn-light-outline"
                  onClick={() => this.props.onClose()}
                  disabled={this.props.issaved}
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit" 
                  disabled={this.props.issaved}
                 >
                  Save 
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    )
  }
}
