import * as React from "react"
import { get } from 'lodash'

const PositionBottomRight = ({isMatched}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
    >
      <g data-name="Group 1655" transform="translate(-631 -661)">
        <g
          fill="none"
          stroke="#858585"
          strokeWidth="0.5"
          data-name="Rectangle 3399"
          transform="translate(631 661)"
        >
          <rect width="27" height="27" stroke="none" rx="6"></rect>
          <rect width="26.5" height="26.5" x="0.25" y="0.25" rx="5.75"></rect>
        </g>
        <rect
          width="10"
          height="10"
          fill={isMatched ? '#01d1fa' : "#858585"}
          data-name="Rectangle 3406"
          rx="3"
          transform="translate(645 675)"
        ></rect>
      </g>
    </svg>
  );
};



export default PositionBottomRight
