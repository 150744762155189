import {
	faBroadcastTower,
	faCog,
	faPlayCircle,
} from '@fortawesome/free-solid-svg-icons'
import { faCircleR } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cloneDeep, get, includes, map, set } from 'lodash'
import 'rc-slider/assets/index.css'
import React from 'react'
import { Button, Dropdown, ToggleButton } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { FacebookIcon, InfoIcon, YoutubeIcon } from '../../components/svgIcons/index.js'
import WebRTCPublisher from '../../components/webRTCplayer/WebRTCPublisher.js'
import sourceTypes from '../../constants/sourceTypes.js'
import * as API from '../../services/api.js'
import AspectRatio from '../../util/AspectRatio.jsx'
import canvasRecord from '../../util/canvasRecord.js'
import GetEventFromURL from '../../util/getEventFromURL.js'
import GetToken from '../../util/getToken.js'
import AddEditOutputModal from './add-edit-output-modal.js'
import UseEventCallback from '../preview/useEventCallback.js'
import ThreeRender from './three-render'
import { TemplateContext } from '../../templateContext.js'
import PanelHeader from '../components/PanelHeader.js'
import TemplateControls from './programControl'
import AudioMixer from '../audio-mixer/AudioMixSubPanel.js'
import AudioEngine from '../audio-mixer/AudioEngine.js'
import OutputSettingsModal from './OutputSettingsModal.js'
import * as Hub from '../../services/api.js'
import "/node_modules/flag-icons/css/flag-icons.min.css";

const BEARER_TOKEN = GetToken()

const StreamingServiceURLs = {
	1: 'rtmp://live-api-s.facebook.com:443/rtmp/',
	2: 'rtmp://a.rtmp.youtube.com/live2/',
}

//parameters for stream quality
const DefaultResolution = { w: 1920, h: 1080 }
const StreamingFramerate = 30
const RecordingFramerate = 30
const DefaultMasterGain = 1

export default class PGMPanel extends React.Component {
	static contextType = TemplateContext

	constructor(props) {
		super(props)
		this.state = {
			sources: [],
			isStreaming: false,
			outputStreams: [],
			eventId: GetEventFromURL(),
			events: undefined,
			createStreamModalVisible: false,
			removeStreamDialog: false,
			removeStreamId: undefined,
			streamForEdit: undefined,
			initialOutput: {
				_id: undefined,
				streamType: '1',
				streamUrl: StreamingServiceURLs['1'],
				streamKey: '',
			},
			transition: {mode: 'cut', duration: 0},
			issaved: false,
			isdeleted: false,
			isRecording: false,
			buttons: [],
			resolution: DefaultResolution
		}
		this.canvasRef = React.createRef()
		this.canvasRecorder = null
		this.AudioEngine = new AudioEngine(DefaultMasterGain, true)
		this.mixedAudioNode = null
		this.currentLayoutData = null
	}

	componentDidMount() {
		this.getStreamRecords(this.state.eventId)
		Hub.getEventOutputSettings(this.state.eventId).then(
			(result) => {
				console.log('res', result)
				if(!result) return
				if(!result.hasOwnProperty('resolution')) return
				console.log('data', result)
				this.setState({resolution: result.resolution.value})
			}
		)
	}

	componentWillUnmount() {
		//clean up audio context
		if (this.AudioContext && this.AudioContext.state !== 'closed') {
			console.log('AudioContext closed')
			//this.AudioContext.close()
		}
	}

	addButtons = (buttons) => {
		this.setState({ buttons: buttons })
	}

	/**
	 * Updates the current active scene for program output
	 * @param {*} data the data for the next program out state
	 */
	updateScene = (data) => {
		this.currentLayoutData = cloneDeep(data)
		
		//use this user triggered event to make sure the audio context is running
		this.AudioEngine.forceStart()

		let refrencedSources = data.layout
			.filter(source => source.hidden !== true)		//remove hidden sources
			.sort((a, b) => a.position.z - b.position.z) 	//sort in Z order for rendering layers
			.map((source) => {								//add reference for video dom reference		
				return {
					...source,
					ref: React.createRef(),
				}
			})

		this.setState({ 
			sources: refrencedSources, 
			transition: data.transition,
			reset: false
		})
	}

	/**
	 * Start a local recording session
	 */
	startRecording = async () => {
		var RecordingStream = this.canvasRef.captureStream(RecordingFramerate)
		var AudioOutputStream = this.AudioEngine.getMixedAudioStream()

		var AudioRecordingTracks = AudioOutputStream.stream.getTracks()

		AudioRecordingTracks.forEach((track) => {
			RecordingStream.addTrack(track)
		})

		this.canvasRecorder = canvasRecord(RecordingStream)
		this.canvasRecorder.start()
		this.setState({ isRecording: true })
	}

	/**
	 * Stop the local recording session
	 */
	stopRecording = async () => {
		// Stop and dispose
		this.canvasRecorder.stop()
		this.canvasRecorder.dispose()
		this.setState({ isRecording: false })
	}

	getStreamRecords = async (eventId) => {
		API.getEventOutputStreams(BEARER_TOKEN, eventId)
			.then((result) => {
				this.setState({ outputStreams: result })
			})
			.catch((err) => {
				console.log('getEventOutputStreams', err)
			})
	}

	getSourceElementsForDOM = () => {
		const sources = this.state.sources
		const output = []
		sources.forEach((source, index) => {
			if (source.type === sourceTypes.video) {
				output.push(
					<video
						key={'output' + source.id}
						style={{
							position: 'absolute',
							top: '0',
							left: '0',
							zIndex: '-1',
							visibility: '',
							width: '100%',
							maxHeight: '100%',
							maxWidth: '100%',
							height: '100%',
						}}
						id={source.id}
						crossOrigin='anonymous'
						autoPlay={true}
						loop={true}
						muted={true}
						volume={0}
						src={source.sourceURL}
					></video>
				)
			}
		})
		return output
	}

	copyLinkToClipboard(link) {
		navigator.clipboard.writeText(link)
		toast.info('📋 Copied streaming link to clipboard', {
			position: 'bottom-right',
			autoClose: 2500,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		})
	}

	toggleLive = async () => {
		
		console.log('Go Live')
		const { eventId } = this.state
		if (this.state.isStreaming === true) {
			window.stopPublishing()
			this.setState({ isStreaming: false })
			return
		}

		if (this.state.isStreaming === false) {
			const newBroadcastData = await API.createBroadcast(eventId)
			this.sendCanvasToWebRTC(this.canvasRef, newBroadcastData.id)
			toast.success('Started Streaming')
			this.setState({ isStreaming: true })
			return
		}
	}

	sendCanvasToWebRTC = (canvas, streamId) => {
		//capture video from the canvas
		var outputStream = canvas.captureStream(StreamingFramerate)

		//create an audio source for the output stream
		var outputAudioStream = this.AudioEngine.getMixedAudioStream()
		var outputAudioTracks = outputAudioStream.stream.getTracks()

		//get all the tracks from the output audio stream and add them to the output stream.
		outputAudioTracks.forEach((track) => {
			outputStream.addTrack(track)
		})

		//interface with webRTC adaptor specified in index.html script (to be fixed later)
		window.localStream = outputStream
		window.streamId = streamId
		window.initWebRTC()
	}

	deleteOutput = (id) => {
		try {
			this.setState({ isdeleted: true })
			const { eventId } = this.state
			API.deleteEventOutputStream(eventId, id).then(() => {
				this.getStreamRecords(eventId)
				this.setState({
					removeStreamDialog: !this.state.removeStreamDialog,
					removeStreamId: undefined,
				})
				this.toggleAddOutputModal()
				toast.success('Stream deleted.', {
					position: 'bottom-right',
					autoClose: 5000,
					hideProgressBar: true,
				})
				this.setState({ isdeleted: false })
			})
		} catch (err) {
			console.log(err)
			this.setState({ isdeleted: false })
		}
	}

	toggleRemoveStreamDialog = (streamId) => {
		this.setState({
			removeStreamDialog: !this.state.removeStreamDialog,
			removeStreamId: streamId,
		})
	}

	toggleAddOutputModal = (stream) => {
		this.setState({
			addOutpurModalVisible: !this.state.addOutpurModalVisible,
			initialOutput: stream
				? {
						_id: stream._id,
						streamType: get(stream, 'streamType', '1'),
						streamUrl: get(stream, 'streamUrl', StreamingServiceURLs['1']),
						streamKey: get(stream, 'streamKey', ''),
				  }
				: {
						_id: undefined,
						streamType: '1',
						streamUrl: StreamingServiceURLs['1'],
						streamKey: '',
				  },
		})
	}

	handleTypeChange = (e) => {
		const { initialOutput } = this.state
		set(initialOutput, 'streamType', `${e.target.value}`)
		if (!includes(['3', 3], e.target.value)) {
			set(initialOutput, 'streamUrl', StreamingServiceURLs[`${e.target.value}`])
		} else {
			set(initialOutput, 'streamUrl', '')
		}
		this.setState({
			initialOutput,
		})
	}

	saveAddOutput = (stream) => {
		try {
			this.setState({ issaved: true })
			const { eventId } = this.state
			const input = {
				streamLink: `${stream.streamUrl}${stream.streamKey}`,
				streamType: parseInt(stream.streamType),
				streamUrl: `${stream.streamUrl}`,
				streamKey: `${stream.streamKey}`,
			}
			if (get(stream, '_id', null)) {
				API.updateEventOutputStream(eventId, stream._id, input).then(() => {
					this.toggleAddOutputModal()
					this.getStreamRecords(eventId)
				})
			} else {
				API.addEventOutputStream(eventId, input).then(() => {
					this.toggleAddOutputModal()
					this.getStreamRecords(eventId)
					toast.success('Stream created.', {
						position: 'bottom-right',
						autoClose: 5000,
						hideProgressBar: true,
					})
					this.setState({ issaved: false })
				})
			}
		} catch (err) {
			console.log(err)
			this.toggleAddOutputModal()
			this.setState({ issaved: false })
		}
	}

	updateTemplateData = () => {
		const TemplateData = this.context	
		this.setState({templateData: TemplateData})
	  }


	updateOutputSettings = (settings) => {
		this.setState({outputSettingsModalVisible: false, resolution: settings.resolution}, () => {
			if(!this.currentLayoutData) return
			setTimeout(()=>this.updateScene(this.currentLayoutData), 10)
		})
		Hub.setEventOutputSettings(this.state.eventId, {resolution: {value: settings.resolution}}).then(() => {})
	}

	render() {
		const streams = this.state.outputStreams

		return (
			<div className='main-content'>
				<UseEventCallback
					event='transition-layout-pgm'
					callback={this.updateScene}
				/>
				<UseEventCallback
					event='template-data-update'
					callback={this.updateTemplateData}
				/>
				<div className='output-panel-subpanel'>
					<div className='io-subpanel-left'>
						<PanelHeader
							title={'Preview'}
							infoLink={process.env.REACT_APP_FRESHDESK_PREVIEW}
						>
							<div className='io-subpanel-right'>
								<div className='io-subpanel-actions'>
									{map(streams, (stream, ind) => {
										let icon = faPlayCircle
										let color = 'white'
										let name = 'outputFilter'
										if (stream.streamType === 1) {
											icon = <FacebookIcon />
											color = 'blue'
											name = 'Facebook stream'
										}
										if (stream.streamType === 2) {
											icon = <YoutubeIcon />
											color = 'red'
											name = 'Youtube stream'
										}
										return (
											<Button
												variant='transparent'
												className='btn-social-share'
												key={stream._id}
												onClick={() => {
													this.toggleAddOutputModal(stream)
												}}
												disabled={this.state.isStreaming}
											>
												{icon}
											</Button>
										)
									})}
								</div>
								<div className='io-subpanel-actions'>
									<Dropdown className="d-inline-block" variant="dark">
										<Dropdown.Toggle variant='transparent'>
											<span class="fi fi-us"></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="w-100" variant='dark'>
                                            <Dropdown.Item>
												<span class="fi fi-us"></span> &nbsp; US
											</Dropdown.Item>
                                            <Dropdown.Item>
												<span class="fi fi-gb"></span> &nbsp; UK
                                            </Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
									<Button className='io-btn-light btn btn-transparent settings-btn clearBtn' onClick={() => {this.setState({outputSettingsModalVisible: true})}}>
										<FontAwesomeIcon icon={faCog}></FontAwesomeIcon>
									</Button>
									<Button
										disabled={this.state.isStreaming || !(streams.length < 2)}
										variant='primary'
										className='io-btn-light btn btn-transparent add-output-btn'
										onClick={this.toggleAddOutputModal}
									>
										Add output
									</Button>
									<Button
										disabled={this.state.isStreaming || !(streams.length < 2)}
										variant='primary'
										className='io-btn-light btn btn-transparent clearBtn'
										onClick={
											this.state.isRecording
												? this.stopRecording
												: this.startRecording
										}
									>
										{this.state.isRecording ? (
											<div>
												Stop recording &nbsp;
												<FontAwesomeIcon
													className='pulse'
													icon={faCircleR}
													color={'red'}
												></FontAwesomeIcon>
											</div>
										) : (
											<div>
												Start recording &nbsp;
												<FontAwesomeIcon
													icon={faCircleR}
													color={'red'}
												></FontAwesomeIcon>
											</div>
										)}
									</Button>
									<Button
										variant='transparent'
										className='io-btn-light btn btn-transparent'
										style={{color: "white", background: "RGBA(0,0,0,0.5)", outline: "1px solid white"}}
										onClick={this.toggleLive}
									>
										{this.state.isStreaming ? (
											<div>
												End live &nbsp;
												<FontAwesomeIcon
													className='pulse'
													icon={faBroadcastTower}
													color={'limegreen'}
												></FontAwesomeIcon>
											</div>
										) : (
											<div>
												Go live &nbsp;
												<FontAwesomeIcon
													icon={faBroadcastTower}
													color={'limegreen'}
												></FontAwesomeIcon>
											</div>
										)}
									</Button>
								</div>
							</div>
						</PanelHeader>
					</div>

					<div style={{ visibility: 'hidden' }}>
						{this.getSourceElementsForDOM()}
					</div>
					<div className='output-preview-container'>
						<AspectRatio
							ratio={'16:9'}
							offset={0}
						>
							<div className='aspect-ratio-render'>
								<ThreeRender
									internalCanvasRefCallback={(ref) => (this.canvasRef = ref)}
									sources={this.state.sources}
									transition={this.state.transition}
									resolution={this.state.resolution}
									reset={this.state.reset}
								/>
							</div>
						</AspectRatio>

						<div style={{ position: 'absolute', bottom: '10px', left: '10px' }}>
							{this.state.buttons.map((btn) => {
								return (
									<Button
										onClick={() => {
											this.setState({ buttons: [] })
											console.log('EMIT PRESS')
											//this.props.glEventHub.emit("ButtonPress", btn)
										}}
									>
										Select {btn}
									</Button>
								)
							})}
						</div>
					</div>
				</div>

				<div style={{ width: '30%' }}>
					<AudioMixer
						audioEngine={this.AudioEngine}
						sceneSources={this.state.sources}
					/>

					{
						<>
							<PanelHeader title='Sequence Controls'></PanelHeader>
							<div>
								<TemplateControls></TemplateControls>
							</div>
						</>
					}
				</div>

				<AddEditOutputModal
					show={this.state.addOutpurModalVisible}
					onSave={this.saveAddOutput}
					issaved={this.state.issaved}
					isdeleted={this.state.isdeleted}
					onClose={this.toggleAddOutputModal}
					handleTypeChange={this.handleTypeChange}
					onDelete={this.deleteOutput}
					output={this.state.initialOutput}
				/>

				<OutputSettingsModal
					show = {this.state.outputSettingsModalVisible}
					onClose={() => this.setState({outputSettingsModalVisible: false})}
					currentResolution={this.state.resolution}
					onSave={this.updateOutputSettings}
				/>
			</div>
		)
	}
}
