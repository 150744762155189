import { toLower } from "lodash";

export function isTransparent(colorString) {
    // Remove leading and trailing spaces
    colorString = colorString.trim();

    // Check if the color string starts with 'rgba' and its alpha channel is 0
    if (colorString.startsWith('rgba')) {
        const components = colorString.slice(5, -1).split(',');
        const alpha = parseFloat(components[3].trim());
        return alpha === 0;
    }
    
    // Check if the color string starts with 'transparent' or other common transparent named colors
    const lowerCaseColor = colorString.toLowerCase();
    const transparentNamedColors = ['transparent', 'clear', 'none'];
    if (transparentNamedColors.includes(lowerCaseColor)) {
        return true;
    }

    // Check if the color string starts with '#' (hex color)
    if (colorString.startsWith('#')) {
        // Extract the alpha component if it's specified in the hex value
        const alphaHex = colorString.slice(7);
        if (alphaHex) {
            const alphaDecimal = parseInt(alphaHex, 16);
            return alphaDecimal === 0;
        }
        return false; // Hex color without alpha is not transparent
    }

    // Check if the color string starts with 'hsla' and its alpha channel is 0
    if (colorString.startsWith('hsla')) {
        const components = colorString.slice(5, -1).split(',');
        const alpha = parseFloat(components[3].trim());
        return alpha === 0;
    }

    return false; // Not a transparent color
}

export function getAlpha(colorString) {
     // Remove leading and trailing spaces
     colorString = colorString.trim();
     colorString = colorString.toLowerCase()

     // Check if the color string starts with 'rgba' and its alpha channel is 0
     if (colorString.startsWith('rgba')) {
         const components = colorString.slice(5, -1).split(',');
         const alpha = parseFloat(components[3].trim());
         return alpha
     }
     
     // Check if the color string starts with 'transparent' or other common transparent named colors
     const lowerCaseColor = colorString.toLowerCase();
     const transparentNamedColors = ['transparent', 'clear', 'none'];
     if (transparentNamedColors.includes(lowerCaseColor)) {
         return 0;
     }
 
     // Check if the color string starts with '#' (hex color)
     if (colorString.startsWith('#')) {
         // Extract the alpha component if it's specified in the hex value
         const alphaHex = colorString.slice(7);
         if (alphaHex) {
             const alphaDecimal = parseInt(alphaHex, 16);
             return alphaDecimal/255   
         }
         return 1
     }
 
     // Check if the color string starts with 'hsla' and its alpha channel is 0
     if (colorString.startsWith('hsla')) {
         const components = colorString.slice(5, -1).split(',');
         const alpha = parseFloat(components[3].trim());
         return alpha
     }
}