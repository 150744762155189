import * as React from "react"

const FacebookIcon = ({ ...props }) => {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17 9A8 8 0 1 1 .999 9 8 8 0 0 1 17 9Z" stroke="#fff" />
      <path
        d="M11.07 7.43H9.66v-.925a.376.376 0 0 1 .392-.428h.995V4.548l-1.37-.005a1.735 1.735 0 0 0-1.869 1.87V7.43h-.88v1.574h.88v4.453h1.85V9.003h1.25l.161-1.574h.001Z"
        fill="#fff"
      />
    </svg>
  )
}
export default FacebookIcon

