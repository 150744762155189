import * as React from "react"

const Headphone = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17.082}
      height={15.891}
      {...props}
    >
      <path
        data-name="Path 118"
        d="M15.21 9.065h-.251V6.478a6.4 6.4 0 1 0-12.8 0v2.587H1.91a1.9 1.9 0 0 0-1.909 1.889v3.035a1.911 1.911 0 0 0 1.909 1.9h2.048a.472.472 0 0 0 .478-.459V9.513a.465.465 0 0 0-.478-.447h-.871V6.478a5.476 5.476 0 1 1 10.951 0v2.587h-.871a.465.465 0 0 0-.478.447v5.919a.474.474 0 0 0 .478.459h2.043a1.879 1.879 0 0 0 1.87-1.9v-3.035a1.871 1.871 0 0 0-1.87-1.889Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default Headphone

