import React from "react"
import { Button, Modal } from "react-bootstrap"

export function ConfirmationDialog({
  show,
  onClose,
  onDelete,
  header,
  content,
}) {
  return (
    <>
      <Modal
        className="custom-modal"
        variant="dark"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-text">{content}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="transparent"
            className="btn-light-outline"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={onDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
