import React from 'react'
import AspectRatio from '../../util/AspectRatio'
import { Image as Image2, Button, FormControl, FormLabel, Form } from 'react-bootstrap'
import SizedCanvas from './components/SizedCanvas'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faBackwardStep,
	faCaretLeft,
	faCaretRight,
	faForwardStep
} from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery'
import { cloneDeep, debounce} from 'lodash'
import CUID from 'cuid'
import { ConfirmationDialog } from '../../components/modals/ConfirmationDialog'
import 'bootstrap-slider/dist/css/bootstrap-slider.css'
import * as PreviewRenderer from '../../util/canvasHelpers'
import * as Hub from '../../services/api'
import GetEventFromURL from '../../util/getEventFromURL.js'
import GetToken from '../../util/getToken.js'
import sourceTypes from '../../constants/sourceTypes'
import LayoutLimits from '../../constants/layout_limits'
import { toast } from 'react-toastify'
import { throttle } from 'lodash'
import LayoutPreviewList from './components/ScenePreviewList'
import UseEventCallback from './useEventCallback'
import RenderTarget from './components/RenderTarget'
import RenderTargetPropertiesSubpanel from './components/RenderTargetPropertiesSubpanel'
import RenderTargetListSubpanel from './components/RenderTargetListSubpanel'
import LiveSourcePreview from './components/LiveSourcePreview'
import MediaQuerySourcePreview from './components/MediaQuerySourcePreview'
import PanelHeader from '../components/PanelHeader'
import { dispatchEvent } from 'simple-react-event-hub'
import FontAwesomeButton from '../../util/FontAwesomeButton'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { TemplateContext } from '../../templateContext'
import convertSVGtoIMG from '../../util/SVGtoIMG.js'

const UUID = require("uuid").v4

const LayerPreviewResolution = { w: 192, h: 108 } //image size for layout thumbnails
const LayoutResolution = { w: 1920, h: 1080 } 
const token = GetToken()

export default class PreviewPanel extends React.Component {

	static contextType = TemplateContext

	constructor(props) {
		super(props)
		this.state = {
			aspectRatio: { w: 16, h: 9 },
			selectedSO: { url: '', time: 0 },
			sources: [],
			layoutList: [],
			canvasWidth: 1,
			canvasHeight: 1,
			swapTarget: null,
			monitorAudio: false,
			activeScene: 0,
			removeLayoutDialog: false,
			removeLayoutIndex: undefined,
			selectedSourceIndex: null,
			transitionTime: 500,
			activeLayoutId: null,
			currentLiveScene: null,
			autoUpdatePGM: false,
		}
		this.canvasRef = React.createRef()
		this.renderRef = React.createRef()

		//debounced functions, throttled and called on trailing edge only
		this.saveLayoutsToStoreThrottled = debounce(this.saveLayoutsToStore, 1000)
		this.saveExistingLayoutThrottled = debounce(this.saveExistingLayout, 100)
		this.updateCanvas = this.updateCanvasRaw
		this.widthChangeKeyDown = false
	}

	//change of selected scene handler functions

	jumpToLayout = (index) => {
		
		//check for bad index's
		if (index === null) return
		if (index > this.state.layoutList.length - 1) return

		//load the correct layout
		const layout = this.state.layoutList[index].layout
		this.loadLayout(layout)
		this.setState({ activeScene: index}, () => {
			this.updateSceneListScrollPosition()
		})
	}

	handleNext = () => {
		var seqNo = (this.state.activeScene + 1) % this.state.layoutList.length //wrap around to first scene from end
		this.jumpToLayout(seqNo)
	}

	handleLast = () => {
		var seqNo = this.state.activeScene - 1
		if (seqNo < 0) return
		this.jumpToLayout(seqNo)
	}

	handleGoFirst = () => {
		this.jumpToLayout(0)
	}

	handleGoLast = () => {
		var seqNo = this.state.layoutList.length - 1
		this.jumpToLayout(seqNo)
	}

	updateSceneListScrollPosition = () => {
		const { activeScene } = this.state

		if (activeScene < 0) return

		//update scroll to correct position
		var offsets = parseInt($('.scenediv0').height() + 15) * parseInt(activeScene - 1)
		$('.dnd-list').find('ul').animate({ scrollTop: offsets })
	}

	componentDidMount() {
		this.initalizePreviewCanvas()
		this.loadScenesFromHub(GetEventFromURL(), token)
		document.addEventListener('keydown', this.handleKeyDown)
		document.addEventListener('keyup', this.handleKeyUp)
	}

	
	handleKeyDown = (e) => {
		try {
			if (this.state.selectedSourceIndex !== null) {
				let sourceIndex = this.state.selectedSourceIndex
				let sources = this.state.sources

				let position = sources[sourceIndex].position

				let fullPosition = {
					x: position.x * LayoutResolution.w,
					y: position.y * LayoutResolution.h,
					width: position.width * LayoutResolution.w,
					height: position.height * LayoutResolution.h,
				}

				if (e.key === 'up' || e.key === 'ArrowUp') {
					fullPosition.y = fullPosition.y - 1
					const newPosition = this.clampToBounds(fullPosition, {
						width: LayoutResolution.w,
						height: LayoutResolution.h,
					})
					sources[sourceIndex].position.y = newPosition.y / LayoutResolution.h
					this.setState({ sources: sources }, this.saveExistingLayoutThrottled)
				}

				if (e.key === 'down' || e.key === 'ArrowDown') {
					fullPosition.y = fullPosition.y + 1
					const newPosition = this.clampToBounds(fullPosition, {
						width: LayoutResolution.w,
						height: LayoutResolution.h,
					})
					sources[sourceIndex].position.y = newPosition.y / LayoutResolution.h
					this.setState({ sources: sources }, this.saveExistingLayoutThrottled)
				}

				if(e.key === "Escape") {
					this.setState({selectedSourceIndex: null})
				}

				if (e.key === 'left' || e.key === 'ArrowLeft') {
					if (this.widthChangeKeyDown) {
						fullPosition.width = fullPosition.width - 1
					} else {
						fullPosition.x = fullPosition.x - 1
					}
					const newPosition = this.clampToBounds(fullPosition, {
						width: LayoutResolution.w,
						height: LayoutResolution.h,
					})
					sources[sourceIndex].position.x = newPosition.x / LayoutResolution.w
					sources[sourceIndex].position.width = newPosition.width / LayoutResolution.w
					this.setState({ sources: sources }, this.saveExistingLayoutThrottled)
				}

				if (e.key === 'right' || e.key === 'ArrowRight') {
					if (this.widthChangeKeyDown) {
						fullPosition.width = fullPosition.width + 1
					} else {
						fullPosition.x = fullPosition.x + 1
					}
					const newPosition = this.clampToBounds(fullPosition, {
						width: LayoutResolution.w,
						height: LayoutResolution.h,
					})
					sources[sourceIndex].position.x = newPosition.x / LayoutResolution.w
					sources[sourceIndex].position.width = newPosition.width / LayoutResolution.w
					this.setState({ sources: sources }, this.saveExistingLayoutThrottled)
				}
			}
		} catch (err) {
			console.log(err)
		}
	}

	pgmAboutToEnd = (timeLeft) => {
		if (this.state.activeSequenceNumber === 0 || !this.state.playMode) return
		const next =
			(this.state.activeSequenceNumber + 1) % this.state.layoutList.length
		this.setState({ aboutToEnd: true, timeLeft: timeLeft })
	}

	sceneEnd = () => {
		if (!this.state.playMode) return
		this.onTransition()
	}

	initalizePreviewCanvas = () => {
		
		const canvas = this.canvasRef.current

		if(!canvas) return

		const canvasContext = canvas.getContext('2d')
		PreviewRenderer.Init(canvasContext, LayerPreviewResolution)

	}

	loadScenesFromHub = (event, token) => {
		Hub.getEventScenes(token, event)
			.then((result) => {
				this.setState({ layoutList: result })
				if (result.length !== 0) {
					this.loadLayout(result[0].layout)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	canAddAVideo = (sources) => {
		const numberOfVideos = sources.reduce((accumulator, currentValue) => {
			if (currentValue.type === sourceTypes.video) return accumulator + 1
			return 0
		}, 0)
		if (numberOfVideos >= LayoutLimits.videos) return false
		return true
	}

	canAddImage = (sources) => {
		const numberOfImages = sources.reduce((accumulator, currentValue) => {
			if (currentValue.type === sourceTypes.image) return accumulator + 1
			return 0
		}, 0)

		if (numberOfImages >= LayoutLimits.images) return false
		return true
	}

	canAddLiveCamera = (sources) => {
		const numberOfLiveCameras = sources.reduce((accumulator, currentValue) => {
			if (currentValue.type === sourceTypes.webRTC) return accumulator + 1
			return 0
		}, 0)

		if (numberOfLiveCameras >= LayoutLimits.liveCameras) return false
		return true
	}

	checkAgainstSourceLimits = (newSourceType) => {
		const sources = this.state.sources
		switch (newSourceType) {
			case sourceTypes.image:
				if (!this.canAddImage(sources)) {
					toast.warn(`Can't add more than ${LayoutLimits.images} images`)
					return false
				}
				break
			case sourceTypes.video:
				if (!this.canAddAVideo(sources)) {
					toast.warn(`Can't add more than ${LayoutLimits.videos} videos`)
					return false
				}
				break
			case sourceTypes.webRTC:
				if (!this.canAddLiveCamera(sources)) {
					toast.warn(
						`Can't add more than ${LayoutLimits.liveCameras} live cameras`
					)
					return false
				}
				break
			case sourceTypes.mediaQuery:
				return true
				break
			case "SVG":
				return true
				break
			default:
				toast.warn(`This source type is not supported`)
				return false
				break
		}
		return true
	}

	getMeta(url, callback) {
		const img = new Image()

		img.addEventListener('load', function () {
			callback({ w: this.naturalWidth, h: this.naturalHeight })
		})

		img.src = url

		callback({ w: this.naturalWidth, h: this.naturalHeight })
	}

	/**
	 * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
	 * images to fit into a certain area.
	 *
	 * @param {Number} srcWidth width of source image
	 * @param {Number} srcHeight height of source image
	 * @param {Number} maxWidth maximum available width
	 * @param {Number} maxHeight maximum available height
	 * @return {Object} { width, height }
	 */
	calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
		var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)

		return { width: srcWidth * ratio, height: srcHeight * ratio }
	}

	forceSourcePreviewHack(source) {
		const sources = this.state.sources
		this.updateSourceFillMode(sources.length - 1, 'contain') // hack to get it to show up in preview, needs a review.
		setTimeout(() => {
			this.updateSourcePositionRelative(sources.length - 1, {
				x: 0,
				y: 0,
				width: source.w,
				height: source.h,
			})
		}, 1)
	}

	addSource = (source) => {
		if (!source) return

		if (!this.checkAgainstSourceLimits(source.type)) return
		var sources = this.state.sources
		const swap = this.state.swapTarget

		//default to full screen for first item added
		const DefaultPosition = {
			x: source.x,
			y: source.y,
			width: source.w,
			height: source.h,
			z: source.z || 1,
			lockedAR: false,
			v: 0,
		}

		if (swap !== null) {
			dispatchEvent('swap-active', false)
			var sourceToReplace = sources[swap]
			sourceToReplace.type = source.type
			sourceToReplace.sourceURL = source.url
			sourceToReplace.sourceThumb = source.thumbURL
			sourceToReplace.canDrop = source.canDrop
			sourceToReplace.mediaQuery = source.mediaQuery
			sourceToReplace.fillMode = 'contain'
			sourceToReplace.backgroundColor = 'transparent'
			sourceToReplace.duration = 5000
		} else {
			sources.push({
				id: source._id || "" + "#" + CUID(),
				ref1: React.createRef(),
				ref2: React.createRef(),
				position: DefaultPosition,
				type: source.type,
				sourceURL: source.url,
				sourceThumb: source.thumbURL,
				canDrop: source.canDrop,
				mediaQuery: source.mediaQuery,
				fillMode: 'contain',
				backgroundColor: '#00000000',
				duration: 5000,
				lockedAR: false,
				volume: 100,
				fields: source.fields || [],
				name: source.name || `${source.type} - ${sources.length}`,
				graphicFunction: source.graphicFunction || null,
				hidden: false,
			})
		}

		this.setState(
			{
				sources: sources,
				swapTarget: null,
				selectedSourceIndex: sources.length - 1,
			},
			() => {
				this.saveExistingLayoutThrottled
				if (swap === null) {
					this.forceSourcePreviewHack(source)
				} else {
					this.updateSourceFillMode(swap, 'contain') // hack to get it to show up in preview, needs a review.
					setTimeout(() => {
						this.updateSourcePositionRelative(swap, sources[swap].position)
					}, 1)
				}
			}
		)
	}

	onTransition = () => {
		const data = cloneDeep(this.state.sources)
		dispatchEvent('transition-layout-pgm',{layout: data, transition: {mode: "cut", duration: 0}})
	}

	onTransitionFade = () => {
		const data = cloneDeep(this.state.sources)
		dispatchEvent('transition-layout-pgm',{layout: data, transition: {mode: "fade", duration: this.state.transitionTime}})
	}


	restartAll = () => {
		var currentSO = this.state.selectedSO
		this.setState({ selectedSO: { url: '', time: 0 } })
		setTimeout(() => {
			this.setState({ selectedSO: { url: currentSO.url, time: 0 } })
		}, 10)
	}

	toggleSourceLock = (index) => {
		var sources = this.state.sources
		var source = sources[index]
		source.lockedAR = !source.lockedAR
		this.setState({ sources: sources }, this.saveExistingLayoutThrottled)
	}

	updateSourcePositionRelative = (index, updatedPosition, selectbox) => {
		var sources = this.state.sources
		var source = sources[index]
		if (selectbox) {
			sources[index].selectedPosition = selectbox
		}
		sources[index].position = {
			...source.position,
			...updatedPosition,
			v: updatedPosition.v,
		}
		sources[index].lockedAR = false //aspect ratio will not be preserved anyway.

		this.setState({ sources: sources }, this.saveExistingLayoutThrottled)
	}

	updateSourceLock = (id, locked) => {
		const nextSources = this.state.sources.map((source) => {
			if (source.id === id) {
                return {
                    ...source,
                    lockedAR: locked,
                }
            } else {
                return source
            }
		})
		this.setState({ sources: nextSources }, this.saveExistingLayoutThrottled)
	}

	updateSourceVolume = (index, volume) => {
		var sources = this.state.sources
		var source = sources[index]
		source.volume = volume
		this.setState({ sources: sources }, this.saveExistingLayoutThrottled)
	}

	updateSourceFillMode = (index, fillMode) => {
		var sources = this.state.sources
		var source = sources[index]
		source.fillMode = fillMode
		this.setState({ sources: sources }, this.saveExistingLayoutThrottled)
	}

	updateSourceBackgroundColor = (index, color) => {
		var sources = this.state.sources
		var source = sources[index]
		source.backgroundColor = color
		this.setState({ sources: sources }, this.saveExistingLayoutThrottled)
	}

	addMediaToSourceQuery = (index) => {
		dispatchEvent('add-media-to-source-query', true)
	}

	appendMediaToSourceQuery = (item) => {
		var sources = this.state.sources
		var source = sources[this.state.selectedSourceIndex]
		source.mediaQuery.items.push(item)
		dispatchEvent('add-media-to-source-query', false)
		this.setState({ sources: sources }, this.saveExistingLayoutThrottled)
	}

	appendMediaToSourceQueryMultiple = (data) => {
		var sources = this.state.sources
		var source = sources[this.state.selectedSourceIndex]
		source.mediaQuery.items = data.items
		source.mediaQuery.type = `Tag(${data.tag.text})`
		dispatchEvent('add-media-to-source-query', false)
		this.setState({ sources: sources }, this.saveExistingLayoutThrottled)
	}

	updateSourceData = (index, update) => {
		var sources = this.state.sources
		var source = sources[this.state.selectedSourceIndex]
		source.mediaQuery.items = update //update takes priority otherwise use exisitng value
		this.setState({ sources: sources }, this.saveExistingLayoutThrottled)
	}

	setSourceField = (sourceId, fieldName, value) => {
			const nextSources = this.state.sources.map((source) => {
				if (source.id === sourceId) {
					let fields = source.fields
					let fieldIndex = fields.findIndex(field => field.name === fieldName)
					fields[fieldIndex].value = value
					return {
						...source,
						fields: fields,
					}
				} else {
					return source
				}
			})
			this.setState({ sources: nextSources }, this.saveExistingLayoutThrottled)
	}

	updateSourceById = (sourceId, update) => {
		const nextSources = this.state.sources.map((source) => {
			if (source.id === sourceId) {
				return {
					...source,
					...update,
				}
			} else {
				return source
			}
		})
		this.setState({ sources: nextSources }, this.saveExistingLayoutThrottled)
	}

	//sets the target for exchanging items
	exchange = (index) => {
		this.setState({ swapTarget: index })
		dispatchEvent('swap-active', true)
	}

	clampToBounds = (position, bounds) => {
		if (position.x < 0) position.x = 0
		if (position.y < 0) position.y = 0
		if (position.x + position.width > bounds.width)
			position.x = bounds.width - position.width
		if (position.y + position.height > bounds.height)
			position.y = bounds.height - position.height
		return position
	}

	updateSourcePosition = (updatedPosition, index) => {
		var sources = this.state.sources

		//we didn't have width and height so find them
		const fullPosition = {
			x: updatedPosition.x,
			y: updatedPosition.y,
			width: sources[index].position.width * this.state.canvasWidth,
			height: sources[index].position.height * this.state.canvasHeight,
		}

		const restrictedPosition = this.clampToBounds(fullPosition, {
			width: this.state.canvasWidth,
			height: this.state.canvasHeight,
		})
		sources[index].position.x = restrictedPosition.x / this.state.canvasWidth
		sources[index].position.y = restrictedPosition.y / this.state.canvasHeight
		this.setState({ sources: sources }, this.saveExistingLayoutThrottled)
	}

	updateSourcePositionAndSize = (updatedPosition, index) => {

		const canvasWidth = this.state.canvasWidth
		const canvasHeight = this.state.canvasHeight

		let fullPosition = {
			x: updatedPosition.x * canvasWidth,
			y: updatedPosition.y * canvasHeight,
			width: updatedPosition.width * canvasWidth,
			height: updatedPosition.height * canvasHeight,
		}

		if (fullPosition.x + fullPosition.width > canvasWidth) {
			return
		}

		if (fullPosition.y + fullPosition.height > canvasHeight) {
			return
		}

		const restrictedPosition = this.clampToBounds(fullPosition, {
			width: this.state.canvasWidth,
			height: this.state.canvasHeight,
		})

		var sources = this.state.sources
		sources[index].position.x = restrictedPosition.x / canvasWidth
		sources[index].position.y = restrictedPosition.y / canvasHeight
		sources[index].position.width = restrictedPosition.width / canvasWidth
		sources[index].position.height = restrictedPosition.height / canvasHeight
		this.setState({ sources: sources }, this.saveExistingLayoutThrottled)
	}

	updateSourceName = (sourceIndex, value) => {
		var { sources } = this.state
		sources[sourceIndex].name = value
		this.setState({ sources: sources }, this.saveExistingLayoutThrottled)
	}

	updateSourceDuration = (sourceIndex, value) => {
		var { sources } = this.state
		sources[sourceIndex].duration = value * 1000
		this.setState({ sources: sources }, this.saveExistingLayoutThrottled)
	}

	clearMediaSourceQuery = (index) => {
		var { sources } = this.state
		sources[index].mediaQuery = { type: 'MediaObjectList', items: [] }
		this.setState({ sources: sources }, this.saveExistingLayoutThrottled)
	}

	handleTargetSelected = (index) => {
		this.setState({ selectedSourceIndex: index })
	}

	renderSource = (source, index) => {
		var getContent = (source, selected) => {
			let modify = ''
			if (selected) {
				modify = ' flash'
			}
			switch (source.type) {
				case sourceTypes.image:
					return (
						<Image2
							className={'' + modify}
							ref={source.ref1}
							width='100%'
							height='100%'
							src={source.sourceURL}
						></Image2>
					)
				case sourceTypes.webRTC:
					return (
						<LiveSourcePreview
							modify={modify}
							source={source}
							updateCamera={this.updateCameraMapping}
						/>
					)
				case sourceTypes.mediaQuery:
					return (
						<MediaQuerySourcePreview
							canvasPreviewRef={source.ref1}
							canvasOverlayRef={source.ref2}
							source={source}
							modify={modify}
							onContentLoaded={this.saveExistingLayoutThrottled}
						/>
					)
				case sourceTypes.video:
					return (
						<div
							className={'' + modify}
							style={{
								display: 'inline-block',
								position: 'relative',
								width: '100%',
								height: '100%',
							}}
						>
							<Image2
								ref={source.ref1}
								src={
									source.sourceThumb
										? source.sourceThumb
										: '/video-processing.png'
								}
								crossOrigin='anonymous'
								style={{
									display: 'none',
									width: '100%',
									height: 'auto',
									maxHeight: '100%',
									objectFit: source.fillMode,
									marginTop: 'auto',
									position: 'absolute',
									transform: 'translate(-50%, -50%)',
									top: '50%',
									left: '50%',
								}}
							></Image2>
							<video
								src={
									source.sourceURL
								}
								crossOrigin='anonymous'
								style={{
									display: 'inline-block',
									width: '100%',
									height: 'auto',
									maxHeight: '100%',
									objectFit: source.fillMode,
									marginTop: 'auto',
								}}
								muted
								autoPlay
								loop
							></video>
						</div>
					)
				case sourceTypes.SVG:
				   
				   let func = source.sourceURL

					//evaluate the URL as a function
					if(typeof source.sourceURL !== 'function') {
						try {
							eval(`try {func = ${source.sourceURL}} catch (e) {console.log(e)}`);
						} catch (e) {
							console.log(e)
						}
					}

					if(typeof func !== 'function') {
						return
					}

					//get the SVG string with templated data
					let data = cloneDeep(this.context.data)
					if(source.fields && data) {
						source.fields.forEach((field) => {
							if(field.value !== "USE TEMPLATE") {
								data[field.name] = field.value
							}
						})
					}
					
					let svg = func({...data, width: source.position.width*LayoutResolution.w, height: source.position.height*LayoutResolution.h})

					var image64 = convertSVGtoIMG(svg)


					return (
                        <img
							className={'' + modify}
							ref={source.ref1}
							width='100%'
							height='100%'
							src={image64}
						></img>
					)
				default:
					return <div></div>
			}
		}

		const isSelected = this.state.selectedSourceIndex === index

		return (
			<RenderTarget
				sources={this.state.sources}
				key={index}
				source={source}
				isSelected={isSelected}
				index={index}
				content={getContent(source, isSelected)}
				canvasWidth={this.state.canvasWidth}
				canvasHeight={this.state.canvasHeight}
				onPositionChanged={this.updateSourcePositionAndSize}
				onPositionChanging={this.updateSourcePosition}
				onTargetExchanged={this.exchange}
				onTargetSelected={this.handleTargetSelected}
				onTargetRemoved={this.removeSource}
				toggleSourceLock={this.toggleSourceLock}
				isOverlay={false}
				boundElement={this.renderRef.current}
			></RenderTarget>
		)
	}

	updateCameraMapping = (data) => {

	}

	removeSource = (id) => {
		var sources = this.state.sources
		var index = this.state.sources.findIndex((source) => source.id === id)
		sources.splice(index, 1)

		let newSelectedIndex = index - 1

		if (newSelectedIndex < 0) {
			newSelectedIndex = 0
		}
		this.setState(
			{ sources: sources, selectedSourceIndex: newSelectedIndex },
			this.saveExistingLayoutThrottled
		)
	}

	createNewScene = () => {
		var layoutList = this.state.layoutList
		layoutList.push({ layout: [], preview: null, id: UUID() })
		var updatedlayoutList = cloneDeep(layoutList)
		this.saveLayoutsToStoreThrottled(updatedlayoutList)
		this.setState(
			{
				layoutList: updatedlayoutList,
				activeScene: updatedlayoutList.length - 1,
			},
			this.jumpToLayout(updatedlayoutList.length - 1)
		)
	}

	clearLayout = () => {
		const { layoutList: layoutList, activeScene } = this.state
		var sources = []
		const result = this.updateCanvas()
		layoutList[activeScene] = { layout: sources, preview: result }
		var sourceL = cloneDeep(layoutList)
		this.saveLayoutsToStoreThrottled(sourceL)
		this.loadLayout(layoutList[activeScene].layout)
		this.setState({ layoutList: sourceL })
	}

	saveExistingLayout = () => {
		var layoutList = this.state.layoutList
		var index = this.state.activeScene
		var sources = cloneDeep(this.state.sources)
		const result = this.updateCanvas()
		layoutList[index] = { layout: sources, preview: result }
		var sourceL = cloneDeep(layoutList)
		this.saveLayoutsToStoreThrottled(sourceL)
		this.setState({ layoutList: sourceL })
	}

	duplicateLayout = (index) => {
		var layoutList = this.state.layoutList
		const layoutToCopy = cloneDeep(this.state.layoutList[index])
		layoutList.splice(index+1, 0, { layout: layoutToCopy.layout, preview: layoutToCopy.preview })
		var sourceL = cloneDeep(layoutList)
		this.saveLayoutsToStoreThrottled(sourceL)
		this.setState({ layoutList: sourceL })
	}

	loadLayout = (layout) => {
		const loadLayout = async (layout) => {
			if (layout) {
				for (var i = 0; i < layout.length; i++) {
					var source = layout[i]
					source.ref1 = React.createRef()
					source.ref2 = React.createRef()
				}
			}
			return layout
		}
		if (layout) {
			loadLayout(layout).then((result) => {
				if (result !== undefined) {
					this.setState({
						sources: cloneDeep(result),
						timeLeft: false,
						selectedSourceIndex: undefined,
					})
					this.updateCanvas()
				}
			})
		} else {
			this.setState({
				sources: cloneDeep(layout),
				selectedSourceIndex: undefined,
			})
			this.updateCanvas()
		}
	}

	saveLayoutsToStore(layouts) {
		var deRefLayouts = layouts.map((layout) => {
			return {
				...layout,
				layout: layout.layout.map((source) => {
					return { ...source, ref1: null, ref2: null }
				}),
			}
		})

		Hub.setEventScenes(token, GetEventFromURL(), deRefLayouts)
			.then((result) => {

			})
			.catch((err) => {
				console.log(err)
			})
	}

	handleRemoveLayout = (i) => {
		const { sources } = this.state
		const layouts = this.state.layoutList.filter((layout, index) => index !== i)
		this.saveLayoutsToStoreThrottled(layouts)
		
		var nextSeq = this.state.activeScene 
		
		//if the selected scene index would be out off bounds then decrease it
		if (nextSeq	> layouts.length - 2) {
			nextSeq = nextSeq - 1
		}

		this.setState({
			layoutList: layouts,
			sources: !layouts.length ? [] : sources,
			removeLayoutDialog: !this.state.removeLayoutDialog,
			removeLayoutIndex: undefined,
			selectedSourceIndex: 1,
			activeScene: 0,
		})
	}

	goToSeq = (seqNo) => {
		this.loadLayout(this.state.layoutList[seqNo].layout)
		this.setState({
			activeScene: seqNo,
		})
	}

	/**
	 * Update the preview canvas and return the image as a DataURL
	 * @returns {DataURL} the rendered preview image
	 */
	updateCanvasRaw = () => {
		const canvas = this.canvasRef.current
		if (!canvas) return
		const canvasContext = canvas.getContext('2d')
		const sources = this.state.sources
		PreviewRenderer.Init(canvasContext, LayerPreviewResolution)
		PreviewRenderer.Render(canvasContext, sources, LayerPreviewResolution)
		return canvas.toDataURL()
	}

	toggleRemoveLayoutDialog = (index) => {
		this.setState({
			removeLayoutDialog: !this.state.removeLayoutDialog,
			removeLayoutIndex: index,
		})
	}

	handleDrop = (e) => {
		console.log(e.dataTransfer)
	}

	//temp - reload preview to get around bounding error in react RND
	//RND bounds are only checked on resize and not on parent size change so we need to reload the whole thing.
	reloadPreviewHack() {
		this.setState(
			{
				sources: [],
			},
			() => {
				if (this.state.layoutList.length < 1) return
				setTimeout(() => {
					this.jumpToLayout(this.state.activeScene)
				}, 10)
			}
		)
	}

	setScene = (sceneIndex) => {
		if(this.state.layoutList.length < sceneIndex +1) return
		this.jumpToLayout(sceneIndex)
		setTimeout(() => {
			this.onTransition()
		}, 100)
	}

	render() {
		if(this.state.autoUpdatePGM) {
			this.onTransition()
			console.log('updating PGM')
		}
		return (
			<>
				<UseEventCallback
					event='add-source'
					callback={this.addSource}
				/>
				<UseEventCallback
					event="camera-change"
					callback={this.updateCameraMapping}
				/>
				<UseEventCallback
					event='append-media-to-source-query'
					callback={this.appendMediaToSourceQuery}
				/>
				<UseEventCallback
					event='append-media-to-source-query-multiple'
					callback={this.appendMediaToSourceQueryMultiple}
				/>
				<UseEventCallback
					event='set-scene'
					callback={this.setScene}
				/>

				<div className='main-content'>
					<div className='dnd-list'>
						<PanelHeader
							title={'Scenes'}
							infoLink={process.env.REACT_APP_FRESHDESK_SCENES}
						>
							<Button
								disabled={this.state.isStreaming}
								variant='transparent'
								className='io-btn-light'
								onClick={this.createNewScene}
							>
								Add
							</Button>
						</PanelHeader>

						<div
							className='scenes-control-panel'
							style={{ display: 'flex' }}
						>
							<FontAwesomeButton
								icon={faBackwardStep}
								size='1x'
								color='white'
								onClick={this.handleGoFirst}
							/>
							<FontAwesomeButton
								icon={faCaretLeft}
								size='1x'
								color='white'
								onClick={this.handleLast}
							/>
							<input
								className='form-control scenes-control'
								disabled
								type='text'
								value={this.state.activeScene + 1}
							/>
							<FontAwesomeButton
								icon={faCaretRight}
								size='1x'
								color='white'
								onClick={this.handleNext}
							/>
							<FontAwesomeButton
								icon={faForwardStep}
								size='1x'
								color='white'
								onClick={this.handleGoLast}
							/>
						</div>
						{this.state.layoutList.length <= 0 ? (
							<div className='blank-panel-window'>
								<div className='blank-panel-window-text'>
									Create a scene by clicking "ADD"
								</div>
							</div>
						) : (
							<LayoutPreviewList
							layoutList={this.state.layoutList}
								activeScene={this.state.activeScene}
								currentLiveScene={this.state.currentLiveScene}
								activeLayoutId={this.state.activeLayoutId}
								removeLayoutCB={this.toggleRemoveLayoutDialog}
								jumpToLayoutCB={this.jumpToLayout}
								updateLayoutOrderCB={(data) => {
									this.setState({
										layoutList: data.updatedLayoutList,
										activeScene: data.activeScene
									}, this.saveLayoutsToStoreThrottled(this.state.layoutList));
								}}
								copyLayoutCallback={this.duplicateLayout}
							></LayoutPreviewList>
						)}
					</div>

					<div className='scene-preview'>
						<div className='preview-container'>
							<PanelHeader
								title={'Scene Preview'}
								infoLink={process.env.REACT_APP_FRESHDESK_SCENE}
							>
								<Form.Check
									type={'checkbox'}
									id={`autoModeCheckbox`}
									style={{margin: "0px", marginRight: "5px", background: "#27293a", fontSize: "smaller", height: "auto"}}
									value={this.state.autoUpdatePGM}
									onChange={(e) => this.setState({ autoUpdatePGM: e.target.checked })}
								/>
								<Form.Label style={{margin: "0px", marginRight: "15px"}}>Direct Mode</Form.Label>
	
								<FormLabel style={{margin: "0px", marginRight: "5px"}}>Fade Duration: </FormLabel>
								<FormControl
									style = {{padding: "0px 5px 0px 5px", marginRight: "10px", width: "50px", textAlign: "center", backgroundColor: "#27293a", border: "1px solid #6b6b6b"}}
									as="input"
									value={this.state.transitionTime}
									onChange={(e) => {
                                        this.setState({ transitionTime: e.target.value })
                                    }}
									type="number"
								></FormControl>
								<Button
									variant='transparent'
									className='io-btn-light io-btn-light'
									style={{ background: '#222', marginRight: '10px' }}
									onClick={this.onTransitionFade}
								>
									Fade <FontAwesomeIcon icon={faArrowRight} />
								</Button>
								<Button
									variant='transparent'
									className='io-btn-light io-btn-light'
									style={{ background: '#222' }}
									onClick={this.onTransition}
								>
									Cut <FontAwesomeIcon icon={faArrowRight} />
								</Button>
							</PanelHeader>

							
								<div
									className='preview-container-inner'
									onClick={() =>
										this.setState({ selectedSourceIndex: undefined })
									}
								>
									<AspectRatio ratio={'16:9'}>
										<SizedCanvas
											style={{ width: '100%', height: '100%' }}
											onSizeChangeCB={(width, height) => {
												this.setState(
													{
														canvasHeight: height,
														canvasWidth: width,
													},
													() => {
														this.reloadPreviewHack()
													}
												)
											}}
										>
											<div
												ref={this.renderRef}
												className='preview-container-sources imptp'
												onClick={() =>
													this.setState({ selectedSourceIndex: undefined })
												}
											>
												{
													this.state.sources.map((source, index) => {
														if(source.hidden === true) return <></>
														return this.renderSource(source, index)
													}
												)}
											</div>
										</SizedCanvas>
									</AspectRatio>
								</div>
						</div>

						<div style={{height: "100%", maxHeight: "100%"}}>

						<RenderTargetListSubpanel
							renderTargetsList={this.state.sources}
							selectedRenderTarget={
								this.state.sources[this.state.selectedSourceIndex]
							}
							onSelected={(id) => {
								const index = this.state.sources.findIndex(
									(source) => source.id === id
								)
								if (index !== -1) {
									this.setState({ selectedSourceIndex: index })
								}
							}}
							onRemove={this.removeSource}
							onClearLayout={this.clearLayout}
							onAddObject={(object) => this.addSource(object)}
							onToggleObjectLocked={this.updateSourceLock}
							onUpdateSource={this.updateSourceById}
						/>

						<RenderTargetPropertiesSubpanel
							renderTargetList={this.state.sources}
							index={this.state.selectedSourceIndex}
							updateSourcePositionRelative={this.updateSourcePositionRelative}
							updateSourceName={this.updateSourceName}
							updateSourceFillMode={this.updateSourceFillMode}
							updateSourceBackgroundColor={throttle(
								this.updateSourceBackgroundColor,
								1000
							)}
							updateSourceVolume={this.updateSourceVolume}
							addMediaToSourceQuery={this.addMediaToSourceQuery}
							updateSourceDuration={this.updateSourceDuration}
							clearMediaSourceQuery={this.clearMediaSourceQuery}
							updateSourceData={this.updateSourceData}
							setSourceField={this.setSourceField}
						/>

						</div>

						<canvas
							ref={this.canvasRef}
							className='preview-canvas'
						/>
					</div>
				</div>
				<ConfirmationDialog
					show={this.state.removeLayoutDialog}
					onClose={this.toggleRemoveLayoutDialog}
					content={'Are you sure want to delete'}
					header='Delete this layout?'
					onDelete={() => this.handleRemoveLayout(this.state.removeLayoutIndex)}
				/>
			</>
		)
	}
}
