import React, { useState } from "react";
import { FormControl, Button, Row, Col, OverlayTrigger, Popover, Tooltip} from "react-bootstrap";
import {
  LayerBackground,
  LayerForeground,
  LayerMiddle,
  PositionBottomLeft,
  PositionBottomRight,
  PositionCenter,
  PositionMini,
  PositionTopLeft,
  PositionTopRight,
} from "../../../components/svgIcons";
import PanelHeader from "../../components/PanelHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import sourceTypes from "../../../constants/sourceTypes";
import FontAwesomeButton from '../../../util/FontAwesomeButton';

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from "react-grid-dnd";
import Slider from "rc-slider";
import { ChromePicker } from "react-color";
import ColorPickerButton from "./ColorPickerButton";
import { faObjectsAlignCenterHorizontal, faObjectsAlignCenterVertical } from "@fortawesome/pro-solid-svg-icons";
import { isEqual } from "lodash";
import { render } from "react-dom";

const maxLayers = 10 //maximum number of layers for the preview

export default function RenderTargetPropertiesPanel({
  renderTargetList,
  index,
  updateSourcePositionRelative,
  updateSourceName,
  updateSourceFillMode,
  updateSourceBackgroundColor,
  addMediaToSourceQuery,
  updateSourceDuration,
  clearMediaSourceQuery,
  updateSourceMediaQuery,
  updateSourceVolume,
  updateSourceData,
  setSourceField
}) {

  


  const [isInMediaEditMode, setIsInMediaEditMode] = useState(false)

  //reset is selected object changes
  React.useEffect(() => {
    setIsInMediaEditMode(false);
  }, [index])

  if (index === undefined || index >= renderTargetList.length || index === null || index === -1) {
    //no item selected so return note
    return (
      <div className="subpanel-scene-object-props">
        <PanelHeader
          title={"Scene Object Properties"}
          infoLink={process.env.REACT_APP_FRESHDESK_SCENE_OBJECT_PROPERTIES}
        />
        <div className="blank-panel-window">
          <div className="blank-panel-window-text">
            Select an object to view its properties
          </div>
        </div>
      </div>
    );
  }

  const renderTarget = renderTargetList[index];


  const onChange = (sourceId, sourceIndex, targetIndex, targetId) => {
    const nextState = swap(renderTarget.mediaQuery.items, sourceIndex, targetIndex);
    updateSourceData(index, nextState)
  }

  if(isInMediaEditMode && renderTarget.mediaQuery !== undefined) {
    return (
      <div className="subpanel-scene-object-props">
      <PanelHeader
        title={"Scene Object Properties"}
        infoLink={process.env.REACT_APP_FRESHDESK_SCENE_OBJECT_PROPERTIES}
      />
      <div className="panel-subheader">
                <div className="panel-subheader-content">
                <Button className="io-btn-light" style={{marginRight: "10px"}} onClick={() => setIsInMediaEditMode(false)}><FontAwesomeIcon icon={faArrowLeft}/></Button>
                  {renderTarget.name + " " + " - Content"}
                </div>
      </div>
      <div className="scene-object-properties-row">
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-left-wrapper">
              <div className="scene-object-properties-title">Media Query</div>
            </div>
          </div>
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-right-wrapper">

                  <FormControl
                    as="input"
                    disabled
                    value={
                      renderTarget.mediaQuery
                        ? renderTarget.mediaQuery.type
                        : "None"
                    }
                  ></FormControl>
                
                  <Button
                    style={{ fontSize: "smaller" }}
                    onClick={() => clearMediaSourceQuery(index)}
                  >
                    Clear
                  </Button>

                  <Button
                    style={{ fontSize: "smaller" }}
                    onClick={() => addMediaToSourceQuery(index)}
                  >
                    Add
                  </Button>
            </div>
          </div>
        </div>
      <GridContextProvider onChange={onChange}>
      <GridDropZone
        id="items"
        boxesPerRow={5}
        rowHeight={100}
        style={{ height: "calc(100% - 10px)", maxHeight: "calc(100% - 140px)", width: "calc(100% - 0px)", overflowY: "scroll", overflowX: "hidden"}}
      >
        {renderTarget.mediaQuery.items.map(item => (
          <GridItem key={item._id}>
            <div
              style={{
                marginLeft: "5px",
                marginTop: "5px",
                width: "calc(100% - 5px)",
                height: "calc(100% - 5px)"
              }}
            >
                <img src={item.thumbnail} style={{width: "100%", height: "100%", pointerEvents: "none"}} ></img>
                <FontAwesomeButton
                  style={{float: "right", display: "inline-block", position: "absolute", right: "2px", top: "5px", margin: "0px"}}
                  size={"1x"}
                  color={"red"}
                  icon={faTimes}
                  tooltip={"Remove"}
                  onClick={() => {updateSourceData(index, renderTarget.mediaQuery.items.filter(x => x._id !== item._id))}}
                  >
                </FontAwesomeButton>
            </div>
          </GridItem>
        ))}
      </GridDropZone>
    </GridContextProvider>
    </div>
    )
  }

  const centerPositionY = {
    ...renderTarget.position,
    y: 0.5 - renderTarget.position.height/2,
  }

  const centerPositionX = {
    ...renderTarget.position,
    x: 0.5 - renderTarget.position.width/2
  }

  const upperLeftQuad = {
    ...renderTarget.position,
    x: 0,
    y: 0, 
    width: 0.5,
    height: 0.5
  }

  const upperRightQuad = {
    ...renderTarget.position,
    x: 0.5,
    y: 0, 
    width: 0.5,
    height: 0.5
  }

  const lowerLeftQuad = {
    ...renderTarget.position,
    x: 0,
    y: 0.5, 
    width: 0.5,
    height: 0.5
  }

  const lowerRightQuad = {
    ...renderTarget.position,
    x: 0.5,
    y: 0.5, 
    width: 0.5,
    height: 0.5
  }

  const fullScreen = {
    ...renderTarget.position,
    x: 0,
    y: 0, 
    width: 1,
    height: 1
  }

  const pip = {
    ...renderTarget.position,
    x: 0.65,
    y: 0.62,
    width: 0.3,
    height: 0.3,
  }


  return (

    <div className="subpanel-scene-object-props">
      <PanelHeader
        title={"Scene Object Properties"}
        infoLink={process.env.REACT_APP_FRESHDESK_SCENE_OBJECT_PROPERTIES}
      />
      <div
        className="scene-object-properties"
        key={renderTarget.id + renderTarget.position.v}
      >
        <div className="scene-object-properties-row">
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-left-wrapper">
              <div className="scene-object-properties-title">Name</div>
            </div>
          </div>
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-right-wrapper">
              <FormControl
                className="form-control scene-object-properties-input"
                type="text"
                value={renderTarget.name}
                onChange={(e) => {
                  updateSourceName(index, e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="scene-object-properties-row">
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-left-wrapper">
              <div className="scene-object-properties-title">Position</div>
            </div>
          </div>
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-right-wrapper">
              <div className="scene-object-position">
                <div className="scene-object-position-item">
                  <Button
                    variant="transparent"
                    className="btn-scene-object"
                    onClick={() =>
                      updateSourcePositionRelative(
                        index,
                        upperLeftQuad,
                        ""
                      )
                    }
                  >
                    <PositionTopLeft source={renderTarget} isMatched={isEqual(upperLeftQuad, renderTarget.position)} />
                  </Button>
                </div>
                <div className="scene-object-position-item">
                  <Button
                    variant="transparent"
                    className="btn-scene-object"
                    onClick={() =>
                      updateSourcePositionRelative(
                        index,
                        upperRightQuad,
                        "TopRight"
                      )
                    }
                  >
                    <PositionTopRight source={renderTarget} isMatched={isEqual(upperRightQuad, renderTarget.position)} />
                  </Button>
                </div>
                <div className="scene-object-position-item">
                  <Button
                    variant="transparent"
                    className="btn-scene-object"
                    onClick={() =>
                      updateSourcePositionRelative(
                        index,
                        lowerRightQuad,
                        "BottomRight"
                      )
                    }
                  >
                    <PositionBottomRight source={renderTarget} isMatched={isEqual(lowerRightQuad, renderTarget.position)} />
                  </Button>
                </div>
                <div className="scene-object-position-item">
                  <Button
                    variant="transparent"
                    className="btn-scene-object"
                    onClick={() =>
                      updateSourcePositionRelative(
                        index,
                        lowerLeftQuad,
                        "BottomLeft"
                      )
                    }
                  >
                    <PositionBottomLeft source={renderTarget} isMatched={isEqual(lowerLeftQuad, renderTarget.position)}/>
                  </Button>
                </div>
                <div className="scene-object-position-item">
                  <Button
                    variant="transparent"
                    className="btn-scene-object"
                    onClick={() =>
                      updateSourcePositionRelative(
                        index,
                        fullScreen,
                        "Center"
                      )
                    }
                  >
                    <PositionCenter source={renderTarget} isMatched={isEqual(fullScreen, renderTarget.position)} />
                  </Button>
                </div>
                <div className="scene-object-position-item">
                  <Button
                    variant="transparent"
                    className="btn-scene-object"
                    onClick={() =>
                      updateSourcePositionRelative(
                        index,
                        pip,
                        "Mini"
                      )
                    }
                  >
                    <PositionMini source={renderTarget} isMatched={isEqual(pip, renderTarget.position)} />
                  </Button>
                  <Button
                    variant="transparent"
                    className="btn-scene-object"
                    style={{width: "2em", height: "2em", borderRadius: "5px", fontSize: "large", padding: "2px"}}
                    onClick={() =>
                      updateSourcePositionRelative(
                        index,
                        centerPositionX,
                        ""
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faObjectsAlignCenterHorizontal} size={"1x"} color={renderTarget.position.x === centerPositionX.x ? "#01d1fa" : "#858585"}/>
                  </Button>
                  <Button
                    variant="transparent"
                    className="btn-scene-object"
                    style={{width: "2em", height: "2em", borderRadius: "5px", fontSize: "large", padding: "2px"}}
                    onClick={() => {
                      updateSourcePositionRelative(
                        index,
                        centerPositionY,
                        ""
                      )
                    }}
                  >
                    <FontAwesomeIcon icon={faObjectsAlignCenterVertical} size={"1x"} color={renderTarget.position.y === centerPositionY.y ? "#01d1fa" : "#858585"}/>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="scene-object-properties-row">
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-left-wrapper">
              <div className="scene-object-properties-title">Layer</div>
            </div>
          </div>
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-right-wrapper">
              <div className="layer-properties">
                <div className="layer-selection-wrapper">
                  <FormControl
                    className="form-control layer-selection-input"
                    type="number"
                    style={{display: "none"}}
                    onChange={(e) =>
                      updateSourcePositionRelative(index, {
                        z: Math.round(e.target.value),
                      })
                    }
                    min={1}
                    max={maxLayers}
                    default
                    value={renderTarget.position.z}
                    defaultValue={renderTarget.position.z}
                    placeholder={renderTarget.position.z}
                  />
                </div>
                <div className="layer-selection">
                  <Button
                    variant={
                      "transparent"
                    }
                    className="btn-layer-select"
                    onClick={() =>
                      updateSourcePositionRelative(index, {
                        z: 1,
                      })
                    }
                  >
                    <LayerBackground isMatched={renderTarget.position.z === 1}/>
                  </Button>
                  <Button
                    variant={
                      "transparent"
                    }
                    className="btn-layer-select"
                    onClick={() =>
                      updateSourcePositionRelative(index, {
                        z: 2,
                      })
                    }
                  >
                    <LayerMiddle isMatched={renderTarget.position.z === 2} />
                  </Button>
                  <Button
                    variant={
                      "transparent"
                    }
                    className="btn-layer-select"
                    onClick={() =>
                      updateSourcePositionRelative(index, {
                        z: 3,
                      })
                    }
                  >
                    <LayerForeground isMatched={renderTarget.position.z === 3} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="scene-object-properties-row">
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-left-wrapper">
              <div className="scene-object-properties-title">Background</div>
            </div>
          </div>
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-right-wrapper">
                    <ColorPickerButton 
                        color={renderTarget.backgroundColor} 
                        onChange={(color, e) => updateSourceBackgroundColor(index, `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a || 1})`)}/>
                    <Button style={{fontSize: "smaller", display: "inline-block"}} onClick={() => updateSourceBackgroundColor(index, "#00000000")}>Remove Background</Button>
            </div>
          </div>
        </div>

        <div className="scene-object-properties-row">
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-left-wrapper">
              <div className="scene-object-properties-title">Fill Mode</div>
            </div>
          </div>
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-right-wrapper">
              <FormControl
                as="select"
                onChange={(e) => updateSourceFillMode(index, e.target.value)}
                value={renderTarget.fillMode}
              >
                <option value="contain">Contain - (leterbox)</option>
                <option value="cover">Cover - (crop)</option>
                <option value="fill">Fill - (strech)</option>
              </FormControl>
            </div>
          </div>
        </div>

        <div className="scene-object-properties-row"  style={{display: (renderTarget.type === sourceTypes.webRTC || renderTarget.type === sourceTypes.SVG || renderTarget.type === sourceTypes.video) ? "none" : "flex"}}>
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-left-wrapper">
              <div className="scene-object-properties-title">Media Query</div>
            </div>
          </div>
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-right-wrapper">
                  <FormControl
                    as="input"
                    disabled
                    value={
                      renderTarget.mediaQuery
                        ? renderTarget.mediaQuery.type
                        : "None"
                    }
                  ></FormControl>
             
                  <Button
                    style={{ fontSize: "smaller" }}
                    onClick={() => setIsInMediaEditMode(true)}
                  >
                    Edit
                  </Button>
                  <Button
                    style={{ fontSize: "smaller" }}
                    onClick={() => clearMediaSourceQuery(index)}
                  >
                    Clear
                  </Button>
            </div>
          </div>
        </div>

        <div className="scene-object-properties-row" style={{display: (renderTarget.type === sourceTypes.webRTC || !renderTarget.mediaQuery) ? "none" : "flex"}}>
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-left-wrapper">
              <div className="scene-object-properties-title">Presentation</div>
            </div>
          </div>
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-right-wrapper">
              <FormControl as="select" disabled>
                <option value="contain">Slideshow</option>
                <option value="cover">Grid</option>
              </FormControl>
            </div>
          </div>
        </div>

        <div className="scene-object-properties-row"  style={{display: (renderTarget.type === sourceTypes.webRTC || !renderTarget.mediaQuery) ? "none" : "flex"}}>
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-left-wrapper">
              <div className="scene-object-properties-title">Duration</div>
            </div>
          </div>
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-right-wrapper">
              <FormControl as="input" type="input" value={renderTarget.duration/1000} onChange={(e) => updateSourceDuration(index, e.target.value)}></FormControl>
            </div>
          </div>
        </div>

        <div className="scene-object-properties-row"  style={{display: (renderTarget.type === sourceTypes.webRTC || renderTarget.type === sourceTypes.video) ? "flex" : "none"}}>
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-left-wrapper">
              <div className="scene-object-properties-title">Volume</div>
            </div>
          </div>
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-right-wrapper">
            <Slider
								className='audio-mix-channel-slider'
								defaultValue={100}
								min={0}
								max={100}
								step={1}
								value={renderTarget.volume}
								onChange={(value) => updateSourceVolume(index, Math.round(value))}
							/>
              <FormControl as="input" type="number" max={100} min={0} value={renderTarget.volume} onChange={(e) => updateSourceVolume(index, Math.round(e.target.value))}></FormControl>
            </div>
          </div>
        </div>
        <div className="scene-object-properties-row"  style={{display: (renderTarget.type === sourceTypes.SVG) ? "flex" : "none"}}>
          <div className="scene-object-properties-col">
            <div className="scene-object-properties-left-wrapper">
              <div className="scene-object-properties-title">Fields</div>
            </div>
          </div>
          <div className="scene-object-properties-col">
                    <div style={{width: "100%", paddingRight: "5px"}}>
              {renderTarget.fields && renderTarget.fields.map((field, index) => {
                if(field.type === "color") {
                  return (
                  <div style={{display: "flex", padding: "5px"}}key={field.name}>
                    <div style={{width: "60%"}}>{field.name}</div>
                    <ColorPickerButton color={field.value} onChange={(color, e) => setSourceField(renderTarget.id, field.name, `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a || 1})`)}/>
                  </div>
                  )
                }
                return <div style={{display: "flex", padding: "5px"}}key={field.name}><div style={{width: "60%"}}>{field.name}</div><FormControl as="input" type="text" value={field.value} onChange={(e) => setSourceField(renderTarget.id, field.name, e.target.value)}></FormControl></div>
              })}  
              </div>
          </div>
          </div>
        </div>
      </div>

  );
}
