import React from "react";
import {
  faExchangeAlt,
  faLock,
  faLockOpen,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import FontAwesomeButton from "../../../util/FontAwesomeButton";

/**
 * Implements the toolbar logic for the WYSIYWG preview
 * @param {*} {onTargetRemoved, onTargetExchanged}
 * @returns {JSX.Element} RenderTargetToolbar
 */
export default function RenderTargetToolbar({
  onTargetRemoved,
  onTargetExchanged,
  locked,
  toggleSourceLock,
  toolbarVisible,
}) {
  //should add scale factor?
  const IconSize = "1x";

  return (
    <div className="sourceContainerComponent renderTargetToolbar" style={toolbarVisible}>
      <FontAwesomeButton
        style={{ marginRight: "10px" }}
        tooltip={locked ? "Unlock" : "Lock"}
        icon={locked ? faLock : faLockOpen}
        size={IconSize}
        onClick={toggleSourceLock}
      />
      <FontAwesomeButton
        style={{ marginRight: "10px" }}
        tooltip={"Swap"}
        icon={faExchangeAlt}
        size={IconSize}
        onClick={onTargetExchanged}
      />
      <FontAwesomeButton
        className="renderTargetRemoveButton"
        style={{marginRight: "0px"}}
        color={"red"}
        tooltip={"Remove"}
        icon={faTimes}
        size={IconSize}
        onClick={onTargetRemoved}
      />
    </div>
  );
}
