import React, {useState, useEffect} from 'react';
import {cloneDeep} from 'lodash'
import {ListGroup, Form, Button, Dropdown} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera, faImage, faVideo } from '@fortawesome/free-solid-svg-icons'
import PanelHeader from '../../../components/PanelHeader';
import FontAwesomeButton from '../../../../util/FontAwesomeButton'
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

/*const getSceneSources = (sources) => {
  console.log("Sources", sources)
    return sources.map(source => {

      var typeIcon = <FontAwesomeIcon icon={faImage} />
      if (source.type === "video") typeIcon = <FontAwesomeIcon icon={faVideo}/>
      if (source.type === "webRTC") typeIcon = <FontAwesomeIcon icon={faCamera}/>
      return (
      <ListGroup.Item>
          <p>{typeIcon} Name: {source.type}</p>
          {source.type === "video" ? <Form.Check type="checkbox" label="Loop" /> : <p></p>}
      </ListGroup.Item>
    )})
}*/

const Sidebar = ({selectedElement, onChangeProperty}) => {
  const [element, setSelectedElement] = useState({...selectedElement})
  const [time, setTime] = useState(0)
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setSelectedElement(selectedElement);
    setVisible(true)
  }, [selectedElement]);

  useEffect(() => {
    if(element == null) return;
    onChangeProperty(element)
  }, [element]);
 
  if (!element) {
    return (
      <aside style={{maxWidth: "0px", padding: "0px"}}/>
    )
  }

  const hideSideBar = () => {
    setVisible(false)
  }

  var content = null

  if(element.type === "sceneNode" && element.data !== undefined) {
    content =  (
        <div>
          <img src={element.data.preview} style={{ width: "100%", height: "auto"}}></img>
        <br></br>
        <p><b>Content Details</b></p>
        <Form style={{justifyContent: "left", textAlign: "left"}}>
          <Form.Label><b>Runtime:</b> Infinite (live)</Form.Label>
          <br></br>
          <Form.Label><b>Options:</b></Form.Label>
          <Form.Check style={{marginLeft: "10px"}} type="checkbox" label="Loop Videos" checked={element.data.loop ? element.data.loop : false} onChange={(event)=> setSelectedElement({...element, data: {...element.data, loop: event.target.checked}})} />
        </Form>
        </div>
    );
  }

  if(element.type === "input") {
    content = (
      <div>
          <h5>Sequence Start</h5>
          <p><b>This is a sequence start, you can cue it by clicking on it or pressing its cue key</b></p>
          <br></br>
          <p>Options</p>
          <Form style={{justifyContent: "left", textAlign: "left"}}>
            <Form.Label><b>Name</b></Form.Label> 
            <br></br>
            <Form.Control type="text" placeholder="Sequence name" value={element.data.label} onChange={(event) => setSelectedElement({...element, data: {...element.data, label: event.target.value}})} />
            <br></br>
            <Form.Label><b>Cue Key</b></Form.Label>
            <br></br>
            <Form.Control as="select" aria-label="Default select example">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">5</option>
              <option value="5">4</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
            </Form.Control>
            <br></br>
            </Form>
          <Button varient="danger" onClick={(event) => setSelectedElement({})}>Delete</Button>
          </div>
    );
  }

  if(element.type === "output") {
    content = (
      <div>
          <br></br>
          <p><b>This is a sequence end</b></p>
          <br></br>
          <p>Options</p>
          <Form style={{justifyContent: "left", textAlign: "left"}}>
            <br></br>
            <Form.Check
              inline
              label={<b>Return to last sequence</b>}
              name="group1"
              type={"checkbox"}
              id={`inline-check-1`}
            />
            <br></br>
          </Form>
          <Button varient="danger" onClick={(event) => setSelectedElement({})}>Delete</Button>
      </div>
    );
  }

  if(element.type === "content") {
    content = (
      <div>
                <PanelHeader
              title={"Properties"}
              infoLink={process.env.REACT_APP_FRESHDESK_SCENES}
            >
            </PanelHeader>
          <br></br>
          <p>This is a content source, use it to provide content to a templated layout</p>
          <br></br>
          <p><b>Tags</b></p>
          <div style={{fontSize: "12px"}}>
            <b style={{background: "#F90", padding: "2px", margin: "2px", borderRadius: "2px"}}>Replay</b> <b style={{background: "#F90", padding: "2px", margin: "2px", borderRadius: "2px"}}>Highlights</b>
          </div>
          <br></br>
          <p><b>Avalible Content</b></p>
      </div>
    );
  }

  ///this is an edge
  if(element.source !== undefined) {

    if(element.data !== undefined) {
      if(element.data.type === "content_flow") {
        content = (
          <div>
                    <PanelHeader
              title={"Properties"}
              infoLink={process.env.REACT_APP_FRESHDESK_SCENES}
            >
            </PanelHeader>
              <br></br>
              <Button onClick={(event)=> setSelectedElement({...element, label: "Latest"})}>Latest</Button>
              <br></br>
              <Button onClick={(event)=> setSelectedElement({...element, label: "Random"})}>Random</Button>
              <br></br>
              <Button onClick={(event)=> setSelectedElement({...element, label: "Selected"})}>Selected</Button>
              <br></br>
              <br></br>
              <Button varient="danger" onClick={(event) => setSelectedElement({})}>Delete</Button>

          </div>
        );
      }
    }

    const fmtMSS = (s) => {return(s-(s%=60))/60+(9<s?':':':0')+s}

    content = (
      <div>
                <PanelHeader
              title={"Properties"}
              infoLink={process.env.REACT_APP_FRESHDESK_SCENES}
            >
            </PanelHeader>
          <br></br>
          <p><b>{element.label}</b></p>
          <Button style={{marginRight: "5px"}} inline onClick={(event)=> setSelectedElement({...element, label: "Click", data: {...element.data, mode: "click"}})}>Click</Button>
          <Button style={{marginRight: "5px"}} inline onClick={(event)=> setSelectedElement({...element, label: `Content end ${fmtMSS(time)}`, data: {...element.data, mode: "content_finish", time: 0}})}>Content Finish</Button>
          <br></br>
          <br></br>
          <input type="number" value={time} onChange={(event) => {setTime(event.target.value)}}></input>
          <Button style={{marginRight: "5px"}} inline onClick={(event)=> setSelectedElement({...element, label: `Time: ${fmtMSS(time)}`, data: {...element.data, mode: "time", time: time}})}>Fixed Time</Button>

          <br></br>
          <p><b>Effects</b></p>
          <select placeholder="Select Transistion Effect">
            <option>Fade</option>
            <option>Blur</option>
            <option>Swipe</option>
          </select>
      </div>
    );
  }

  return (
    <aside style={{maxWidth: "25%", width: "25%", padding: "0px", backgroundColor: "#1E232E", color: "#fff", borderLeft: "1px solid #31354b", borderRight: "0px"}}>
      <PanelHeader
        title={"Properties"}
        infoLink={process.env.REACT_APP_FRESHDESK_SCENES}
      >
        <FontAwesomeButton 
          icon={faTimes}
          color="red"
          onClick={hideSideBar}
        />
      </PanelHeader>
      <div style={{padding: "var(--panelPadding)"}}>
        {content}
      </div>
    </aside>
  )

  return(<div>Uknown node type</div>)
  
};

export default Sidebar;