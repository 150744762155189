import React from "react"
import "./Player.css"
import WebRTCAdaptor from "./js/webrtc_adaptor"
import { dispatchEvent } from "simple-react-event-hub"

class WebRTCPlayer extends React.PureComponent {
    constructor(props) {
        super(props)
        this.webRTCAdaptor = null
        this.state = {
            mediaConstraints: {
                video: false,
                audio: false,
            },
            streamName: this.props.streamId,
            token: "",
            pc_config: {
                iceServers: [
                    {
                        urls: "stun:stun.l.google.com:19302",
                    },
                ],
            },
            sdpConstraints: {
                OfferToReceiveAudio: true,
                OfferToReceiveVideo: true,
            },
            websocketURL: `wss://${process.env.REACT_APP_MEDIA_SERVER_HOST}:${process.env.REACT_APP_MEDIA_SERVER_EDGE}/WebRTCAppEE/websocket`,
            isShow: false,
        }
    }

    componentDidMount() {
        this.webRTCAdaptor = this.initiateWebrtc()
    }

    onStartPlaying = () => {
        this.webRTCAdaptor.play(this.props.streamId, this.state.token)
    }

    initiateWebrtc = () => {
        return new WebRTCAdaptor({
            websocket_url: this.state.websocketURL,
            mediaConstraints: this.state.mediaConstraints,
            peerconnection_config: this.state.pc_config,
            sdp_constraints: this.state.sdpConstraints,
            remoteVideoId: `${this.props.cameraSlot}`,
            isPlayMode: true,
            debug: true,
            candidateTypes: ["tcp", "udp"],
            callback: (info, obj) => {
                if (info === "initialized") {
                    this.onStartPlaying()
                } else if (info === "play_started") {
                    this.setState({ playing: true })
                } else if (info === "play_finished") {
                    this.setState({ playing: false })
                    setTimeout(() => this.onStartPlaying(), 3000)
                } else if (info === "closed") {
                    this.setState({ playing: false })
                } else if (info === "streamInformation") {
                }
            },
            callbackError: (error) => {
                if (error === "no_stream_exist") {
                    //console.log("streamNotFound, retying")
                    setTimeout(() => this.onStartPlaying(), 1000)
                }
            },
        })
    }

    render() {
        const {
            muted = true,
            internalRef = React.createRef(),
            preview = false,
            name,
        } = this.props
        var border = "player-border-red"
        if (this.state.playing) {
            border = "player-border-green"
        }
        if (preview) {
            border = "player-border-none"
        }

        return (
            <div
                className={`player ${border} ${!this.state.playing && preview ? "player-red" : "player-transparent"
                    } ${preview ? "player-border-width-0" : "player-border-width-1"}`}
            >
                <video
                    ref={internalRef}
                    id={this.props.cameraSlot}
                    autoPlay
                    controls={false}
                    playsInline
                    onPlaying={
                        dispatchEvent("camera-started", {id: this.props.streamId})
                    }
                    onStalled={() => console.log("Stalled")}
                    muted={true}
                    volume={0}
                    className={`player-video ${this.state.playing ? "player-video-visible" : "player-video-hidden"
                        } ${!this.state.playing && preview
                            ? "player-video-red"
                            : "player-video-transparent"
                        }`}
                ></video>
                {preview && !this.state.playing ? (
                    <h5 className="player-video-title">{name}</h5>
                ) : (
                    ""
                )}
            </div>
        )
    }
}

export default WebRTCPlayer
