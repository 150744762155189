import React, { Component } from 'react'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import sourceTypes from '../../constants/sourceTypes'
import TWEEN from '@tweenjs/tween.js'
import { TemplateContext } from '../../templateContext'
import { cloneDeep, isEqual, merge } from 'lodash'
import convertSVGtoIMG from '../../util/SVGtoIMG'
import * as colorHelpers from '../../util/colorHelpers'


const TransparentRGBA = '#00000000'
const TransparentString = 'transparent'

//should reflect aspect ratio - need to add calcs for non 16x9 aspect ratio
const VirtualScreenHeight = 9
const VirtualScreenWidth = 16

//calculate correct distance for the camera based on the virtual screen size
const vFOV = 45 * Math.PI / 180; 
const vDistance = Math.abs(VirtualScreenHeight /  (2 * Math.tan( vFOV / 2 )));

const VirtualScreenPosition = new THREE.Vector3(0, 0, 0)
const VirtualCameraPosition = new THREE.Vector3(0, 0, vDistance)

//offset in Z direction for stacked layers - small enought to not be noticeable while avoiding Z fighting
const layerZOffset = 0.001
const indexZOffset = 0.0001


export default class ThreeRenderer extends Component {
	
	static contextType = TemplateContext

	constructor(props) {
		super(props)
	}

	async componentDidMount() {
		this.props.internalCanvasRefCallback(this.internalCanvasRef)
		console.log("componentDidMount")
		// === THREE.JS CODE START ===
		this.scene = new THREE.Scene()
		let aspectRatio = this.props.resolution.w / this.props.resolution.h
		this.camera = new THREE.PerspectiveCamera(45, aspectRatio, 1, 100)
		this.renderer = new THREE.WebGLRenderer({
			canvas: this.internalCanvasRef,
			antialias: true,

		})

		this.internalCanvasRef.addEventListener("webglcontextlost", (event) => {
			console.log('webglcontextlost', event)
		});
		this.renderer.setSize(this.props.resolution.w, this.props.resolution.h, false)

		this.instances = []
		this.camera.position.set(VirtualCameraPosition.x, VirtualCameraPosition.y, VirtualCameraPosition.z)
		
		//this.camera.rotateY(Math.PI / 2)1

		this.controls = new OrbitControls(this.camera, this.internalCanvasRef)
		this.controls.enableKeys = true
		this.controls.enablePan = true
		this.controls.enabled = false
		this.controls.keyPanSpeed = 1
		this.controls.zoomSpeed = 0.1
		this.controls.keys = {
			LEFT: 'ArrowLeft', //left arrow
			UP: 'ArrowUp', // up arrow
			RIGHT: 'ArrowRight', // right arrow
			BOTTOM: 'ArrowDown', // down arrow
			FORWARD: 'KeyW',
		}
		this.controls.listenToKeyEvents(window)
		this.controls.target.set(0,0,0)
		this.controls.update()
		this.controls.rotate

		this.animate()
		this.transitionTime = 200
	}

	animate = (time) => {
		this.controls.update()
		this.renderer.render(this.scene, this.camera)
		TWEEN.update(time)
		requestAnimationFrame(this.animate)
	}

	getCenterCoordinates(position) {
		const { x, y, width, height } = position
		const xCenter = x + width / 2
		const yCenter = y + height / 2
		return { xCenter, yCenter }
	}

	get3DWidth(position) {
		const width3D = (VirtualScreenWidth) * (position.width)
		return width3D
	}

	get3DHeight(position) {
		const height3D = (VirtualScreenHeight) * (position.height)
		return height3D
	}

	async componentWillReceiveProps(nextProps) {
		if(nextProps.resolution.w !== this.props.resolution.w || nextProps.resolution.h !== this.props.resolution.h) {
			this.renderer.setSize(nextProps.resolution.w, nextProps.resolution.h, false)
		}

		if(nextProps.transition) this.transitionTime = nextProps.transition.duration
		
		var videos = []

		const sourcesAreOutdated =  true

        /**
     * Merges the source data with the template data
     * @param {*} templateData the template data context
     * @param {*} fields the fields to merge into the template data
     * @returns {*} the merged data
     */
        const getGraphicSVG = (source, templateData, fields) =>{
          const mergedData = cloneDeep(templateData)
          
          if (fields && mergedData) {
            fields.forEach((field) => {
              if (field.value !== 'USE TEMPLATE') {
                mergedData[field.name] = field.value
              }
            })
          }

		  //add the correct resolutuion width and height for SVG viewports
		  mergedData.width = source.position.width*this.props.resolution.w
		  mergedData.height = source.position.height*this.props.resolution.h

          let graphicGeneratorFunction = null
          eval('graphicGeneratorFunction = ' + source.sourceURL)
          let svg = graphicGeneratorFunction(mergedData)
          return svg
        }
    
        const drawGraphic = (index, source, svg) =>{
          
    
          let url = convertSVGtoIMG(svg)
    
          this.drawImageAtPosition(
            index,
            source.position,
            url,
            source.fillMode || 'contain',
            true,
            source.id,
            svg,
			() => {},
			source.backgroundColor
          )
    
        }

		if (sourcesAreOutdated) {

			this.scene.children.forEach((child) => {
				if (child.type === 'Mesh') {
					new TWEEN.Tween(child.material)
						.to({ opacity: 0 }, this.transitionTime + 150)
						.easing(TWEEN.Easing.Linear.None)
						.start()
						.onComplete(() => {
							child.removeFromParent()
						})
				}
			})
			
			this.instances.forEach((instance) => {
				clearInterval(instance)
			})
			this.instances = []

			nextProps.sources.forEach(async (source, index) => {
				//will become legacy
				if (source.type === sourceTypes.image) {
					cob
					this.addColorPlaneAtPoisition(
						index,
						source.position,
						source.backgroundColor

					)
					this.drawImageAtPosition(
						index,
						source.position,
						source.sourceURL,
						source.fillMode || 'contain',
						false,
						source.id,
						null,
					)
				}

				if (source.type === sourceTypes.video) {
					//1. Draw render target itself with background color.
					this.addColorPlaneAtPoisition(
						index,
						source.position,
						source.backgroundColor
					)

					this.setState({ videos: videos }, () => {
						setTimeout(async () => {
							try {
								const video2 = document.getElementById(source.id)
								const videoTexture = new THREE.VideoTexture(video2)
								const naturalW = video2.videoWidth
								const naturalH = video2.videoHeight
								this.drawTexturedSurfaceAtPosition(
									index,
									videoTexture,
									source.position,
									source.fillMode || 'contain',
									naturalW,
									naturalH
								)
							} catch (err) {}
						}, 10)
					})
				}

				if (source.type === sourceTypes.webRTC) {
					if (source.sourceURL === '') return

					//1. Draw render target itself with background color.
					this.addColorPlaneAtPoisition(
						index,
						source.position,
						source.backgroundColor
					)

					const video2 = document.getElementById(
						"cs" + source.sourceURL
					)

					if (!video2) return //no video found

					const naturalW = video2.videoWidth
					const naturalH = video2.videoHeight
					const liveVideoTexture = new THREE.VideoTexture(video2)

					this.drawTexturedSurfaceAtPosition(
						index,
						liveVideoTexture,
						source.position,
						source.fillMode,
						naturalW,
						naturalH
					)
				}

				if (source.type === sourceTypes.mediaQuery) {
					//need to add a playing slideshow to the state here.

					//1. Draw render target itself with background color.

					//run the media query.

					//treat as a normal image as there is only one - draw image first then background to avoid any flicker
					if (source.mediaQuery && source.mediaQuery.items.length === 1) {
						this.drawImageAtPosition(
							index + 1,
							source.position,
							source.mediaQuery.items[0].url,
							source.fillMode,
							false,
							source.id,
							null,
							() => {
								this.addColorPlaneAtPoisition(
									index,
									source.position,
									source.backgroundColor
								)
							}
						)
					}

					//3. Store in state.

					//4. Play out as slideshow.
					if (source.mediaQuery && source.mediaQuery.items.length > 1) {
						const instance = this.drawSlideShowInPosition(
							index,
							source.mediaQuery.items,
							source.duration,
							source.position,
							source.fillMode
						)
						this.instances.push(instance)
						this.addColorPlaneAtPoisition(
							index,
							source.position,
							source.backgroundColor
						)
					}
				}

				if(source.type === sourceTypes.SVG) {
				const svg = getGraphicSVG(source, this.context.data, source.fields)
				drawGraphic(index, source, svg)
				}
			})
		}

    nextProps.sources.forEach((source, index) => { 
      if (source.type !== sourceTypes.SVG) return

      const sceneObject = this.scene.children.find((child) => child.sourceId === source.id)
      
      let latestSVG = getGraphicSVG(source, this.context.data, source.fields)

      //graphic not rendered yet so add it to the scene
      if(!sceneObject){
        //drawGraphic(index, source, latestSVG)
        return
      }

      const isOutdated =!isEqual(latestSVG, sceneObject.svgData)

      //if graphic is up to date, do nothing.
      if(!isOutdated) return

      //if graphic present but outdated, crossfade between old and new graphic.
	  //this.transitionTime = 200
      //new TWEEN.Tween(sceneObject.material)
		//.to({ opacity: 0 }, this.transitionTime)
		//.easing(TWEEN.Easing.Linear.None)
		//.start()
		//.onComplete(() => {
		//	this.removeObject3D(sceneObject)
		//})
      	//drawGraphic(index, source, latestSVG)
    })
	
}

	addColorPlaneAtPoisition(index, position, color) {
		console.log("addColorPlaneAtPoisition", color)
		//no need to draw anything there is fully transparent or no backgroud.
		if (
			color === TransparentRGBA ||
			color === TransparentString ||
			color === 'none' ||
			color === null ||
			color === ""
		)
			return

		const w = this.get3DWidth(position)
		const h = this.get3DHeight(position)

		//split the CSS color into three.js color representation and opactity from alpha channel
		const colorCode = new THREE.Color(color)
		const alpha = colorHelpers.getAlpha(color)

		var material = new THREE.MeshBasicMaterial({
			opacity: alpha,
			transparent: true,
			color: colorCode,
		})

		var geometry = new THREE.PlaneBufferGeometry(w, h)

		var texturedSurface = new THREE.Mesh(geometry, material)
		this.scene.add(texturedSurface)
		const { xCenter, yCenter } = this.getCenterCoordinates(position)

		texturedSurface.position.set(
			VirtualScreenPosition.x + (-VirtualScreenWidth/2) + (VirtualScreenWidth) * xCenter,
			VirtualScreenPosition.y + (VirtualScreenHeight/2) + (-VirtualScreenHeight) * yCenter,
			VirtualScreenPosition.z + (position.z - 1) * layerZOffset + index * indexZOffset
		)

		console.log(texturedSurface.position)

		//texturedSurface.rotateY(Math.PI / 2)
	}

	drawImageAtPosition(
		index,
		position,
		imageURL,
		fillMode,
		isSVG,
		sourceId = "",
		data = null,
		callback = () => {},
		color = ""
	) {
		const loader = new THREE.TextureLoader()

		loader.load(imageURL, (texture) => {
			
			const naturalW = texture.image.naturalWidth
			const naturalH = texture.image.naturalHeight
			
			if (isSVG === true) {
				//if we have an SVG, we need to scale the vector image before it is rasterized otherwise scale will always be zero
				texture.image.width = position.width*this.props.resolution.w
				texture.image.height = position.height*this.props.resolution.h
			}

			this.addColorPlaneAtPoisition(index, position, color)
			this.drawTexturedSurfaceAtPosition(
				index,
				texture,
				position,
				fillMode,
				naturalW,
				naturalH,
				callback,
				isSVG,
				sourceId,
				data
			)
		})
	}

	drawTexturedSurfaceAtPosition(
		index,
		texture,
		position,
		fillMode,
		naturalW,
		naturalH,
		callback = () => {},
		isSVG = false,
    sourceId="",
    data = null
	) {
		console.log(index, position)
		let containerW = this.get3DWidth(position)
		let containerH = this.get3DHeight(position)

		texture.matrixAutoUpdate = false;

		//implement CSS fill modes as transforms
		//note fill (strech) is the default behaviour and dosn't need to be implemented
		if (fillMode === 'contain') {
			const resize = this.contain(
				texture,
				containerW,
				containerH,
				naturalW,
				naturalH
			)
			containerW = resize.w
			containerH = resize.h
		}

		if (fillMode === 'cover') {
			this.cover(texture, containerW, containerH, naturalW, naturalH)
		}

		var material = new THREE.MeshBasicMaterial({
			map: texture,
			opacity: this.transitionTime == 0 ? 1 : 0,
			transparent: true,
		})

		var geometry = new THREE.PlaneBufferGeometry(containerW, containerH)
		var texturedSurface = new THREE.Mesh(geometry, material)
		if(isSVG) {
			texturedSurface.sourceType = "svg"
			texturedSurface.svgData = data
		}
    	texturedSurface.sourceId = sourceId
		
		this.scene.add(texturedSurface)

		new TWEEN.Tween(texturedSurface.material)
			.to({ opacity: 1 }, this.transitionTime)
			.easing(TWEEN.Easing.Linear.None)
			.start()

		const { xCenter, yCenter } = this.getCenterCoordinates(position)
		
		texturedSurface.position.set(
			VirtualScreenPosition.x + (-VirtualScreenWidth/2) + (VirtualScreenWidth) * xCenter,
			VirtualScreenPosition.y + (VirtualScreenHeight/2) + (-VirtualScreenHeight) * yCenter,
			VirtualScreenPosition.z + (position.z - 1) * layerZOffset + index * indexZOffset
		)
		
		callback(texturedSurface)
	}

	cover(
		texture,
		containerWidth,
		containerHeight,
		imageNaturalWidth,
		imageNaturalHeight
	) {
		//we will manualy set the texture's transform matrix
		texture.matrixAutoUpdate = false

		let imageAspect = imageNaturalWidth / imageNaturalHeight
		let containerAspect = containerWidth / containerHeight

		if (containerAspect < imageAspect) {
			//width contrained case
			const ratio = containerAspect / imageAspect
			texture.matrix.setUvTransform(0, 0, ratio, 1, 0, 0.5, 0.5)
		} else {
			//height constrained case
			const ratio = imageAspect / containerAspect
			texture.matrix.setUvTransform(0, 0, 1, ratio, 0, 0.5, 0.5)
		}
	}

	contain(texture, containerW, containerH, naturalW, naturalH) {
		const width = naturalW
		const height = naturalH
		const imageAspect = width / height
		var newWidth = containerW
		var newHeight = containerH
		var containerAspect = containerW / containerH
		if (containerAspect < imageAspect) {
			//width contrained case
			newHeight = containerW / containerAspect
		}
		if (containerAspect > imageAspect) {
			//height constrained case
			newWidth = containerH * imageAspect
		}
		return { w: newWidth, h: newHeight }
	}

	removeObject3D(object3D) {
		if (!(object3D instanceof THREE.Object3D)) return false

		// for better memory management and performance
		if (object3D.geometry) object3D.geometry.dispose()

		if (object3D.material) {
			if (object3D.material instanceof Array) {
				// for better memory management and performance
				object3D.material.forEach((material) => material.dispose())
			} else {
				// for better memory management and performance
				object3D.material.dispose()
			}
		}
		object3D.removeFromParent() // the parent might be the scene or another Object3D, but it is sure to be removed this way
		return true
	}

	drawSlideShowInPosition(index, imageList, duration, position, fillMode) {
		let imageTextures = []
		let slideIndex = 0
		let lastImage = null
		let loadedImages = 0
		let instance = null

		//load all images
		imageList.forEach((image, index) => {
			const loader = new THREE.TextureLoader()
			loader.load(image.url, (texture) => {
				imageTextures.push(texture)
				loadedImages = loadedImages + 1
				if (index === 0) {
					updateSlideShow()
				}
			})
		})

		const crossfade = (lastImage, nextImage, callback) => {
			new TWEEN.Tween(lastImage.material)
				.to({ opacity: 0 }, 1000)
				.easing(TWEEN.Easing.Cubic.Out)
				.start()
				.onComplete(() => {
					callback()
				})
			new TWEEN.Tween(nextImage.material)
				.to({ opacity: 1 }, 1000)
				.easing(TWEEN.Easing.Cubic.In)
				.start()
				.onComplete(() => {})
		}

		const updateSlideShow = () => {
			if (loadedImages === 0) return //nothing loaded so do nothing.

			//get the image details
			const image = imageTextures[slideIndex]
			const naturalW = image.image.naturalWidth
			const naturalH = image.image.naturalHeight

			//draw as normal.
			this.drawTexturedSurfaceAtPosition(
				index,
				imageTextures[slideIndex],
				position,
				fillMode,
				naturalW,
				naturalH,
				(texturedSurface) => {
					texturedSurface.material.opacity = 0
					texturedSurface.transparent = true
					if (lastImage !== null) {
						if (texturedSurface.material.map == lastImage.material.map) return
					}
					if (lastImage !== null) {
						//if there was a previous image crossfade with it and then remove it when done.
						crossfade(lastImage, texturedSurface, () => {
							this.removeObject3D(lastImage)
							lastImage = texturedSurface
						})
					} else {
						//if this is the first image just fade it in.

						new TWEEN.Tween(texturedSurface.material)
							.to({ opacity: 1 }, 1000)
							.easing(TWEEN.Easing.Cubic.In)
							.start()
							.onComplete(() => {
								lastImage = texturedSurface
							})
					}
				}
			)

			//advance slideshow
			slideIndex = slideIndex + 1
			if (slideIndex >= loadedImages) {
				if (loadedImages < imageList.length) {
					slideIndex = slideIndex //hold for now
				}
				slideIndex = 0
			}
		}

		//set update loop going
		instance = setInterval(updateSlideShow, duration + 1000) //+1000 for crossfade time
		return instance
	}

	render() {
		return (
			<div className='preview-render'>
				<canvas
					id='render-target'
					ref={(ref) => (this.internalCanvasRef = ref)}
				/>
			</div>
		)
	}
}
