import * as React from "react"

const YoutubeIcon = ({ ...props }) => {
  return (
    <svg
      width={28}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.172 1.276H3.758a2.483 2.483 0 0 0-2.483 2.483V14.24a2.483 2.483 0 0 0 2.483 2.483h20.414a2.483 2.483 0 0 0 2.483-2.483V3.76a2.483 2.483 0 0 0-2.483-2.483Z"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m16.503 8.96-4.93-2.8.098 5.768 4.832-2.967Zm1.085-.018-6.452 3.962-.131-7.702 6.583 3.74Z"
        fill="#fff"
      />
    </svg>
  )
}
export default YoutubeIcon

