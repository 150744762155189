
export default function Clock({gameTime}) {

   let time = "00:00"

   if(gameTime) {
    time = gameTime
   }
    return `<svg viewBox="0 0 145 45" width="145px" height="45px" xmlns="http://www.w3.org/2000/svg">
	<g id="Layer_1-2">
	  <polygon class="cls-7" points="145 45 25 45 0 0 145 0 120 45"/>
	  <text style="fill: rgb(255, 255, 255); font-family: 'Yu Gothic UI'; font-size: 32px; white-space: pre; text-anchor: middle;" x="72.5" y="35.737">${time}</text>
	</g>
  </svg>`
}