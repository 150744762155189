import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  getEventInputStreams,
  addEventInputStream,
  updateEventInputStream,
  deleteEventInputStream,
  deleteEventInputStreams
} from "../../services/api.js"
import AddSourceModal from "../components/AddSourceModal.js"
import { ConfirmationDialog } from "../../components/modals/ConfirmationDialog.js"
import { LinkShareDialog } from "../../components/modals/LinkShareDialog.js"

import TestEventConfig from "../../constants/test_event_config.js"

import GetEventFromURL from "../../util/getEventFromURL.js"
import WebRTCPlayer from "../../components/webRTCplayer/WebRTCPlayer.js"
import GetToken from "../../util/getToken.js"
import {
  Button,
  Badge
} from "react-bootstrap"
import PanelHeader from "../components/PanelHeader.js"
import { faWebcam, faUser, faDisplay, faBan, faEdit, faScreencast, faUsers, faSignalStream} from "@fortawesome/pro-light-svg-icons"
import { faClose, faCog, faLink, faPlug, faStop} from "@fortawesome/free-solid-svg-icons"

import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { PackedGrid } from 'react-packed-grid'
import SizedCanvas from "../preview/components/SizedCanvas.js"
import { dispatchEvent } from "simple-react-event-hub"
import AddCameraModal from "./add-camera-modal.js"
import Video from "./videoFromSrcObj.js"
import { cloneDeep } from "lodash"
import UseEventCallback from "../preview/useEventCallback.js"
import {MappingContext} from "./inputMappingContext.js"
const BEARER_TOKEN = GetToken()

const StreamTypeMap = [
  {
    code: 1,
    name: "WebRTC",
    icon: faWebcam,
  },
  {
    code: 2,
    name: "RTMP",
    icon: faUser,
  },
  {
    code: 3,
    name: "Greenroom User",
    icon: faUser
  },
  {
    code: 4,
    name: "Screenshare",
    icon: faDisplay
  }
]

class InputMonitorPanel extends React.Component {
  static contextType = MappingContext

  constructor(props) {
    super(props)
    this.state = {
      items: TestEventConfig,
      inputStreams: [],
      localStreamActive: false,
      localStream: null,
      eventId: undefined,
      streamForEdit: undefined,
      addSourceModalVisible: false,
      deleteSourceDialog: false,
      initialStream: { _id: undefined, name: "", streamType: "1" },
      selectedStream: null,
      inputs: [{
        _id: "1",
        name: "Host Camera",
        source: null,
      },
      {
        _id: "2",
        name: "Guest 1",
        source: null,
      },
      {
        _id: "3",
        name: "Guest 2",
        source: null,
      },
      {
        _id: "4",
        name: "Guest 3",
        source: null,
      }],
    }
    this.eventId = GetEventFromURL()
    this.boxRef = React.createRef()
  }




  componentDidMount() {
    
    if (this.eventId) {
      
     /* setInterval(() => {
        const roomStreams = localStorage.getItem("StreamList")
        if(!roomStreams) return
        const roomStreamsObj = JSON.parse(roomStreams)
        const data = roomStreamsObj.map(stream => {
          return { _id: stream.streamId, name: stream.streamName, endpoint: stream.streamId, type: 1}
        })
        if(data.length === this.state.inputStreams.length -1) return
          data.unshift({_id: "host-"+this.eventId, name: "Host", endpoint: "host-"+this.eventId, type: 1})
          this.setState({inputStreams: data})
      }, 10000)*/

      this.loadInputStreams(this.eventId)

      this.setState({ eventId: this.eventId })
      //console.log("AN", this.context)
      if(false) {
        setTimeout(() => {
          this.context.publish("host-"+this.eventId, "", "", "", "Host")
          this.context.joinRoom(this.eventId, "host-"+this.eventId)
        }, 5000)
          
      }
    } else {
      this.setState({
        inputStreams: []
      })
    }
  }

  clearinputStream = () => {
    deleteEventInputStreams(BEARER_TOKEN, this.eventId).then(result => {
      this.setState({
        inputStreams: []
      })
    })
  }

  notifyUserOfLinkCopy() {
    toast.info("📋 Copied streaming link to clipboard", {
      position: "bottom-right",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
  }

  copyLinkToClipboard(eventId, streamId) {
    const link = `${process.env.REACT_APP_PRE_PRODUCTION_URL}/stream/${eventId}/${streamId}`
    navigator.clipboard.writeText(link)
    this.notifyUserOfLinkCopy()
  }

  drag = (event, item) => {
    event.dataTransfer.setData("item", JSON.stringify(item))
  }

  disableBothVideoAndAudio = (stream) => {
    stream.getTracks().forEach((track) => {
        if (track.readyState == 'live') {
            track.enabled = false;
        }
    });
  }

  enableBothVideoAndAudio = (stream) => {
    stream.getTracks().forEach((track) => {
        if (track.readyState == 'live') {
            track.enabled = true;
        }
    });
  }

  getLayer(stream) {
    const { _id, name, endpoint, ref, muted = true, selected, source } = stream
    const newRef = React.createRef()
    return (
      <div className="input-monitor-layer" key={_id}>
        <div
          className="input-monitor-layer-addPIPSource"
          onClick={() => {
            if (this.state.selectedStream !== null) {

              if (stream === null || stream === undefined || stream === "") return

              //find the correct input slot from the ID
              let inputs = this.state.inputs
              let index = inputs.findIndex(x => x._id === _id)

              let inputStreamToAssign = ""
              let streamId = ""
              if(this.state.selectedStream === "localStream") {
                inputStreamToAssign = "localStream"
                streamId = this.state.localStream.id
                this.enableBothVideoAndAudio(this.state.localStream)
              } else {
                inputStreamToAssign = this.state.inputStreams.find(x => x._id === this.state.selectedStream)
                streamId = inputStreamToAssign.endpoint.split("=").pop()
              }
              
              //assign the source to the input slot
              inputs[index].source = inputStreamToAssign
              
              //update the state and clear the selected stream source
              this.setState({ inputs: inputs, selectedStream: null}, () => {
                let data = {inputSlot: _id, streamId: streamId}
                console.log("CAM Change", data)
                this.context.updateMap(data)
                dispatchEvent("camera-change", data)
              })  

            } else {
              this.addPIPSource(stream)
            }
          }}
        >
          {(source !== null && source !== "localStream") &&
            <WebRTCPlayer
              key={source._id}
              ref={ref}
              muted={muted}
              volume={0}
              streamId={source.endpoint.split("=").pop()}
              cameraSlot={"cs" + _id}
            ></WebRTCPlayer>
          }

          {source === "localStream" &&
            <Video 
              onPlaying={() => dispatchEvent("camera-started", {id: this.state.localStream?.id || "none"})} 
              id={"cs" + _id}
              style={{
                width: "100%", 
                height: "100%", 
                objectFit: "contain", 
                outline: this.state.localStreamActive ? "1px solid limegreen" : "1px solid red"
              }} 
              srcObject={this.state.localStream} 
              muted 
              autoPlay>
            </Video>
          }

        </div>
        <Badge bg="dark" style={{ color: "white", position: "absolute", display: "inline-block", top: "0px", left: "0px", fontSize: "normal" }}>{stream.source ? stream.source === "localStream" ? "Local Camera" : stream.source.name : "Select Source"}</Badge>
        {(source !== null) &&
          <FontAwesomeIcon style={{ color: "white", position: "absolute", display: "inline-block", top: "5px", right: "5px", fontSize: "normal" }} icon={faBan} onClick={() => {
            let inputs = this.state.inputs
            let index = inputs.findIndex(x => x._id === _id)
            try {
              document.getElementById ("cs" + _id).srcObject = null
              document.getElementById ("cs" + _id).src = null
              if(source === "localStream") {
                console.log("stopping local stream")
                this.disableBothVideoAndAudio(this.state.localStream)
              }
            } catch (e) {
              console.log(e)
            }
            inputs[index].source = null
            this.setState({ inputs: inputs})
            this.context.updateMap({inputSlot: _id, streamId: ""})
            dispatchEvent("camera-change", {inputSlot: _id, streamId: ""})
          }} color="white" />
        }
        <div className="render-target-title-inner">
          <p className="live-source-preview-text">{stream.name} &nbsp;</p>
          <FontAwesomeIcon icon={faEdit} color="white" />
        </div>
      </div>
    )
  }



  unmuteSource = id => {
    var items = this.state.inputStreams
    var index = items.findIndex(x => x._id === id)
    items[index].muted = false
    this.setState({ items: items })
  }

  muteSource = id => {
    var items = this.state.inputStreams
    var index = items.findIndex(x => x._id === id)
    items[index].muted = true
    this.setState({ items: items })
  }

  addPIPSource = stream => {
    console.log("ADD source")
    if (stream === null || stream === undefined || stream === "") return

    let streamId = ""
    if(stream.source !== null && stream.source!== "localStream") {
      streamId = stream.source.endpoint.split("=").pop()
    } else {
      if(stream.source === "localStream") {
        streamId = this.state.localStream.id
      }
    }

    console.log("CAM DATA", stream._id, streamId, stream.name)
    const newSource = {
      _id: streamId,
      url: stream._id,
      x: 0,
      y: 0,
      w: 0.3,
      h: 0.3,
      z: 3,
      type: "webRTC",
      name: stream.name
    }
    dispatchEvent('add-source', newSource)
  }

  removeSource = index => {
    var inputStreams = this.state.inputStreams
    inputStreams.splice(index, 1)
    this.setState({ inputStreams: inputStreams })
  }

  getItemsRendered(items) {
    var rendered = []
    items.forEach((item, index) => {
      rendered.push(
        <li key={item._id} className="outer-node-container-2">
          {this.getLayer(item)}
        </li>
      )
    })
    return rendered
  }

  addSource = () => {
    var items = this.state.items

    //generate a random stream id (must be number)
    //for large production - needs to be more UUID'y
    var min = 1000000000
    var max = 9999999999
    var num = Math.floor(Math.random() * (max - min + 1)) + min

    items.push({
      title: `New Stream ${items.length - 1}`,
      selectedSO: `${num}`,
      selectedTag: {},
      visible: false,
      selectedThemes: [],
      ref: React.createRef()
    })
    this.setState({ items: items })
  }

  toggleAddSourceModal = () => {
    if (this.state.inputStreams.length > 10) {
      toast.error("Can't add more than 10 streams")
      return
    }
    this.setState({
      addSourceModalVisible: !this.state.addSourceModalVisible,
      streamForEdit: undefined
    })
  }

  toggleEditSourceModal = stream => {
    this.setState({
      addSourceModalVisible: !this.state.addSourceModalVisible,
      streamForEdit: stream
    })
  }

  toggleDeleteSourceDialog = stream => {
    this.setState({
      deleteSourceDialog: !this.state.deleteSourceDialog,
      streamForEdit: stream
    })
  }

  loadInputStreams = eventId => {
    getEventInputStreams(BEARER_TOKEN, eventId).then(result => {
      this.setState({
        inputStreams: result.map(x => {
          return { ref: React.createRef(), ...x }
        })
      })
    })
  }

  addLocalStream = (stream) => {
    this.setState({localStreamActive: true, localStream: stream, addSourceModalVisible: false})
  }

  saveStream = stream => {
    try {
      const input = {
        name: stream.name,
        streamType: parseInt(stream.streamType)
      }
      if (stream._id) {
        updateEventInputStream(
          BEARER_TOKEN,
          this.state.eventId,
          stream._id,
          input
        ).then(() => {
          this.loadInputStreams(this.state.eventId)
        })
      } else {
        addEventInputStream(BEARER_TOKEN, this.state.eventId, input).then(
          () => {
            this.loadInputStreams(this.state.eventId)
          }
        )
      }
    } catch (err) {
      console.log(err)
    }
    this.toggleAddSourceModal()
  }

  deleteStream = streamId => {
    try {
      deleteEventInputStream(BEARER_TOKEN, this.state.eventId, streamId).then(
        () => {
          this.loadInputStreams(this.state.eventId)
        }
      )
    } catch (err) {
      console.log(err)
    }
  }

  handleOnDeleteSource = stream => {
    this.deleteStream(stream._id)

    this.setState({
      deleteSourceDialog: !this.state.deleteSourceDialog,
      streamForEdit: undefined
    })

    setTimeout(() => {
      toast.info("Input source successfully deleted.", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }, 50)
  }

  getCameraMapping = () => {

    const mappedCameras = this.state.inputs.map ((input) => {
      let inputStreamToAssign = ""
      let streamId = ""
      if(this.state.selectedStream === "localStream") {
        inputStreamToAssign = "localStream"
        streamId = this.state.localStream.id
      } else {
        inputStreamToAssign = this.state.inputStreams.find(x => x._id === this.state.selectedStream)
        streamId = inputStreamToAssign.endpoint.split("=").pop()
      }
      return {inputSlot: input._id, streamId: streamId}
    })

    dispatchEvent("camera-mapping", {mappedCameras: mappedCameras})
  }

  render() {
    const LiveSourcesGrid = () => {
      return (
        <SizedCanvas style={{width: "100%", height: "100%"}} onSizeChangeCB={() => {} /*() => this.boxRef.current()*/}>
          <PackedGrid boxAspectRatio={16/9} updateLayoutRef={this.boxRef} className="fullSize">
            {this.state.inputs.map(stream => {
              return <div key={stream.source} className="grid-box">{this.getLayer(stream)}</div>
            })}
          </PackedGrid>
        </SizedCanvas>
      )
    }

    return (
      <>
      <UseEventCallback
					event='get-camera-mapping'
					callback={this.getCameraMapping}
			/>
      <div className="main-content">
        <div className="input-monitor-panel">
          <PanelHeader
            title={"Input Monitor & Selector"}
            infoLink={process.env.REACT_APP_FRESHDESK_LIVE_PREVIEW}
          ></PanelHeader>
          
            <div className="live-resources-wrapper">
                <LiveSourcesGrid />
            </div>
        </div>
        <div className="input-streams-panel">
          <PanelHeader
            title={"Input Sources"}
            infoLink={process.env.REACT_APP_LIVE_INPUTS}
          >
            <div className="io-subpanel-actions">
              <Button variant="transparent"
                disabled={false}
                onClick={this.toggleAddSourceModal}
                className="io-btn-light">
                 Add local camera
              </Button>
            </div>
          </PanelHeader>

          <div className="input-streams-wrapper">
            {this.state.inputStreams.length <= 0 ? (
              <div class="blank-panel-window">
                <div class="blank-panel-window-text">
                  Create an input stream by clicking "Add New"
                </div>
              </div>
            ) : (
              <div className="input-streams-list">
                <div className="input-streams-list-header">
                  <FontAwesomeIcon icon={faSignalStream} fixedWidth style={{marginRight: "10px", marginLeft: "5px"}}/>
                  Live Sources</div>
                  {this.state.localStreamActive &&
                    <div className="input-streams-list-item" style={{ border: (this.state.selectedStream === "localStream" ? "1px solid yellow" : "")}} onClick={() => { 
                      if (this.state.selectedStream === "localStream") {
                        this.setState({ selectedStream: null })
                      } else {
                        this.setState({ selectedStream: "localStream" })
                      }
                    }}>
                      <div className="input-streams-list-inner">
                        <span style={{ display: "flex", alignItems: "center", width: "100%" }}>
                          <FontAwesomeIcon icon={faPlug} color="white" />
                          <div className="input-streams-title">Local Camera</div>
                          <FontAwesomeIcon icon={faClose} color="red" style={{ marginRight: "5px" }} onClick={() => {this.setState({localStreamActive: false, localStream: null})}}></FontAwesomeIcon>
                          <FontAwesomeIcon icon={faCog} color="White" stlye={{ right: "5px" }} onClick={() => {this.setState({addSourceModalVisible: true})}}></FontAwesomeIcon>
                        </span>
                      </div>
                    </div>
                  }
                {
                  this.state.inputStreams.map((source, index) => {
                    return (
                      <div className="input-streams-list-item" style={{ border: (this.state.selectedStream === source._id ? "1px solid yellow" : "")}} onClick={() => { 
                        if (this.state.selectedStream === source._id) {
                          this.setState({ selectedStream: null })
                        } else {
                          this.setState({ selectedStream: source._id })
                        }
                        console.log("Selected " + source.name)
                      }}>
                        <div className="input-streams-list-inner">
                          <span style={{ display: "flex", alignItems: "center", width: "100%" }}>
                            <FontAwesomeIcon icon={source.streamType === 1 ? faWebcam : faScreencast} color="white" />
                            <div className="input-streams-title">{source.name}</div>
                            <FontAwesomeIcon icon={faLink} color="White" stlye={{ right: "5px" }} onClick={() => this.copyLinkToClipboard(this.state.eventId, source._id)}></FontAwesomeIcon>
                          </span>
                        </div>
                      </div>
                    )  
                  })
                }
                
                <div className="input-streams-list-header" >
                  <FontAwesomeIcon icon={faUsers} fixedWidth style={{marginRight: "10px", marginLeft: "5px"}}/>
                  Greenroom</div>
                {[].map((source, index) => {
                  
                  let data = null

                  if (source.name === "Host Camera") {
                    data = StreamTypeMap.find(type => type.code === 1)
                  } else {
                    if(source.name.includes("Screenshare")) {
                      data = StreamTypeMap.find(type => type.code === 4)
                    } else {
                      data = StreamTypeMap.find(type => type.code === 2)
                    }
                  }

                  return (
                    <div className="input-streams-list-item" style={{ border: (this.state.selectedStream === source._id ? "1px solid yellow" : "none"), borderOffset: "-2px" }} onClick={() => {
                      if (this.state.selectedStream === source._id) {
                        this.setState({ selectedStream: null })
                      } else {
                        this.setState({ selectedStream: source._id })
                      }
                      console.log("Selected " + source.name)
                    }}>
                      <div className="input-streams-list-inner">
                        <span style={{ display: "flex", alignItems: "center", width: "100%" }}>
                          <FontAwesomeIcon icon={data.icon} color="white" />
                          <div className="input-streams-title">{source.name}</div>
                          <FontAwesomeIcon icon={faCircle} color="green" stlye={{ right: "5px" }}></FontAwesomeIcon>
                        </span>
                      </div>
                    </div>
                  )
                })}
                 <div className="input-streams-list-item">
                      <div className="input-streams-list-inner">
                        <span style={{ display: "flex", alignItems: "center", width: "100%" }}>
                          <div className="input-streams-title">Empty</div>
                        </span>
                      </div>
                    </div>
              </div>
            )}
          </div>
        </div>

        <AddCameraModal
          show={this.state.addSourceModalVisible}
          onClose={() => {this.setState({addSourceModalVisible: false})}}
          onSave={(stream) => this.addLocalStream(stream)}
        />

        <ConfirmationDialog
          show={this.state.deleteSourceDialog}
          onClose={this.toggleDeleteSourceDialog}
          content={`Are you sure want to delete ${this.state.streamForEdit && this.state.streamForEdit.name}`}
          header="Delete this source?"
          onDelete={() => this.handleOnDeleteSource(this.state.streamForEdit)}
        />

        <LinkShareDialog
          show={this.state.showLinkDialog}
          onClose={() => this.setState({ showLinkDialog: false })}
          content={
            <div className="link-share-dialog">
              <p>Please copy the link and share it with the performer</p>
              <input type="text" value={this.state.linkForShare} />
            </div>
          }
          header="Share Streaming Link"
        />

      </div>
      </>
    )
  }
}


export default React.memo(InputMonitorPanel)