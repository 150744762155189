'use strict'

import React from 'react'
import { Button, Tooltip } from 'react-bootstrap';
import { ChromePicker } from 'react-color'

class ColorPickerButton extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      displayColorPicker: false,
      color: this.props.color
    };
  }


  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleColorChange = (color) => {
    const RGBA = `RGBA(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a || 1})`
    this.setState({color: RGBA})
    this.props.onChange(color)
  }

  render() {
    const popover = {
      position: 'absolute',
      zIndex: '2',
      marginTop: "10px"
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
    return (
      <div>
        <Button style={{padding: "2px", borderRadius: "5px"}} onClick={ this.handleClick }>
          <div className='checked-background' style={{padding: "0px", borderRadius: "3px", width: "1.5em", height: "1.5em"}}>
            <div style={{width: "100%", height: "100%", backgroundColor: this.props.color}}></div>
          </div>
        </Button>
        { this.state.displayColorPicker ? <div style={ popover }>
          <div style={ cover } onClick={ this.handleClose }/>
            <ChromePicker color={this.state.color} onChange={this.handleColorChange} />
        </div> : null }
      </div>
    )
  }
}

export default ColorPickerButton