import React, { Component } from 'react'
import { FormLabel, Image, Dropdown, Breadcrumb, Button } from 'react-bootstrap'
import { withAuth0 } from "@auth0/auth0-react"
import { Logo } from '../svgIcons'
import { get } from 'lodash'
import PresetLayouts from '../../constants/preset_layouts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCircleInfo } from '@fortawesome/free-solid-svg-icons'

class nav extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userName: ''
        }
    }

    logout = () => {
        localStorage.removeItem("user")
        localStorage.removeItem("userActiveTeam")
        localStorage.removeItem("userProfile")
        localStorage.removeItem("auth0_token")
        localStorage.removeItem("userActiveOrg")
        localStorage.removeItem("userActiveOrgName")
        this.props.auth0.logout({ returnTo: `${process.env.REACT_APP_PRE_PRODUCTION_URL}` })
    }

    backtohome = () => {
        window.location.href = `${process.env.REACT_APP_PRE_PRODUCTION_URL}/welcome`;
    }
    
    componentDidMount() {
        const profile = JSON.parse(localStorage.getItem("userProfile"))
        if (profile) {
            this.setState({ userName: profile.firstname })
        }
    }

    render() {
        const help = process.env.REACT_APP_FRESHDESK_URL

        if (!this.props.event) {
            return (
                <div>Event not loaded</div>
            )
        }

        function jsUcfirst(string) {
            if (!string) return null
            return string.charAt(0).toUpperCase() + string.slice(1)
        }

        return (
            <>
                <header className="header header-fixed-top"
>
                    <div className="header-left">
                        <div className="header-brand"
                            onClick={this.backtohome}>
                            <Logo style={{height: "50px"}}/>
                        </div>
                            <div className="header-title">
                                <Breadcrumb className="Breadcrumb">
                                    <Breadcrumb.Item href={`${process.env.REACT_APP_PRE_PRODUCTION_URL}/events/${this.props.event._id}`}>Events</Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        {this.props.event.name}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                    </div>
                    <div className="header-right">
                      
                        <Dropdown alignRight className="profile-dropdown">
                                <Dropdown.Toggle
                                    align="end"
                                    variant="transparent"
                                    id="dropdown-custom-components"
                                    className="profile-dropdown-components"
                                >
                                    <FormLabel className="userName d-none d-md-inline-block">
                                        <span className="navBarText">Mode: </span><span className='navBarText' style={{color: "white"}}>Editor</span>{" "}
                                        <FontAwesomeIcon icon={faCaretDown}/>
                                    </FormLabel>
                                </Dropdown.Toggle>

                                <Dropdown.Menu align="end" variant="dark">
                                    {
                                        PresetLayouts.map((preset, index) => {
                                            return (
                                                <Dropdown.Item 
                                                    key={"layout"+preset.id} 
                                                    onClick={() => this.props.layoutCB(preset.layout)} 
                                                    eventKey={index}
                                                >
                                                    {preset.title}
                                                </Dropdown.Item>
                                            )
                                        })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>


                            <Button variant='transparent' style={{display: "inline-block", marginLeft: "20px", color: "white", display: "flex", alignItems: "center",  justifyContent: "center"}} onClick={() => window.FreshworksWidget('open')}>
                                <span className='navBarText'>Help &nbsp; </span>
                                <FontAwesomeIcon icon={faCircleInfo} size={"lg"}></FontAwesomeIcon>
                            </Button>


                        <div>
                            <Dropdown alignRight className="profile-dropdown" autoClose="outside">
                                <Dropdown.Toggle
                                    align="end"
                                    variant="transparent"
                                    id="dropdown-custom-components"
                                    className="profile-dropdown-components"
                                >
                  
                                    <FormLabel className="userName d-none d-md-inline-block">
                                        &nbsp;
                                        {jsUcfirst(get(this.state, "userName", ""))}
                                        &nbsp;
                                        <FontAwesomeIcon icon={faCaretDown}/>
                                    </FormLabel>

                                    <Image
                                        src={this.props.auth0.user.picture}
                                        referrerPolicy="no-referrer"
                                        className="nav-dropdown-image"
                                        roundedCircle
                                    />
                                   
                                </Dropdown.Toggle>

                                <Dropdown.Menu align="end" variant="dark">
                                    <Dropdown.Item onClick={this.logout} eventKey="1">Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>


                        </div>


                    </div>
                </header>
            </>
        )
    }
}
// export default withRouter(withAuth0(nav))
export default withAuth0(nav)