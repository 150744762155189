import React from 'react'
import sourceTypes from '../../constants/sourceTypes'
import { useState, useEffect } from 'react'
import { Headphone } from '../../components/svgIcons'
import VolumeSlider from './components/volumeSlider'
import PanelHeader from '../components/PanelHeader'

/**
 * Manages the mixing and volume of audio sources.
 * @param {*} param0 
 * @returns 
 */
export default function AudioMixSubPanel({ audioEngine, sceneSources }) {
	
	const [audioSources, setAudioSources] = useState([])
	const [masterVolume, setMasterVolume] = useState(50)
	const [masterMuted, setMasterMuted] = useState(false)
	const [monitorAudio, setMonitorAudio] = useState(true)
	const [activeAudioSources, setActiveAudioSources] = useState([])

	//update audio source state based on the active scene sources
	useEffect(() => {
		const audioSources = filterForAudioSources(sceneSources)
		setAudioSources(audioSources)
	}, [sceneSources])

	//updates the audio graph for monitoring of audio
	useEffect(() => {
		audioEngine.setMonitorAudio(monitorAudio)
	}, [monitorAudio])

	//updates the audio graph to reflect the mater gain and mute state
	useEffect(() => {
		if (masterMuted) {
			audioEngine.setMasterGain(0)
		} else {
			audioEngine.setMasterGain(masterVolume / 100)
		}
	}, [masterMuted, masterVolume])

	//updates the audio graph to reflect the audio source state
	useEffect(() => {
		
		//update volumes
		activeAudioSources.forEach((activeSource) => {
			let source = audioSources.find((source) => source.id === activeSource)
			if (source === undefined) {
				return
			} else {
				let nextVolume = source.muted ? 0 : source.volume/100
				audioEngine.setAudioSourceGain(source.id, nextVolume)
			}
		})

		//add sources that are new and not in the mix
		const sourcesToBeAdded = audioSources.filter(
			(source) =>
				!activeAudioSources.find((activeSource) => activeSource === source.id)
		)

		sourcesToBeAdded.forEach((source) => {
			if (source.type === sourceTypes.video) {
				var sourceElement = document.getElementById(source.id)
				audioEngine.addDOMElementSource(
					source.id,
					sourceElement,
					source.volume / 100
				)
				setActiveAudioSources((activeAudioSources) => [
					...activeAudioSources,
					source.id,
				])
			}

			if (source.type === sourceTypes.webRTC) {
				var sourceElement = document.getElementById("cs"+ source.sourceURL)
				audioEngine.addDOMElementStreamSource(
					source.id,
					sourceElement,
					source.volume / 100
				)
				setActiveAudioSources((activeAudioSources) => [
					...activeAudioSources,
					source.id,
				])
			}
		})

		//remove sources that are no longer in the mix
		activeAudioSources.forEach((activeSource) => {
			if (sceneSources.find((sceneSource) => sceneSource.id === activeSource)) {
				//dn
			} else {
				audioEngine.removeAudioSource(activeSource)
				setActiveAudioSources((activeAudioSources) =>
					activeAudioSources.filter((source) => source !== activeSource)
				)
			}
		})
	}, [audioSources])

	/**
	 * filters sources for those that produce audio
	 * @param {[SceneSource]]} sources 
	 * @returns 
	 */
	const filterForAudioSources = (sources) => {
		return sources.filter(
			(source) =>
				source.type === sourceTypes.webRTC || source.type === sourceTypes.video
		)
	}

	/**
	 * Update an audio source
	 * @param {*} id // the id of the audio source to update
	 * @param {*} values // an object containing the new key:values for the audio source
	 */
	const updateSourceById = (id, values) => {
		//Updates the audio source using map to trigger re-render
		const updatedSources = audioSources.map((source) => {
			if (source.id === id) {
				return { ...source, ...values }
			} else {
				return source
			}
		})
		setAudioSources(updatedSources)
	}

	/**
	 * Update the volume of an audio source
	 * @param {int} nextVolume
	 * @param {SceneSource} source
	 */
	const handleSourceVolumeChange = (nextVolume, source) => {
		updateSourceById(source.id, { volume: nextVolume })
	}

	/**
	 * Update the muted state of an audio source
	 * @param {SceneSource} source
	 */
	const toggleSourceMute = (source) => {
		updateSourceById(source.id, { muted: !source.muted })
	}

	return (
		<div className='audio-mix-subpanel'>
			<PanelHeader
				title='Audio Mix'
				infoLink={process.env.REACT_APP_FRESHDESK_AUDIOMIX}
			>
				<Headphone 
					onClick={() => setMonitorAudio((monitorAudio) => !monitorAudio)} 
					color={monitorAudio ? '#01D1FA' : '#fff'}
				/>
			</PanelHeader>

			<div style={{ paddingTop: '15px' }}>
				<ul style={{ paddingLeft: '1rem' }}>
					<VolumeSlider
						style={{ paddingLeft: '1rem' }}
						key={'slider-master'}
						label={'Master Volume'}
						currentVolume={masterVolume}
						muted={masterMuted}
						onVolumeChange={(nextVolume) => setMasterVolume(nextVolume)}
						onToggleMute={() => setMasterMuted((masterMuted) => !masterMuted)}
					/>
					{audioSources.map((source) => (
						<VolumeSlider
							key={'slider-' + source.id}
							label={source.name}
							currentVolume={source.volume}
							muted={source.muted}
							onVolumeChange={(nextVolume) => handleSourceVolumeChange(nextVolume, source)}
							onToggleMute={() => toggleSourceMute(source)}
						/>
					))}
				</ul>
			</div>
		</div>
	)
}
