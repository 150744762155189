import { faVideo, faVideoSlash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState} from 'react'
import Video from '../../input-monitor/videoFromSrcObj'
import { useEvent } from 'simple-react-event-hub'
import {MappingContext} from '../../input-monitor/inputMappingContext'
import { useContext } from 'react'

//preview for render target
export default function LiveSourcePreview({modify, source, updateCamera}) {
  
  const InputMappingContext = useContext(MappingContext)
  const [sourceIsLive, setSourceIsLive] = useState(null)
  const [srcObject, setSrcObject] = useState(null)
  const [id, setId] = useState(source.id.split("#")[0])
  const [hasCheckedCamera, setHasCheckedCamera] = useState(false)

  useEvent( 'camera-change', (cameraChangeEvent) => {
    checkForCamera(source.sourceURL)
  });

  useEvent( 'camera-started', (event) => {
    checkForCamera(source.sourceURL)
  })

  useEffect(() => {
    checkForCamera(source.sourceURL)
  }, [id])

  useEffect(() => {

    checkForCamera(source.sourceURL)
  }, [

  ])

  const checkForCamera = (id) => {
    const video = document.getElementById("cs" + id)
      if(video) {
        if(true) {
          setSrcObject(video.srcObject)
          setSourceIsLive(true)
          setHasCheckedCamera(true)
        } else {
          setSrcObject(null)
          setSourceIsLive(false)
          setHasCheckedCamera(true)
        }
      } else {
        setSrcObject(null)
        setSourceIsLive(false)
        setHasCheckedCamera(true)
      }
  }

  return (
    <>
      <div className={"preview-video-type-webrtc" + modify} style={{background: source.backgroundColor}}>
          {sourceIsLive && (
            <div style={{width: "100%", height: "100%", overflow: "hidden"}}>
              <Video muted autoPlay srcObject={srcObject} style={{width: "100%", height: "100%", objectFit: source.fillMode}}/>
            </div>
          )}
          {!sourceIsLive && hasCheckedCamera && (
            <div style={{background: "green", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row"}}>
              <div style={{textAlign: "center"}}>
                <div style={{marginBottom: "10px"}}>{source.name} {`(offline)`}</div>
                <FontAwesomeIcon icon={faVideoSlash} size={"2x"}></FontAwesomeIcon>
              </div>
            </div>
          )}
      </div>
    </>
  )
}