import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

//defaults
const DefaultShowDelay = 250
const DefaultHideDelay = 200
const DefaultPosition = 'top'
const DefaultSize = "2x"
const DefaultColor = "white"
const DefaultStyle = {marginRight: "0px"}

export default function FontAwesomeButton({
        icon, 
        tooltip, 
        onClick, 
        size = DefaultSize, 
        color = DefaultColor, 
        style = DefaultStyle
    }) {
    
    const hasToolTip =  tooltip !== null && 
                        tooltip !== undefined && 
                        tooltip !== ""
    
    //if no valid tooltip only use icon to reduce dom items
    if (!hasToolTip) {
        return (
            <FontAwesomeIcon 
                className="faHoverIcon" 
                icon={icon} 
                onClick={onClick} 
                size={size} 
                color={color} 
                style={style}
            />
        )
    }

    //full tooltip button
    return (
        <OverlayTrigger
            style={style}
            placement={DefaultPosition}
            delay={{ 
                show: DefaultShowDelay, 
                hide: DefaultHideDelay 
            }}
            overlay={
                <Tooltip 
                    id="button-tooltip" 
                    style={{pointerEvents: "none"}}
                >
                    {tooltip}
                </Tooltip>
            }
        >
            <FontAwesomeIcon 
                className="faHoverIcon" 
                icon={icon} 
                onClick={onClick} 
                size={size} 
                color={color} 
                style={style}
            />
        </OverlayTrigger>
    )
}