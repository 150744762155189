export const HalfTimeInterviewFields = [
    {
        name: "title",
        type: "text",
        value: "Half Time Interview",
    },
    {
        name: "secondaryTitle",
        type: "text",
        value: "Player Name",
    },
    {
        name: "footnote",
        type: "text",
        value: "",
    },
]

export default function halfTimeInterview({title, secondaryTitle, footnote, primaryColor, secondaryColor, textColor}) {

	const style = `.cls-1{letter-spacing:-.04em;}.cls-2{letter-spacing:-.01em;}.cls-3{letter-spacing:-.09em;}.cls-4{fill:${primaryColor};}.cls-5{fill:#e5e4e4;}.cls-6,.cls-7{fill:${secondaryColor};}.cls-7,.cls-8{font-family:Roboto-Bold, Roboto;font-size:29.34px;font-weight:600;}.cls-8{fill:#fff;}.cls-9{letter-spacing:-.03em;}.cls-10{letter-spacing:-.06em;}.cls-11{letter-spacing:-.06em;}.largeText{font-size:26.34px;}`

  return `<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1920" height="1500" viewBox="0 0 1189.2 857.09">
<defs>
    <style>
        .cls-1{fill:none;}.cls-1,.cls-2,.cls-3{isolation:isolate;}.cls-4,.cls-3,.cls-5{fill:#010101;}.cls-6{fill:${primaryColor};}.cls-7{fill:#a31916;}.cls-8{letter-spacing:0em;}.cls-9{letter-spacing:-.03em;}.cls-10{letter-spacing:0em;}.cls-11{letter-spacing:-.01em;}.cls-2{fill:#fff;font-size:33px;}.cls-2,.cls-3{font-family:Roboto-Bold, Roboto;font-weight:700;}.cls-3{font-size:60px;}.cls-12{letter-spacing:-.07em;}.cls-13{letter-spacing:-.03em;}.cls-5{opacity:.5;}
    </style>
</defs>
<g id="Layer_1-2" style="font-family: &quot;Yu Gothic UI&quot;;">
    <rect class="cls-5" x="0" y="156.3" width="1189.2" height="694.14" />
    <rect class="cls-4" x="363.28" width="462.63" height="50.48" />
    <rect class="cls-6" y="50.48" width="1189.2" height="105.82" />
    <rect class="cls-7" y="850.44" width="1189.2" height="6.64" />
    <g class="cls-1">
        <text class="cls-2" transform="translate(595 36.7)" text-anchor="middle">
            <tspan class="cls-10" x="0" y="0">
                ${title}
            </tspan>
        </text>
    </g>
    <g class="cls-1">
        <text class="cls-3" transform="translate(595 125.99)">
            <tspan x="0" y="0" text-anchor="middle" style='fill: ${textColor};'>
                ${secondaryTitle}
            </tspan>
        </text>
    </g>
    <g class="cls-1">
        <text class="cls-2" transform="translate(595 808)">
            <tspan x="0" y="0" text-anchor="middle">
                ${footnote}
            </tspan>
        </text>
    </g>
    <g class="cls-1">
    </g>
</g>
</svg>`
}




