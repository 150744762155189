import React from 'react'
import { Table, Form, Row, Col, Button } from 'react-bootstrap'
import { TemplateContext } from '../../templateContext.js'
import PanelHeader from '../components/PanelHeader.js'

import HalfTimeScore from './assets/football-graphics/halfTimeScore.js'
import KickOff from './assets/football-graphics/kickOff.js'
import ScoreBoard from './assets/football-graphics/scoreboard.js'
import TitleCard from './assets/football-graphics/titleCard.js'
import convertSVGtoIMG from '../../util/SVGtoIMG.js'

export default function template() {
	const { data, setData } = React.useContext(TemplateContext)

	if (!data) {
		return <></>
	}

	const pngData = TitleCard({...data, message: `Your Message Here`})

	let blob = new Blob([pngData], { type: 'image/svg+xml' })
	let url = URL.createObjectURL(blob)

	const getSVGasIMG = (svgString) => {
		let imageData = convertSVGtoIMG(svgString)
		return (
			<img
                src={imageData}
                style={{
                    width: '100%',
                    height: '100%'
                }}
            />
		)
	}

	const download = () => {
		const a = document.createElement("a");
		a.href = url;
		a.download = "titleCard.svg";
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	return (
		<div>
			<PanelHeader
				title={'Template Data'}
				infoLink={' '}
			>
				<Button className='io-btn io-btn-light' onClick={download}>Export Graphics</Button>
			</PanelHeader>

			<div style={{ padding: '10px' }}>
				<Table
					responsive
					striped
					bordered
					hover
					variant='dark'
				>
					<thead>
						<tr>
							<th style={{ width: '100px' }}>Element</th>
							<th style={{ width: '40%' }}>Variables</th>
							<th>Preview</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td style={{ paddingLeft: '5px' }}>Scoreboards</td>
							<td>
								<Form style={{ padding: '10px' }}>
									<Row>
										<Col>
											<Form.Label>Team Names:</Form.Label>
										</Col>
									</Row>
									<Row>
										<Col>
											<Form.Control
												value={data.team1Name}
												onChange={(e) => {
													setData({ team1Name: e.target.value })
												}}
											/>
										</Col>
										<Col md={3}>
											<Form.Control
												value={data.team1ShortName}
												onChange={(e) => {
													setData({ team1ShortName: e.target.value })
												}}
											/>
										</Col>
									</Row>
									<br></br>
									<Row>
										<Col>
											<Form.Control
												value={data.team2Name}
												onChange={(e) => setData({ team2Name: e.target.value })}
											/>
										</Col>
										<Col md={3}>
											<Form.Control
												value={data.team2ShortName}
												onChange={(e) =>
													setData({ team2ShortName: e.target.value })
												}
											/>
										</Col>
									</Row>
									<br></br>
									<Row>
										<Col>
											<Form.Label>Match Details:</Form.Label>
										</Col>
									</Row>
									<Row>
										<Col>
											<Form.Control
												value={data.date}
												onChange={(e) => {
													setData({ date: e.target.value })
												}}
											/>
										</Col>
									</Row>
									<br></br>
									<Row>
										<Col>
											<Form.Control
												value={data.location}
												onChange={(e) => {
													setData({ location: e.target.value })
												}}
											/>
										</Col>
									</Row>
									<br></br>
									<Row>
										<Col>
											<Form.Label>Colours:</Form.Label>
										</Col>
									</Row>
									<Row>
										<Col>
											<Form.Control
												type='color'
												id='exampleColorInput'
												value={data.primaryColor}
												onChange={(e) => {
													setData({ primaryColor: e.target.value })
												}}
												title='Choose your color'
											/>
										</Col>
									</Row>
									<br></br>
									<Row>
										<Col>
											<Form.Control
												type='color'
												id='exampleColorInput1'
												value={data.secondaryColor}
												onChange={(e) => {
													setData({ secondaryColor: e.target.value })
												}}
												title='Choose your color'
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<Form.Label>Text Color</Form.Label>
											<Form.Control
												type='color'
												id='exampleColorInput2'
												value={data.textColor}
												onChange={(e) => {
													setData({ textColor: e.target.value })
												}}
												title='Choose your color'
											/>
										</Col>
									</Row>
								</Form>
							</td>
							<td>
								<div
									className='checked-background'
									style={{ padding: '10px', backgroundColor: 'green' }}
								>
									<br></br>
									<br></br>
									{getSVGasIMG(ScoreBoard({...data, }))}
									<br></br>
									<br></br>
									{getSVGasIMG(KickOff({...data, gameMode: 'KICKOFF' }))}
									<br></br>
									<br></br>
									{getSVGasIMG(HalfTimeScore({...data, gameMode: 'HALF TIME' }))}
								</div>
							</td>
						</tr>
						<tr>
							<td style={{ padding: '5px' }}>Title Card</td>
							<td>
								<Form style={{ padding: '10px' }}>
									<Row>
										<Col>
											<Form.Label>Kickoff Time:</Form.Label>
										</Col>
									</Row>
									<Row>
										<Col>
											<Form.Control
												value={data.kickOffTime}
												onChange={(e) => {
													setData({ kickOffTime: e.target.value })
												}}
											/>
										</Col>
									</Row>
								</Form>
							</td>
							<td>
								<img
									style={{ width: '100%', height: '100%' }}
									src={url}
								></img>
							</td>
						</tr>
					</tbody>
				</Table>
			</div>
		</div>
	)
}
