import * as React from "react"

const Logo = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="60"
      fill="none"
      viewBox="0 0 64 60"
      {...props}
    >
      <path
        fill="#fff"
        d="M37.29 49.703a20.714 20.714 0 01-8.509 1.449 25 25 0 01-4.59-.62c-5.882-1.46-10.373-4.747-13.471-9.86C8.55 37 7.537 33.223 7.683 29.341c.051-1.365.253-2.768.606-4.205 1.161-4.741 3.765-8.68 7.812-11.819 4.217-3.087 8.927-4.535 14.131-4.34 6.056.229 11.076 2.508 15.057 6.845 4.066 4.34 6.035 9.368 5.904 15.087a23.312 23.312 0 01-.148 1.64l7.621 1.001c.086-.711.157-1.428.197-2.162l.017-.447c.182-8.281-2.559-15.475-8.227-21.582C45.063 3.427 38.368.314 30.563.02c-5.718-.213-11.11 1.227-16.181 4.329-5.15 3.099-8.984 7.353-11.494 12.771C1.127 20.814.171 24.664.02 28.674c-.074 2.003.06 4.06.41 6.166.964 6.144 3.437 11.383 7.421 15.72 5.543 5.93 12.282 9.066 20.216 9.408l.256.008c5.29.2 10.09-.853 14.41-3.141 0 0 2.852-2.066.555-5.709-2.051-3.255-5.998-1.423-5.998-1.423z"
      ></path>
      <path
        fill="#EC143C"
        d="M54.677 44.345a8.674 8.674 0 100-17.347 8.674 8.674 0 000 17.347z"
      ></path>
    </svg>
  )
}
export default Logo

