import React from 'react'
import { Row, Col, ToggleButton, Button, ListGroup } from 'react-bootstrap'
import { useEffect } from 'react'
import { dispatchEvent } from 'simple-react-event-hub'
import { TemplateContext } from '../../templateContext'
import FootballTimer from '../template/tools/timer'



export default function TemplateControls() {
	const {data, setData} = React.useContext(TemplateContext)


	const [activeMode, setActiveMode] = React.useState(-3)
	const [showPreAdvert, setShowPreAdvert] = React.useState(false)
	const [sceneIndex, setSceneIndex] = React.useState(2)
	const footballTimer = new FootballTimer()

	useEffect(() => {dispatchEvent("set-scene", sceneIndex)}, [sceneIndex])

	const updateTimer = (elapsed) => {
		const seconds= Math.floor((elapsed / 1000) % 60);
		const minutes= Math.floor((elapsed / (1000 * 60)));
		
		const timerString = `${minutes.toFixed(0).padStart(2, "0")}:${seconds.toFixed(0).padStart(2, "0")}`;
		setData({gameTime: timerString || ""}, false)
	}

	return (
		<div>
		<div style={{ padding: '5px' }}>

			<ListGroup>
				<ListGroup.Item style={{padding: "2px 5px"}} variant={activeMode === -2 ? "info" : "dark"} onClick={() => {setActiveMode(-2);setSceneIndex(0)}}>Stream Starting</ListGroup.Item>
				<ListGroup.Item style={{padding: "2px 5px"}} variant={activeMode === -1 ? "info" : "dark"} onClick={() => {setActiveMode(-1);setSceneIndex(1)}}>Pre Game Int</ListGroup.Item>
				<ListGroup.Item style={{padding: "2px 5px"}} variant={activeMode === 1 ? "info" : "dark"}  onClick={() => {setActiveMode(1);setSceneIndex(2)}}>Half Time Int</ListGroup.Item>
				<ListGroup.Item style={{padding: "2px 5px"}} variant={activeMode === 2 ? "info" : "dark"}  onClick={() => {setActiveMode(2);setSceneIndex(3)}}>Kickoff</ListGroup.Item>
				<ListGroup.Item style={{padding: "2px 5px"}} variant={activeMode === 3 ? "info" : "dark"}  onClick={() => {setActiveMode(3);setSceneIndex(4)}}>Gameplay</ListGroup.Item>
				<ListGroup.Item style={{padding: "2px 5px"}} variant={activeMode === 4 ? "info" : "dark"}  onClick={() => {setActiveMode(4);setSceneIndex(5)}}>Half Time</ListGroup.Item>
				<ListGroup.Item style={{padding: "2px 5px"}} variant={activeMode === 5 ? "info" : "dark"}  onClick={() => {setActiveMode(5);setSceneIndex(7)}}>Full Time</ListGroup.Item>
			</ListGroup>

			{activeMode == -3 && (
				<div>
					<hr style={{ marginTop: '5px' }}></hr>
					<ToggleButton
						style={{ marginLeft: '10px' }}
						variant='danger'
						value='1'
						onClick={() => {
							setActiveMode(-2)
							setSceneIndex(0)
						}}
					>
						{' '}
						Start Stream{' '}
					</ToggleButton>
				</div>
			)}
      {activeMode == -2 && (
				<div>
					<hr style={{ marginTop: '5px' }}></hr>
					<ToggleButton
						style={{ marginLeft: '10px' }}
						variant='danger'
						value='1'
						onClick={() => {
							setActiveMode(-1)
							setSceneIndex(1)
						}}
					>
						{' '}
						Show Lineup{' '}
					</ToggleButton>
				</div>
			)}
            {activeMode == -1 && (
				<div>
					<hr style={{ marginTop: '5px' }}></hr>
					<ToggleButton
						style={{ marginLeft: '10px' }}
						variant='danger'
						value='1'
						onClick={() => {
							setActiveMode(1)
							setSceneIndex(2)
						}}
					>
						{' '}
						Show Formation{' '}
					</ToggleButton>
				</div>
			)}
			{activeMode == 1 && (
				<div>
					<hr style={{ marginTop: '5px' }}></hr>
					<ToggleButton
						style={{ marginLeft: '10px' }}
						value='2'
						onClick={() => {
							setShowPreAdvert(!showPreAdvert)
						}}
					>
						Adverts
					</ToggleButton>
					<ToggleButton
						style={{ marginLeft: '10px' }}
						value='3'
						onClick={() => {
							setShowPreAdvert(!showPreAdvert)
						}}
					>
						Interviews
					</ToggleButton>
					<ToggleButton
						style={{ marginLeft: '10px' }}
						variant='danger'
						value='1'
						onClick={() => {
							setActiveMode(2)
							setSceneIndex(3)
						}}
					>
						{' '}
						Kickoff{' '}
					</ToggleButton>
				</div>
			)}

			{activeMode == 4 && (
				<div>
					<hr></hr>
					<ToggleButton
						style={{ marginLeft: '10px' }}
						value='2'
						onClick={() => {
							setShowPreAdvert(!showPreAdvert)
              setSceneIndex(5)
						}}
					>
						Adverts
					</ToggleButton>
					<ToggleButton
						style={{ marginLeft: '10px' }}
						value='2'
						onClick={() => {
							setShowPreAdvert(!showPreAdvert)
              setSceneIndex(6)
						}}
					>
						Interviews
					</ToggleButton>

					<ToggleButton
						style={{ marginLeft: '10px' }}
						variant='danger'
						value='1'
						onClick={() => {
							setActiveMode(3)
              setSceneIndex(4)
						}}
					>
						{' '}
						Resume Game{' '}
					</ToggleButton>
				</div>
			)}

			{activeMode == 5 && (
				<div>
					<hr></hr>
					<ToggleButton
						style={{ marginLeft: '10px' }}
						variant='danger'
						value='1'
						onClick={() => {
							setActiveMode(6)
							setSceneIndex(8)
						}}
					>
						{' '}
						End Stream{' '}
					</ToggleButton>
				</div>
			)}

			{activeMode == 2 && (
				<div>
					<hr></hr>
					<ToggleButton
						variant={'danger'}
						style={{ marginLeft: '10px' }}
						value='2'
						onClick={() => {
							setActiveMode(3)
              				setSceneIndex(4)
							//footballTimer.start()
							//footballTimer.addTickListener(updateTimer)
						}}
					>
						Game Start
					</ToggleButton>
				</div>
			)}

			{activeMode == 3 && <div>{/*show clock*/}</div>}

			{activeMode == 3 && (
				<div>
					<hr></hr>
					<Row>
						<Col>
							<Button
								onClick={() => {
									setData({scoreTeam1: data.scoreTeam1 + 1})
								}}
							>
								Goal+ {data.team1ShortName}
							</Button>
						</Col>
						<Col>
							<Button
								onClick={() => {
									setData({scoreTeam2: data.scoreTeam2 + 1})
								}}
							>
								Goal+ {data.team2ShortName}
							</Button>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button
								style={{marginTop: '5px'}}
								onClick={() => {
									setData({scoreTeam1: data.scoreTeam1 - 1})
								}}
							>
								Goal- {data.team1ShortName}
							</Button>
						</Col>
						<Col>
							<Button
								style={{marginTop: '5px'}}
								onClick={() => {
									setData({scoreTeam2: data.scoreTeam2 - 1})
								}}
							>
								Goal- {data.team2ShortName}
							</Button>
						</Col>
					</Row>
					<ToggleButton
						variant={'danger'}
						style={{ marginRight: '10px', marginTop: "5px" }}
						value='2'
						onClick={() => {
							setActiveMode(4)
              setSceneIndex(5)
						}}
					>
						Half Time
					</ToggleButton>
					<ToggleButton
						style={{ marginRight: '10px', marginTop: "5px" }}
						variant={'danger'}
						value='3'
						onClick={() => {
							setActiveMode(5)
              setSceneIndex(7)
						}}
					>
						Full Time
					</ToggleButton>
				</div>
			)}
		</div>
		</div>
	)
}
