import * as React from "react"

const UploadIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={38.533}
      height={28.259}
      {...props}
    >
      <path
        data-name="Path 303"
        d="M19.266.001a11.456 11.456 0 0 1 11.36 9.58 9.4 9.4 0 0 1-1.512 18.678H8.135a8.118 8.118 0 0 1-.348-16.23A11.446 11.446 0 0 1 19.267 0Zm0 1.712a9.831 9.831 0 0 0-9.767 11.024.857.857 0 0 1-.856.963h-.508a6.422 6.422 0 1 0 0 12.844h20.979a7.71 7.71 0 0 0 .709-15.387.856.856 0 0 1-.776-.749 9.817 9.817 0 0 0-9.781-8.7Zm0 8.563a.927.927 0 0 1 .575.214l4.71 4.281a.88.88 0 0 1 .067 1.218.9.9 0 0 1-1.218.053l-3.278-2.984v9.205a.856.856 0 0 1-1.712 0v-9.205l-3.278 2.984a.913.913 0 0 1-1.218-.053.87.87 0 0 1 .067-1.218l4.71-4.281a.748.748 0 0 1 .575-.214Z"
        fill="#b7b7b7"
      />
    </svg>
  )
}
export default UploadIcon

