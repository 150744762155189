import React, { useEffect } from 'react';
import { useEvent } from 'simple-react-event-hub';

export default function useEventCallback({event, callback}) {

    const [data, setData] = React.useState(null)

    useEvent(event, (data) => {
      setData(data)
    } );

    useEffect(()=> {
      if(data !== null) callback(data)
    },[data])

  return (
    <></>
  )
}
