import { faMicrophone, faVideo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { Button, Modal, Dropdown } from 'react-bootstrap'

export default function AddCameraModal({show, onClose, onSave}) {

    const [videoDevices, setVideoDevices] = React.useState([])
    const [audioDevices, setAudioDevices] = React.useState([])
    const [selectedVideoInput, setSelectedVideoInput] = React.useState(null)
    const [selectedAudioInput, setSelectedAudioInput] = React.useState(null)
    const videoPreview = useRef()
    const [stream, setStream] = React.useState(null)

    useEffect(() => {
        updateDeviceList()
        navigator.mediaDevices.addEventListener('devicechange', () => {
            updateDeviceList()
        })
        return () => {
            navigator.mediaDevices.removeEventListener('devicechange', () => {
                updateDeviceList()
            })
        }
    }, [])

    useEffect(() => {
       UpdateVideoPreview(selectedAudioInput, selectedVideoInput)
    }, [selectedVideoInput, selectedAudioInput])

    const UpdateVideoPreview = (selectedAudioInput, selectedVideoInput) => {
        const constraints = {
            audio: {deviceId: selectedAudioInput ? {exact: selectedAudioInput.deviceId} : undefined},
            video: {deviceId: selectedVideoInput ? {exact: selectedVideoInput.deviceId} : undefined}
        };

        if(!show) return
        navigator.mediaDevices.getUserMedia(constraints)
            .then(stream => {
                console.log("Stream", stream);
                if(videoPreview.current !== undefined) {
                    videoPreview.current.srcObject = stream;
                }
                if(stream) {
                    setStream(stream)
                }
            })
    }

    const updateDeviceList = () => {
        navigator.mediaDevices.enumerateDevices().then(devices => {
            console.log("Devices", devices);
            const videoDevices = devices.filter(device => device.kind === 'videoinput')
            const audioDevices = devices.filter(device => device.kind === 'audioinput')

            setVideoDevices(videoDevices)
            setAudioDevices(audioDevices)

            const defaultVideoDevice = videoDevices.find(device => device.deviceId === 'default')
            if(!defaultVideoDevice) {
                //if(videoDevices.length > 0) setSelectedVideoInput(videoDevices[0])
            }

            const defaultAudioDevice = audioDevices.find(device => device.deviceId === 'default')

            UpdateVideoPreview(defaultAudioDevice, defaultVideoDevice)

            //if(defaultVideoDevice !== undefined) setSelectedVideoInput(defaultVideoDevice)
            if(defaultAudioDevice !== undefined) setSelectedAudioInput(defaultAudioDevice)
        })
    }   

  return (
    <Modal 
        show={show}
        onHide={onClose}
        className="custom-modal"
        variant="dark"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
          <Modal.Title>Local Camera Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Choose your sources</p>
          <Dropdown
          className="text-muted"
          style={{ maxWidth: "100%", width: "100%", fontSize: "1vw" }}
        >
          <Dropdown.Toggle
            variant="dark"
            id="dropdown-basic"
            className="alignCenter fullWidth"
            style={{width: "100%"}}
          >
            <FontAwesomeIcon icon={faVideo} style={{ marginRight: "10px" }} />
            {videoDevices.length > 0 && selectedVideoInput !== null
              ? selectedVideoInput.label
              : "Select Video Input"}
          </Dropdown.Toggle>
          <Dropdown.Menu variant="dark" className="fullWidth">
            {videoDevices.map((videoSource) => {
              return (
                <Dropdown.Item
                  key={videoSource.deviceId}
                  onClick={() => setSelectedVideoInput(videoSource)}
                  className="text-center fullWidth text-limited"
                >
                  {videoSource.label}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown
          className="text-muted"
          style={{ maxWidth: "100%", width: "100%", fontSize: "1vw" }}
        >
          <Dropdown.Toggle
            variant="dark"
            id="dropdown-basic"
            className="alignCenter fullWidth"
            style={{width: "100%"}}
          >
            <FontAwesomeIcon icon={faMicrophone} style={{ marginRight: "10px" }} />
            {audioDevices.length > 0 && selectedAudioInput !== null
              ? selectedAudioInput.label
              : "Select Audio Input"}
          </Dropdown.Toggle>
          <Dropdown.Menu variant="dark" className="fullWidth">
            {audioDevices.map((audioDevice) => {
              return (
                <Dropdown.Item
                  key={audioDevice.deviceId}
                  onClick={() => setSelectedAudioInput(audioDevice)}
                  className="text-center fullWidth text-limited"
                >
                  {audioDevice.label}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <br></br>
        <video ref={videoPreview} autoPlay muted style={{width: "100%", background: "black"}}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={() => onSave(stream)}>
            Save
          </Button>
        </Modal.Footer>
    </Modal>
  )
}
