import React from 'react'
import PanelHeader from '../components/PanelHeader'
import GraphEditor from './graph-editor/index'
export default function sequencePanel(props) {
  return (
    <div className="main-content">
         <GraphEditor/>
    </div>
  )
}
