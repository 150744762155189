import React, { createContext, useState} from 'react'
import { Children } from 'react'


const mapping = []

export const MappingContext = createContext(mapping)

export default function InputMappingContextWrapper({children}) {

  const [inputMap, setInputMap] = useState([])

  const updateInputMap = (update) => {
    console.log('update input map:', update)
    const hasMapEntry = inputMap.findIndex(mapEntry => mapEntry.inputSlot === update.inputSlot)
    
    let newMap = inputMap
    if(hasMapEntry !== -1) {
       newMap = newMap.map((entry, index) => {
            if (index !== hasMapEntry) return entry
            entry.streamId = update.streamId
            return entry
        })
    } else {
        newMap.push(update)
    }

    console.log('next input map:', newMap)
    setInputMap(newMap)
  }

  return (
    <MappingContext.Provider value={{inputMap: inputMap, updateMap: (newMap) => updateInputMap(newMap)}}>
        {children}
    </MappingContext.Provider>
  )
}
