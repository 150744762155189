import React, { useContext, useState } from 'react'
import PanelHeader from '../../components/PanelHeader'
import { ListGroup, Button } from 'react-bootstrap'
import { ConfirmationDialog } from '../../../components/modals/ConfirmationDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faEye, faLock, faLockOpen, faEyeSlash} from '@fortawesome/free-solid-svg-icons'
import FontAwesomeButton from '../../../util/FontAwesomeButton'
import {
	faHexagon,
	faHexagonImage,
	faIcons,
	faImage,
	faImages,
	faLayerGroup,
	faPlay,
	faVideo,
} from '@fortawesome/pro-light-svg-icons'
import sourceTypes from '../../../constants/sourceTypes'
import CreateObjectModal from './CreateObjectModal'
import { TemplateContext } from '../../../templateContext'
import convertSVGtoIMG from '../../../util/SVGtoIMG'

export default function RenderTargetListSubpanel({
	renderTargetsList,
	selectedRenderTarget,
	onSelected,
	onRemove,
	onClearLayout,
	onAddObject,
	onToggleObjectLocked,
	onUpdateSource
}) {
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [showCreateObjectModal, setShowCreateObjectModal] = useState(false)
	const TemplateData = useContext(TemplateContext)

	const tryClearLayout = () => {
		setShowConfirmModal(true)
	}

	const userConfirmedClearLayout = () => {
		setShowConfirmModal(false)
		onClearLayout()
	}

	const useCanceledClearLayout = () => {
		setShowConfirmModal(false)
	}

	let sortedList = []
	let layers = 0
	let layerLists = []
	if (renderTargetsList.length > 0) {
		for (let i = 0; i < 3; i++) {
			let layerContent = renderTargetsList.filter(
				(renderTarget) => renderTarget.position.z === i + 1
			)
			layerContent.length > 0
				? layerLists.push(layerContent)
				: layerLists.push([])
		}
	}

	return (
		<>
			<div className='subpanel-scene-objects'>
				<PanelHeader
					title={'Scene Objects'}
					infoLink={process.env.REACT_APP_FRESHDESK_SCENE_OBJECTS}
				>
					<Button 
						onClick={() => setShowCreateObjectModal(true)}
						variant='transparent'
						className='io-btn-light'
						>
							Create
					</Button>
					<Button
						disabled={renderTargetsList.length <= 0}
						style={{ marginLeft: '10px' }}
						variant='transparent'
						className='io-btn-light'
						onClick={tryClearLayout}
					>
						Clear
					</Button>
				</PanelHeader>

				{renderTargetsList.length <= 0 ? (
					<div className='blank-panel-window'>
						<div className='blank-panel-window-text'>
							Add an object by clicking it in the Media Library
						</div>
					</div>
				) : (
					<ListGroup className='scene-object-list'>
						{layerLists.map((list, layerIndex) => {
							return (
								<div style={{ background: ''}}>
									<ListGroup.Item
										style={{
											padding: '5px',
											paddingLeft: '5px',
											width: '100%',
											borderLeft: "0px",
											background: 'rgba(0 , 0, 0 , 0.2)'

										}}
										variant='dark'
										className='scene-object-list-item'
									>
										<div
											className='scene-object-item-inner'
											style={{ display: 'flex', alignItems: 'center', color: "#DDD"}}
										>
											<FontAwesomeIcon fixedWidth icon={faLayerGroup}></FontAwesomeIcon>
											Layer: {layerIndex + 1}
										</div>
										<FontAwesomeButton
															style={{
																marginRight: '5px',
																display: 'inline-block',
																position: 'relative',
																right: '0px',
															}}
															size={'1x'}
															color={'#999'}
															icon={faEye}
															tooltip={'Show/Hide Layer'}
															onClick={() => {}}
														></FontAwesomeButton>
									</ListGroup.Item>
									<div style={{ paddingLeft: '15px' }}>
										{list.length > 0 &&
											list.map((source, index) => {
												let icon =
													source.type === sourceTypes.video ? faPlay : faImage
												if (source.type === sourceTypes.mediaQuery) {
													if (source.mediaQuery.items.length > 1) {
														icon = faImages
													}
												}
												if (source.type === sourceTypes.webRTC) {
													icon = faVideo
												}
												if (source.type === sourceTypes.SVG) {
													icon = faHexagonImage
												}
												const isActive = selectedRenderTarget ? selectedRenderTarget.id === source.id : false
												return (
													<ListGroup.Item
														variant='dark'
														className={'scene-object-list-item'}
														style={{ background: isActive? 'rgba(200 , 200, 255 , 0.2)' : 'rgba(0 , 0, 0 , 0.1)' }}
														onClick={() => onSelected(source.id)}
													>
														<div className='scene-object-item-inner'>
																<FontAwesomeIcon fixedWidth icon={icon}></FontAwesomeIcon>
															<div className='scene-object-title' style={{color: "#DDD"}}>
																{source.name}
															</div>
														</div>
														<FontAwesomeButton
															style={{
																marginRight: '10px',
																display: 'inline-block',
																position: 'relative',
																right: '0px',
															}}
															size={'1x'}
															color={'#999'}
															icon={source.lockedAR? faLock : faLockOpen}
															tooltip={'Lock/Unlock Object'}
															onClick={() => onToggleObjectLocked(source.id, !source.lockedAR)}
														></FontAwesomeButton>
														<FontAwesomeButton
															style={{
																marginRight: '10px',
																display: 'inline-block',
																position: 'relative',
																right: '0px',
															}}
															size={'1x'}
															color={'#999'}
															icon={source.hidden ? faEyeSlash : faEye}
															tooltip={'Show/Hide Object'}
															onClick={() => {onUpdateSource(source.id, {hidden: !source.hidden})}}
														></FontAwesomeButton>
														<FontAwesomeButton
															style={{
																marginRight: '0px',
																display: 'inline-block',
																position: 'relative',
																right: '0px',
															}}
															size={'1x'}
															color={'#999'}
															icon={faTimes}
															tooltip={'Remove Object'}
															onClick={() => onRemove(source.id)}
														></FontAwesomeButton>
													</ListGroup.Item>
												)
											})}

										{list.length === 0 && (
											<ListGroup.Item
												active={false}
												variant='dark'
												className='scene-object-list-item'
												style={{ background: 'rgba(0 , 0, 0 , 0.1)' }}
											>
												<div className='scene-object-item-inner'>
													<div className='scene-object-title'>Empty</div>
												</div>
											</ListGroup.Item>
										)}
									</div>
								</div>
							)
						})}
					</ListGroup>
				)}
				
			</div>
			<ConfirmationDialog
				show={showConfirmModal}
				onClose={useCanceledClearLayout}
				content={
					<>
						<p>Are you sure you want to clear the layout?</p>
						<p>This will remove all objects from the scene</p>
					</>
				}
				header={`Clear Scene?`}
				onDelete={userConfirmedClearLayout}
			/>
			<CreateObjectModal
				show={showCreateObjectModal}
				onClose={() => setShowCreateObjectModal(false)}
				onConfirm={(item) => {
					const graphic = item.graphic

					let svg = graphic({...TemplateData.data})
					
					let image64 = convertSVGtoIMG(svg)

					let getMeta = (url, callback) => {
						const img = new Image()
						img.addEventListener('load', function () {
							callback({ w: this.naturalWidth, h: this.naturalHeight })
						})
						img.src = url
					}

					getMeta(image64, (ar) => {
						let aspect = ar.h / ar.w
						let width = 1920/2
						let height = width * aspect
						width = width / 1920
						height = height / 1080
		


						onAddObject({
							name: item.name,
							type: "SVG",
							url:  graphic,
							thumbURL: graphic,
							canDrop: false,
							mediaQuery: null,
							fillMode: "contain",
							fields: item.fields,
							graphicFunction: item.graphic,
							w: width,
							h: height,
							x: 0,
							y: 0,
							z: 3
						})

						setShowCreateObjectModal(false)
					})

					
				}}
			>

			</CreateObjectModal>
		</>
	)
}
