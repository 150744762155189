import React from 'react'
import Slider from 'rc-slider'
import { Button } from 'react-bootstrap'
import { VolumeMute, VolumeUp } from '../../../components/svgIcons'

export default function VolumeSlider({label, currentVolume, muted, onVolumeChange, onToggleMute}) {
	return (
		<>
			<div className='audio-mix-channel-text'>{label}</div>
			<div className='audio-mix-channel-row'>
				<Slider
					min={0}
					max={100}
					step={1}
					className='audio-mix-channel-slider'
					value={currentVolume}
					onChange={(nextVolume) =>
						onVolumeChange(nextVolume)
					}
					onChangeCommitted={(nextVolume) =>
						onVolumeChange(nextVolume)
					}
				/>
				<div className='audio-mix-channel-action'>
					<Button
						onClick={() => onToggleMute()}
						variant='transparent'
						className='btn-volume'
					>
						{' '}
						{muted ? <VolumeMute /> : <VolumeUp />}
					</Button>
				</div>
			</div>
		</>
	)
}
